<template>
  <v-alert
    v-if="content"
    :color="content.Color"
    dense
    text
    :icon="content.Icon"
    border="left"
    :style="{
      'margin': !isSimulation && isDesktop ? '15px 30px 15px 30px' : '15px' 
    }"
  >
    {{ getTranslation(content.AlertText, locale) }}
  </v-alert>
</template>
<script>
import utils from '@/services/utils'
export default {
  name:'Item6Alert',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    isSimulation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    }
  }
}
</script>

