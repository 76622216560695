<template>
 <v-layout
    wrap
    justify-center
    class="activity"
    :style="{ 
      'background-color' : config.BGColorContent
    }"
  >

  <!--
    <v-flex xs8 style="margin-top: 20px;">
       <activity-type
         :disabled="loading"
         :locale="locale"
         :color="typeColor"
         :typeActivity="activitiesType"
         :onChange="handleChangeType"
      />
      <center>
        <v-btn-toggle v-model="typeActivity" mandatory
          :style="{ 'background-color': `${secundaryColor}`, 'color': isLight(secundaryColor) ? 'black' : 'white' }"
        >
          <v-btn>
            <span :style="{ 'color': isLight(secundaryColor) ? 'black' : 'white' }">{{$t('common.all', locale)}}</span>
          </v-btn>
          <v-btn>
            <v-icon 
              :style="{ 'color': isLight(secundaryColor) ? 'black' : 'white' }"
              small
            >mdi-human-child</v-icon>
          </v-btn>
          <v-btn>
            <v-icon 
              :style="{ 'color': isLight(secundaryColor) ? 'black' : 'white' }"
            >mdi-human-male</v-icon>
          </v-btn>
        </v-btn-toggle>
      </center>
    </v-flex>
  -->
  <!--
    <v-flex xs4 style="margin-top: 20px;">
      <center>
        <reservation-list :updateKey="updateKey"/>
      </center>
    </v-flex>
  -->
    <v-flex
      xs12
      style="padding: 0 20px;"
    >
      <!--week-menu
         :disabled="loading"
         :day="daysToAdd"
         :primaryColor="weekPrimaryColor"
         :secondaryColor="weekSecondaryColor"
         :locale="locale"
         :onChange="handleChangeDay"
      /-->
      <v-container
        fluid
        grid-list-md
        style="padding: 5px"
      >
         <v-layout
            row
            wrap
            style="margin-bottom: 20px;"
         >
            <v-flex
               v-if="loading"
            >
               <center>
                  <v-progress-circular
                     :size="70"
                     :width="7"
                     :color="contentColor"
                     indeterminate
                  />
               </center>
            </v-flex>
            <!--v-flex
               v-if="!loading && events.length === 0"
               xs12
            >
               <center :style="{ 'color': contentColor }">{{ $t('dtouch.activity.messages.noActivities', locale).toUpperCase() }}</center>
            </v-flex>
            <v-flex xs12 v-if="!loading && categories && categories.length > 0" style="padding-bottom: 25px">
               <v-select
                  v-model="selectedCategoryID"
                  :items="categories"
                  :label="$t('booking.filter', locale)"
                  item-text="Name"
                  item-value="ID"
                  outlined
                  :color="titleColor"
                  hide-details
               />
            </v-flex-->
            <v-flex
               xs12
               v-for="event in eventsFiltered"
               :key="event.ID"
            >
               <activity-item        
                  :event="event"
                  :locale="locale"
                  :contentColor="contentColor"
                  :primaryColor="weekPrimaryColor"
                  :secondaryColor="weekSecondaryColor"
                  :titleColor="titleColor"
                  :timeColor="timeColor"
               />
            </v-flex>
            <!--v-flex
               xs12
            >
               <center :style="{ 'color': contentColor }">{{ $t('dtouch.activity.messages.disclaimer', locale)}}</center>
            </v-flex-->
          <!--
            <v-flex
               xs12
               style="margin-top: 10px;"
            >
               <center v-if="isBook">
                  <reservation-list />
               </center>
            </v-flex>
          -->
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import utils from '@/services/utils'
//import ActivityType from './ActivityType'
//import WeekMenu from './WeekMenu'
import ActivityItem from './activityItem/Index'
export default {
   name:'Item48BookActivities',
   components: {
      //ActivityType,
      //WeekMenu,
      ActivityItem,
   },
   props: {
      content: {
         type: Object,
         default: null,
      },
      config: {
         type: Object,
         default: null,
      },
      locale: {
         type: String,
         default: null,
      },
      isDesktop: {
         type: Boolean,
         default: false,
      },
      bookingWorkspaceID: {
         type: String,
         default: null,
      },
   },
   data: () => ({
      loading: false,

      typeColor: 'green',
      weekPrimaryColor: 'green',
      weekSecondaryColor: 'green',
      contentColor: 'gray',
      titleColor: 'orange',
      timeColor: 'red',

      activitiesType: 0,
      daysToAdd: 0,

      events: [],
      eventsFiltered: [],
      categories: null,
      selectedCategoryID: null,
   }),
   computed: {
      ...mapState('app', ['dtouchWorkspace']),
   },
   watch: {
      locale () {
         this.getActivities()
      },
      content () {
         this.prepareContent()
      },
      'content.TypeColor' () {
      },
      selectedCategoryID () {
         this.handleFilterEvents()
      },
      bookingWorkspaceID () {
         this.getActivities()
      },
   },
   mounted () {
      this.prepareContent()
      this.getActivities()
   },
   methods: {
      prepareContent () {
         if (!this.content) return
         this.typeColor = this.content.BookingActTypeColor ? this.content.BookingActTypeColor : 'orange '
         this.weekPrimaryColor = this.content.BookingActWeekPrimaryColor ? this.content.BookingActWeekPrimaryColor : 'black'
         this.weekSecondaryColor = this.content.BookingActWeekSecondaryColor ? this.content.BookingActWeekSecondaryColor : 'green'
         this.contentColor = this.content.BookingActContentColor ? this.content.BookingActContentColor : 'red'
         this.titleColor = this.content.BookingActTitleColor ? this.content.BookingActTitleColor : 'red'
         this.timeColor = this.content.BookingActTimeColor ? this.content.BookingActTimeColor : 'red'
      },
      urlExists (url) {
         try {
            const http = new XMLHttpRequest()
            http.open('HEAD', url, false)
            http.send()
            return http.status != 404
         } catch {
            return false
         }
      },
      getActivities () {
         this.events = []
         this.eventsFiltered = []
         this.loading = true
         const type = this.activitiesType === 1 ? 'children' : this.activitiesType === 2 ? 'adult' : 'all'
         const urlBase = process.env.VUE_APP_API_URL_DTOUCH
         //this.daysToAdd = -120

         const url = `${window.location.protocol}//${urlBase}/v1/public/workspaces/${this.dtouchWorkspace}/booking-activities/${this.bookingWorkspaceID ? this.bookingWorkspaceID : this.content.BookingActWorkspaceID ? this.content.BookingActWorkspaceID : ''}?daysToAdd=${this.daysToAdd}&allDays=true&type=${type}`
         this.categories = []
         this.categories.push({ ID: null, Name: this.$t('dtouch.activity.all', this.locale) })
         axios.get(url)
            .then((response) => {
               if (this.content.BookingActActivityID) {
                  // filter by activityID
                  response.data = response.data.filter(x => x.ActivityID === this.content.BookingActActivityID)
               }
               this.events = response.data.sort((a, b) => {
                  return new Date(a.DateTime) - new Date(b.DateTime)
               })
               .map(item => {
                  if(item.CategoryID && this.categories.filter(x => x.ID === item.CategoryID).length === 0) 
                     this.categories.push({ ID: item.CategoryID, Name: utils.getTranslation(item.CategoryName, this.locale) })

                  item.title = utils.getTranslation(item.ActivityName, this.locale)
                  item.description = utils.getTranslation(item.ActivityDescription, this.locale)
                  //item.image = item.Image? item.Image : 'https://www.dviewcontent.com/activity/acttiv/images/generica_dtouch/404.jpg'
                  item.image = item.Image && this.urlExists(item.Image) ? item.Image :
                               item.ImageCategory && this.urlExists(item.ImageCategory) ? item.ImageCategory : 'https://www.dviewcontent.com/activity/acttiv/images/generica_dtouch/404.jpg'
                  item.date = item.Date
                  //item.start = item.DateTime

                  item.start = utils.convertTime(item.Time)
//                  item.end = utils.addMinutes(item.DateTime, item.Duration)
                  item.end = utils.convertTime(item.Time + item.Duration)
  //                alert(item.Time)
                  item.type = item.Type
                  item.active = false
                  item.outdated = false
                  //item.rangeTime = `${item.DateTime.substring(11, 16)} - ${utils.addMinutes(item.DateTime, item.Duration).toISOString().substring(11, 16)}`
                  item.rangeTime = `${utils.convertTime(item.Time)} - ${utils.convertTime(item.Time + item.Duration)}`
                  item.Place = item.Place === '{}' ? null : item.Place
                  item.MeetingPoint = item.MeetingPoint === '{}' ? null : item.MeetingPoint
                  return item
               })
               this.handleFilterEvents ()
            })
            .catch((error) => {
               console.log(error)
            })
            .then(() => {
               this.loading = false
            })
      },
      handleFilterEvents () {
         if (this.selectedCategoryID && this.categories.filter(x => x.ID === this.selectedCategoryID).length === 0)
            this.selectedCategoryID = null
         
         this.eventsFiltered = this.events.filter(x => x.CategoryID === this.selectedCategoryID || !this.selectedCategoryID)
      },
      handleChangeDay (v) {
         this.daysToAdd = v
         this.getActivities()
      },
      handleChangeType (v) {
         this.activitiesType = v
         this.getActivities()
      }
   },
}
</script>

