<template>
  <v-flex
    v-bind="{ [`xs${!isSimulation && isDesktop ? 6 : 12}`]: true }"
    style="margin-top: 5px;margin-bottom: 6px;min-height:120px;"
    :style="{ 'padding-right': index % 2 === 0 && !isSimulation && isDesktop ? '10px' : '0px', 'padding-left': index % 2 !== 0 && !isSimulation && isDesktop ? '10px' : '0px' }"
  >
    <v-card
      :style="{ 'opacity': activity.IsOLD ? '0.3' : '1'}"
      style="border-radius:5px;height:100%;"
    >
      <v-layout
        row
        wrap
        style="height:100%;"
      >
        <v-flex
          xs4
          style="padding: 0;height:100%;"
        >
          <v-img
            :src="activity.ImageURL"
            lazy-src="https://dviewcontent.com/activity/acttiv/images/2279_dtouch/imalogoacttiv.jpg"
            height="100%"
            width="90%"
            aspect-ratio="1.7"
            class="grey lighten-2"
            style="border-radius: 5px 0 0px 5px;"
          />
        </v-flex>
        <v-flex
          :style="{ 'color': ColorContent}"
          xs8
        >
          <v-layout
            row
            wrap
            style="margin-top:5px;margin-bottom:0px;"
          >
            <v-flex
              xs8
              style="text-align: left;padding-bottom:5px;line-height:1.2;"
              :style="{ 'color': titleColor
              }"
            >
              {{title.toUpperCase()}}
            </v-flex>
            <v-flex
              xs4
              style="text-align: right;padding-right: 15px;padding-bottom:0px;"
            >
              <add-calendar
                v-if="activity && !activity.IsOLD"
                :start="activity.Date"
                :end="activity.Date"
                :startTime="new Date(activity.Start).toTimeString().slice(0,5)"
                :endTime="new Date(activity.End).toTimeString().slice(0,5)"
                :title="activity.Title"
                :description="activity.Category ? activity.Category : ''"
                :color="titleColor"
                :active="activity.IsActive"
                :locale="locale"                
                :isSimulation="isSimulation"
              />
            </v-flex>
            <v-flex
              xs12
              style="text-align: left;padding-top: 0px;padding-bottom: 0px;font-family: Roboto Condensed;font-size:15px !important;"
            >
              <v-icon
                :style="{ 'color': activity.IsActive ? 'rgb(85,173,11)' : secundaryColor}"                  
                dark
                class="activity-button"
                style="margin-left: 0px;font-weight: 500;"
              >
                mdi-clock-outline
              </v-icon>
              <span
                :style="{ 'color': activity.IsActive ? 'rgb(85,173,11)' : secundaryColor}"                  
                style="font-weight: 500;"
              >
                {{ activity.RangeTime }}
              </span>
            </v-flex>
            <v-flex
              v-if="isLopesan && activity && !activity.IsOLD && isBook"
              xs12
              style="text-align: left;padding-bottom:5px;padding-top: 0px;padding-right:10px;"       
            >
              <reservation-lopesan-form
                v-if="isBook && activity.RequiresReservation && (!activity.TimeLimit || (new Date().getTime() - activity.TimeLimit.getTime()) > 0)"
                :ID="activity.TareaPiaID"
                :hotelID="hotelID"
                :color="data.ActtivColor ? data.ActtivColor : 
                        data.ActtivPrimaryColor ? data.ActtivPrimaryColor : BGColor"          
                :token="token"
                :title="activity.Title.toUpperCase()"
                :activity="activity"
                :onShowNotification="handleShowNotification"
              />
            </v-flex>
            <v-flex
              v-if="!isLopesan && activity && !activity.IsOLD && isBook"
              xs12
              style="text-align: left;padding-bottom:5px;padding-top: 0px;padding-right:10px;"       
            >
              <reservation-form
                v-if="isBook && activity.RequiresReservation && (!activity.TimeLimit || (new Date().getTime() - activity.TimeLimit.getTime()) > 0)"
                :ID="activity.TareaPiaID"
                :hotelID="hotelID"
                :color="data.ActtivColor ? data.ActtivColor : 
                        data.ActtivPrimaryColor ? data.ActtivPrimaryColor : BGColor"          
                :token="token"
                :title="activity.Title.toUpperCase()"
                :activity="activity"
                :onShowNotification="handleShowNotification"
              />
              <reservation-notification
                v-if="reservation && isBook"
                :data="reservation"                 
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-flex>
</template>

<script>
  import { mapState } from 'vuex'
  import utils from '@/services/utils'
  //import constants from '@/constants'
  import AddCalendar from './AddCalendar'
  import ReservationForm from './ReservationForm'
  import ReservationLopesanForm from './ReservationLopesanForm'
  import ReservationNotification from './ReservationNotification'
  export default {
    components: {
      AddCalendar,
      ReservationForm,
      ReservationLopesanForm,
      ReservationNotification
    },
    props: {
      hotelID: {
        type: String,
        required: true
      },
      isBook: {
        type: Boolean,
        default: false
      },
      token: {
        type: String,
        required: true
      },
      activity: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
        required: true
      },
      data: {
        type: Object,
        required: true
      },
      onRefreshList: {
        type: Function,
        required: true
      }
    },
    data: () => ({      
      primaryColor: null,
      secundaryColor: null,
      titleColor: null,
      reservation: null,
      title: '',
    }),
    computed: {
      ...mapState('app',['locale', 'FontContent', 'FontSizeContent', 'BGColorContent', 'BGColor', 'Color', 'BGColorSecundary', 'ColorSecundary', 'BGColorContent', 'ColorContent']),
      isSimulation () {
        return true
        //return constants.simulationsRutes.indexOf(this.$route.path.split('/')[1]) >= 0
      },
      isDesktop () {
        return !utils.isMobile()
      },
      isDevelopment () {
        return process.env.VUE_APP_ENV === 'development'
      },
      isLopesan () {
        const location = window.location
        return location.host.indexOf('.lopesan.') > 0 || location.host.indexOf('.dtouch.local') > 0
      }
    },
    watch: {
      'data.ActtivPrimaryColor' () {
        this.checkActtivPrimaryColor()
      },
      'data.ActtivSecundaryColor' () {
        this.checkActtivSecundaryColor()
      },
      'data.ActtivColor' () {
        this.checkActtivTitleColor()
      },
      locale () {
        this.title = this.activity.Name[this.locale] && this.activity.Name[this.locale].length > 0 ? this.activity.Name[this.locale] : this.activity.Title    
      }
    },
    mounted () {
      if (this.data) {
        this.checkActtivPrimaryColor()
        this.checkActtivSecundaryColor()
        this.checkActtivTitleColor()
      }
      this.title = this.activity.Name[this.locale] && this.activity.Name[this.locale].length > 0 ? this.activity.Name[this.locale] : this.activity.Title    
    },
    methods: {
      checkActtivPrimaryColor () {
        this.primaryColor = this.data.ActtivPrimaryColor ? this.data.ActtivPrimaryColor : this.BGColor
      },
      checkActtivSecundaryColor () {
        this.secundaryColor = this.data.ActtivSecundaryColor ? this.data.ActtivSecundaryColor : this.BGColorSecundary
      },
      checkActtivTitleColor () {
        this.titleColor = this.data.ActtivColor ? this.data.ActtivColor : 
                          this.data.ActtivPrimaryColor ? this.data.ActtivPrimaryColor : this.BGColor
      },
      handleShowNotification (value) {
        this.reservation = value.data
        this.onRefreshList()
      }
    }
  }
</script>
<style scoped>
  .activity .container {
    padding: 0px;
  }
  .layout.activity.justify-center {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .activity .v-toolbar__content {
    padding: 0px !important;
    margin: auto !important;
  }
  .activity-button {
    margin-left: 10px;
  }
  .activity-button:hover {
    opacity: 0.7;
  }
  .td:hover {
    cursor: pointer;
  }
</style>
