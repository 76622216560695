<template>
  <div>
    <item
      v-for="item in checkJSON(content.PollFormData)"
      :key="item.id"
      :data="item"
      :updateKey="content.UpdateKey"
      :onUpdateData="handleUpdateData"
    />
    <div style="padding: 20px;">
    <v-btn
      @click="handleSendPoll"
      block
      :color="config.BtnBackgroundColor"
    >
      <span :style="{ 'color' : config.BtnTextColor }">
        {{ $t('common.send', locale) }}
      </span>
    </v-btn>
    </div>
    <v-snackbar
      v-model="showAlert"
      color="success"
    >
      {{ $t('dtouch.survey.messages.sendDataSuccess', locale) }}
 
      <template >
        <v-btn
          dark
          text
          @click="showAlert = false"
        >
          {{$t('common.close', locale)}}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Item from './Item'
export default {
  name:'Item35Poll',
  components: {
    Item,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    body: [],
    showAlert: false,
  }),
  methods: {
    checkJSON (v) {
      return typeof v === 'string' ? JSON.parse(v) : v
    },
    handleUpdateData (id, v) {
      const aux = this.body.filter(x => x.id === id).shift()
      if (aux) aux.value = v
      else
        this.body.push({
          id,
          value: v
        })
    },
    handleSendPoll () {
      axios.post(api.getAPIURL('dtouch', `v1/public/surveys/${this.content.MenuContentID}`), this.body)
        .then(() => {
          this.showAlert = true
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
}
</script>

