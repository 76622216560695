<template>
  <p
    class="food-ingredients"
    :style="{ 'color': config.ColorContent,
      'font-size': `${(config.FontSizeContent - 5)}px`,
      'font-family': config.FontContent, 
    }"
  >
    {{ingredients}}
  </p>
</template>
<script>
import utils from '@/services/utils'
export default {
  name: 'FoodIngredients',
  props: {
    content: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    ingredients: '',
  }),
  watch: {
    'content.FoodIngredients' () {
      this.prepareData()
    },
    locale () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.content.FoodIngredients) this.ingredients = utils.getTranslation(this.content.FoodIngredients, this.locale) 
    }
  },
}
</script>
<style scoped>
.food-ingredients {
  line-height: 1.2;
  margin-bottom: 5px;
  padding-top: 5px;
}
</style>

