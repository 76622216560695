import { render, staticRenderFns } from "./Item33ChannelList.vue?vue&type=template&id=a131c1b8&scoped=true&"
import script from "./Item33ChannelList.vue?vue&type=script&lang=js&"
export * from "./Item33ChannelList.vue?vue&type=script&lang=js&"
import style0 from "./Item33ChannelList.vue?vue&type=style&index=0&id=a131c1b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a131c1b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VFlex,VImg,VLayout,VList,VListItem,VListItemTitle,VMenu,VTextField})
