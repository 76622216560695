<template>
  <iframe 
    v-if="url"
    :src="url"
    width="100%" height="300" style="border:0;" allowfullscreen="" loading="lazy"
  />
</template>
<script>
export default {
  name: 'Map',
  props: {
    item: {
      type: [Object, String],
      required: true,
    },
  },
  data: () => ({
    place: null,
    url: null
  }),
  mounted () {
    this.place = typeof this.item === 'string' ? JSON.parse(this.item) : this.item

    if (!this.place.coordinates) return
    const coordinates = this.place.coordinates.split(',')
    if (coordinates.length !== 2) return
    this.url = `https://maps.google.com/maps?q=${coordinates[0]}, ${coordinates[1]}&z=15&output=embed`
    //this.url = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3530.493157003917!2d-15.570889884936653!3d27.763774982768595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x72cef1d416e69128!2zMjfCsDQ1JzQ5LjYiTiAxNcKwMzQnMDcuMyJX!5e0!3m2!1ses!2ses!4v1645789094025!5m2!1ses!2ses"
  },
}
</script>
<style scoped>
.place p {
  margin: 0 !important;
}
.place .label {
  font-size: 0.7rem;
}
</style>

