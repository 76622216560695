<template>
  <div style="padding:20px;">{{question}}
    <div v-if="data.type==='multiSelection'">
      <v-checkbox
        v-model="response"
        v-for="item in data.values"
        :key="item.id"
        :label="item.answerLocale"
        :value="item.id"
        hide-details
        multiple
      />
    </div>
    <v-container fluid 
      v-else-if="data.type==='singleSelection'"
    >
      <v-radio-group v-model="response" :mandatory="false">
        <v-radio 
          v-for="item in data.values"
          :key="item.id"
          :label="item.answerLocale"
          :value="item.id"
        />
      </v-radio-group>
    </v-container>
    <v-container v-else-if="data.type==='rating'">
      <center>
        <v-rating
          v-model="response"
          color="yellow darken-3"
          background-color="grey darken-1"
          empty-icon="$ratingFull"
          half-increments
          hover
        />
      </center>
    </v-container>
    <v-container v-else-if="data.type==='number'">
      <v-text-field
        v-model="response"
        type="number"
        :min="data.min"
        :max="data.max"
      />
    </v-container>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
export default {
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    updateKey: {
      type: String,
      required: false
    },
    onUpdateData: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      question: null,
      response: null
    }
  },
  computed: {
    ...mapState('app',['locale']),
  },
  watch: {
    locale () {
      this.prepareData()
    },
    'data.question' () {
      this.prepareData()
    },
    'updateKey' () {
      this.prepareData()
    },
    response (v) {
      this.onUpdateData(this.data.id, v)
    }
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.question = utils.getTranslation(this.data.question, this.locale)
      this.data.values = this.data.values.map(item => {
        item.answerLocale = utils.getTranslation(item.answer, this.locale)
        return item
      })
    }
  }
}
</script>

