<template>
  <div 
    class="result-html"
    style="padding: 5px 10px;"
  >
    <v-card :style="{ 'background-color': `${config.FoodDrinkBGColor} !important`}" >
      <v-layout wrap>
        <v-flex xs12>
          <v-container style="padding: 5px !important">
            <table class="food-result" style="width: 100%">
              <tr>
                <td v-if="config.ShowDishesImage && content.FoodImage" style="width:35%; vertical-align:top;">
                  <image-in-line 
                    :content="content" 
                    :config="config"
                    :locale="locale" 
                  />  
                </td>
                <td class="name" style="vertical-align: top;" :style="{ 'padding-left' : config.ShowDishesImage ? '10px' : '0' }">
                  <food-title
                    v-if="content"
                    :content="content"
                    :locale="locale"
                    :config="config"
                  />
                  <food-ingredients
                    v-if="content"
                    :content="content"
                    :locale="locale"
                    :config="config"
                  />
                  <allergens-in-line
                    v-if="config.ShowDishesAllergens && content && content.FoodAllergens"
                    :allergens="content.FoodAllergens"
                    :columnType="!config.ShowDishesImage || !content.FoodImage ? 'xs1' : 'xs2'"
                  />
                </td>
                <td class="price" style="vertical-align:top;white-space: nowrap; text-align: right;">
                  <p
                    style="line-height: 1;padding: 5px 0 0 0;"
                    :style="{ 'color': config.ColorContent,
                      'font-size': `${config.FontSizeContent}px`,
                      'font-family': config.FontContent, 
                    }"
                  >
                    {{ content.FoodPrice }}
                  </p>
                  <food-note
                    v-if="content"
                    :content="content"
                    :locale="locale"
                    :config="config"
                  />
                  <v-layout wrap :class="`justify-end`">
                    <v-flex
                      v-if="content.FoodAllergens && content.FoodAllergens.length && !config.ShowDishesAllergens"
                      v-bind="{ [`xs${content.FoodImage && content.FoodAllergens && content.FoodAllergens.length ? 6 : 12}`]: true }"                  
                      :style="{ 'width' : `${content.FoodImage && content.FoodAllergens && content.FoodAllergens.length ? 75 : 30}px`}"
                    >
                      <!--allergens 
                        :data="content"
                        :config="config"
                        :locale="locale" 
                      /-->
                    </v-flex>
                    <v-flex
                      v-if="content.FoodImage && !config.ShowDishesImage"
                      v-bind="{ [`xs${content.FoodImage && content.FoodAllergens && content.FoodAllergens.length ? 6 : 12}`]: true }"
                      :style="{ width: `${content.FoodImage && content.FoodAllergens && content.FoodAllergens.length ? 75 : 30}px`}"
                    >
                      <image-modal 
                        :data="content"
                        :config="config"
                        :locale="locale"
                      />
                    </v-flex>
                  </v-layout>
                </td>
              </tr>
            </table>
          </v-container>
        </v-flex>
        <dish-orders
          v-if="config.FoodDrinkAllowOrders"
          :config="config"
          :locale="locale"
          :content="content"
        />
      </v-layout>
    </v-card>
  </div>
</template>
<script>
import utils from '@/services/utils'
import FoodTitle from './Title'
import FoodIngredients from './Ingredients'
import FoodNote from './Note'
import ImageModal from './Image'
import ImageInLine from './ImageInLine'
import AllergensInLine from './AllergensInLine'
import DishOrders from './DishOrders'
export default {
  name:'Item27Food',
  components: {
    FoodTitle,
    FoodIngredients,
    FoodNote,
    ImageModal,
    ImageInLine,
    AllergensInLine,
    DishOrders,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    foodName () {
      return this.content.FoodName ? utils.getTranslation (this.content.FoodName, this.locale) : ''
    },
    foodIngredients () {
      return this.content.FoodIngredients ? utils.getTranslation (this.content.FoodIngredients, this.locale) : ''
    },
    foodNote () {
      return this.content.FoodNote ? utils.getTranslation (this.content.FoodNote, this.locale) : ''
    },
    foodAllergens () {
      return this.content.FoodAllergens ? utils.getTranslation (this.content.FoodAllergens, this.locale) : ''
    },
  },
}
</script>

