<template>
  <div
    :style="{ 'color': textColor,
      'font-size': `${fontSize}px`,
      'font-family': config.FontContent, 
      'text-transform': content.Style.ToUpperCase ? 'uppercase' : 'none' ,
      'text-align': content.Style.TextAlign,
      'padding': isDesktop ? '15px 30px' : '10px 15px' 
    }"
  >
    {{ title }}
  </div>
</template>
<script>

import utils from '@/services/utils'
export default {
  name:'Item16Text',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    textColor: null,
  }),
  computed: {
    title () {
      return this.content.Title ? utils.getTranslation (this.content.Title, this.locale) : ''
    },
    fontSize () {
      return this.config.FontSizeContent + this.content.Style.SizeMult
    },
  },
  watch: {
    'content.Style.TextColor': {
      handler (v) {
        this.textColor = v ? v : this.ColorContent
      },
      deep: true
    },
  },
  mounted () {
    this.textColor = this.content.Style.TextColor ? this.content.Style.TextColor : this.config.ColorContent
  },
}
</script>

