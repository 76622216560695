<template>
 <v-row
    class="item-contact pt-2"
    style="text-align: left;width:100%;max-width:300px;"
    :style="cssProps"
  >
    <v-col cols="2" style="text-align:right;">
      <v-icon
        v-if="icon"
      >
        {{ icon }}
      </v-icon>
    </v-col>
    <v-col>
      <span
        v-if="icon === 'mdi-whatsapp'"
      >
        <a :href="`https://wa.me/${description}`"
          style="text-decoration: none;"
        >
          {{description}}
        </a>
      </span>
      <span
        v-else-if="icon === 'mdi-phone'"
      >
        <a :href="`tel:${description}`"
          style="text-decoration: none;"
        >
          {{description}}
        </a>
      </span>
      <span
        v-else-if="icon === 'mdi-email'"
      >
        <a :href="`mailto:${url ? url : description}`"
          style="text-decoration: none;"
        >
          {{description}}
        </a>
      </span>
      <span
        v-else-if="['mdi-web', 'mdi-youtube', 'mdi-twitter','mdi-facebook', 'mdi-linkedin','mdi-instagram'].indexOf(icon) >= 0"
      >
        <a :href="`${url ? url : description}`"
          target="_blank"
          style="text-decoration: none;"
        >
          {{description}}
        </a>        
      </span>
      <span
        v-else-if="['mdi-map-marker'].indexOf(icon) >= 0 && coordenates"
      >
        <a :href="`${coordenates}`"
          target="_blank"
          style="text-decoration: none;"
        >
        {{description}}
        </a> 
      </span>
      <span
        v-else
      >
        {{description}}
      </span>
    </v-col>
  </v-row>
</template>
<script>
import utils from '@/services/utils'
export default {
  name:'Item17ContactItem',
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: null,
    },

  },
  computed: {
    cssProps () {
      return {
        '--colorContent': this.config.ColorContent,
        '--fontSize': `${this.config.FontSizeContent}px`,
        '--fontFamily': this.config.FontContent,
      }
    },
    icon () {
      return this.content.ContactIcon && this.content.ContactIcon.length > 0 ? this.content.ContactIcon : null
    },
    description () {
      if (!this.content.ContactDescription) return null
      const aux = utils.getTranslation (this.content.ContactDescription, this.locale)
      return aux && aux.length > 0 ? aux : null

    },
    url () {
      if (!this.content.ContactURL) return null
      const aux = utils.getTranslation (this.content.ContactURL, this.locale)
      return aux && aux.length > 0 ? aux : null
    },
    coordenates () {
      if (!this.content.ContactLatLon || !this.content.ContactLatLon.Lat || !this.content.ContactLatLon.Lon || this.content.ContactLatLon.Lat.length() === 0 || this.content.ContactLatLon.Lon.length === 0) return null
      return `https://maps.google.com/?q=${this.content.ContactLatLon.Lat}%2C${this.content.ContactLatLon.Lon}`
    },
  },
}
</script>
<style scoped>
.item-contact {
  color: var(--colorContent) !important;
  font-family: var(--fontFamily);
  font-size: var(--fontSize);
}
.item-contact span,a {
  font-family: var(--fontFamily);
  font-size: var(--fontSize);
}
.item-contact * {
  color: var(--colorContent) !important;
}
</style>

