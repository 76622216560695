<template>
  <div 
    style="position: fixed;top:0;"
    :style="{ top: `${top}px`, left: `${left}px`}"
  >
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      dark
      elevation="0"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#CCCCCC"
          v-bind="attrs"
          v-on="on"
          x-small
        >
          + INFO
        </v-btn>
      </template>

      <v-card
        style="opacity: 0.8;"
      >
        <!--v-list>
          <v-list-item>
            <v-list-item-avatar>
              <img
                src="https://cdn.vuetifyjs.com/images/john.jpg"
                alt="John"
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>John Leider</v-list-item-title>
              <v-list-item-subtitle>Founder of Vuetify</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
              >
                <v-icon>mdi-heart</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider/-->
        <v-list>
          <v-list-item
            v-for="(item, index) of config.points"
            :key="index"
          >
            <v-list-item-action>
              {{item.Pos}}
            </v-list-item-action>
            <v-list-item-title>{{ getTranslation(item.Title, locale)}}</v-list-item-title>
        <v-divider/>
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="menu = false"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import utilsService from '@/services/utils'
export default {
  props: {
    top: {
      type: Number,
      default: null,
    },
    left: {
      type: Number,
      default: null,
    },
    config: {
      type: Object,
      requried: true,
    },
    locale: {
      type: String,
      requried: true,
    }
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),
  mounted () {
  },
  methods: {
    getTranslation (v, locale) {
      return utilsService.getTranslation(v, locale)
    },
  },
}
</script>
<style scoped>
.v-menu__content {
  margin-left: 10px;
}
</style>

