<template>
  <div>
    <span style="visibility:hidden;font-size:0;">{{url}}</span>
  <pdf
    v-if="!showPdfTranslateLink"
    :src="src"
    :page="page"
    :rotate="debug ? 90 : 0"
    style="width: 100%"
    @error="errorPDF"
  />
  <v-container
    v-if="!showPdfTranslateLink"
      grid-list-md
      text-xs-center
      style="padding: 0px;"
    >
      <v-layout
        row
        wrap
      >
        <v-flex
          v-if="numPages > 1"
          xs12
          style="margin-bottom:20px;margin-top:20px;"
        >
          <v-card
            :style="{ 'color': Color.concat(' !important'), 'background-color': BGColor.concat(' !important') }"
            dark
            style="width:200px;margin:auto;opacity:0.8;"
          >
            <v-layout
              align-center
              justify-center
            >
              <v-icon
                class="mr-1"
                @click="() => handleChangePage(-1)"
              >
                mdi-arrow-left-bold-circle
              </v-icon>
              <span
                class="subheading mr-2"
                style="padding: 10px;"
              >
                {{ sPage }}
              </span>
              <v-icon
                class="mr-1"
                @click="() => handleChangePage(1)"
              >
                mdi-arrow-right-bold-circle
              </v-icon>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <center v-if="showDownloadPdf"><v-btn text @click="handleDownloadPDF" :color="ColorContent">{{$t('common.download')}}</v-btn></center>
    <center v-if="showPdfTranslateLink"><v-btn text @click="handleOpenFile" :color="ColorContent">{{linkName}}</v-btn></center>
  </div>
</template>

<script>
  import {
    mapState
  } from 'vuex'
  import utils from '@/services/utils'
  import axios from 'axios'
  import pdf from 'vue-pdf'
  // const demo = require('@/assets/demo.pdf')
  export default {
    components: {
      pdf
    },
    props: {
      url: {
        type: String,
        required: false,
        default: null
      },
      downloadPdf: {
        type: [Boolean, Number],
        required: false,
        default: false
      },
      pdfTranslateLink: {
        type: [Boolean, Number],
        required: false,
        default: false
      },
      pdfTranslateLinkName: {
        type: [Object, String],
        required: false,
        default: null
      }
    },
    data: () => ({
      loading: true,
      src: null,
      page: 1,
      numPages: null,
      showDownloadPdf: false,
      showPdfTranslateLink: false,
      linkName: ''
    }),
    computed: {
      ...mapState('app',['locale', 'FontContent', 'FontSizeContent', 'ColorContent', 'Color', 'BGColor']),
      languages () {
        return this.$store.state.app.Languages
      },
      sPage () {
        return `${utils.pad(this.page.toString(), this.numPages.toString().length)} / ${utils.pad(this.numPages.toString(), this.numPages.toString().length)}`
      },
      debug () {
        return this.$route.query.debug && this.$route.query.debug.length > 0
      }
    },
    watch: {
      'url': {
        handler: function (newValue) {
          this.loadPDF(newValue)
        },
        deep: true
      },
      'downloadPdf': {
        handler: function (newValue) {
          this.showDownloadPdf = newValue
        },
        deep: true
      },
      'pdfTranslateLink': {
        handler: function (newValue) {
          this.showPdfTranslateLink = newValue
        },
        deep: true
      },
      'pdfTranslateLinkName': {
        handler: function (newValue) {
          this.linkName = newValue ? utils.getTranslation(newValue, this.locale) : '..'
        },
        deep: true
      },
    },
    mounted() {
      if (this.url) this.loadPDF(this.url)
      this.showDownloadPdf = this.downloadPdf ? true : false
      this.showPdfTranslateLink = this.pdfTranslateLink ? true : false
      this.linkName = this.pdfTranslateLinkName ? utils.getTranslation(this.pdfTranslateLinkName, this.locale) : '..'
    },
    methods: {
      errorPDF (error) {
        console.log('error', error)
      },
      loadPDF (value) {
        this.loading = true
        // if (this.src) this.src.destroy()
        this.src = new pdf.createLoadingTask(`${value}`)
        try {
          this.src.then(pdf => {
            this.numPages = pdf.numPages
            this.page = 1
            this.loading = false
          })
        } catch {
          this.loading = false
        }
        
      },
      handleChangePage (value) {
        this.page = (this.page + value) > 0 && (this.page + value) <= this.numPages ? this.page + value : this.page
      },
      handleOpenFile () {
        window.open(this.url, "_blank");
      },
      handleDownloadPDF () {
        axios({
          method: 'get',
          url: this.url,
          responseType: 'arraybuffer'
        })
        .then(response => {
          this.forceFileDownload(response)
        })
        .catch(() => console.log('error occured'))
      },
      forceFileDownload(response){
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'file.pdf') //or any other extension
        document.body.appendChild(link)
        link.click()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .video-responsive {
    position: relative;
    padding-bottom: 56.25%; /* 16/9 ratio */
    padding-top: 30px; /* IE6 workaround*/
    height: 0;
    overflow: hidden;
  }

  .video-responsive iframe,
  .video-responsive object,
  .video-responsive embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
