<template>
  <div v-if="content.IconsURL">
    <center v-if="content.IconsAlign === 'center'">
      <v-img
        :src="content.IconsURL"
        style="width:120px; height:auto;"
      />
    </center>
    <v-img
      v-else-if="content.IconsAlign === 'left'"
      :src="content.IconsURL"
      style="width:120px; height:auto;margin-right:0"
    />
    <div v-else
      class="d-flex justify-end"
    >
      <div>
        <v-img
          :src="content.IconsURL"
          style="width:120px; height:120px;"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'Item43Icons',
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

