<template>
  <div 
    class="order-cart"
    :style="cssProps"
  >
    <v-layout wrap
      v-show="!showSnackbar"
    >
      <v-flex xs12>
    <v-data-table
      :headers="headers"
      :items="orderItems"
      hide-default-footer
      class="elevation-0"
      :mobile-breakpoint="0"
    >
      <template v-slot:no-data>
        <p>{{$t('dtouch.dishOrders.noData', locale)}}</p>
      </template>
      <template v-slot:item.image="{ item }">
        <v-img 
          v-if="item.FoodData.FoodImage" 
          :src="item.FoodData.FoodImage"
          :aspect-ratio="16/12"
        />
      </template>
      <template v-slot:item.Price="{ item }">
        <span>
          {{ item.Price }}{{item.Currency}}
        </span>
        <span v-if="item.Price2 !== null && !percentage">
          <br />*{{ item.Price2 }}{{item.Currency}}
        </span>
        <span v-if="percentage" style="font-size:0.8rem">
          <br />*{{item.PriceDiscount}}{{item.Currency}}
        </span>
      </template>
      <template v-slot:item.Name="{ item }">
        {{ item.ExtraData ? ' ↳ ' : '' }}{{ prepareName(item.FoodData.FoodName, locale, item.Name) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="handleDeleteItem(item)"
        >
          mdi-close
        </v-icon>
      </template>
    </v-data-table>
      </v-flex>
    </v-layout>
    <v-divider 
      v-show="!showSnackbar"
    />
    <v-layout 
      v-if="total !== null && orderItems && orderItems.length > 0"
      v-show="!showSnackbar"
      wrap 
    >
      <v-flex xs3 style="padding: 10px">
        <h2>{{$t('dtouch.dishOrders.total', locale)}}</h2>
      </v-flex>
      <v-flex xs9 style="padding: 10px 15px 10px 10px;text-align: right;">
        <h2>{{sTotal}}</h2>
        <h2 v-if="note" style="opacity: 0.8;font-size:0.8rem;font-style: italic;">
          {{ getTranslation(note, locale) }}
        </h2>
        <h2 v-if="allInclusive && !percentage && sTotal2 !== null">{{sTotal2}}</h2>
        <h2 v-if="percentage">{{sTotalPercentage}}</h2>
      </v-flex>
      <!--v-flex
        v-if="note"
        xs12
      >
        <small 
          class="note"
        >
          {{ getTranslation(note, locale) }}
        </small>
      </v-flex-->
      <v-flex xs6 style="padding: 10px;">
        <v-btn
          block
          :disabled="loading"
          @click="handleCancelOrder"
        >
          {{$t('dtouch.dishOrders.clearOrder', locale)}}
        </v-btn>
      </v-flex>
      <v-flex xs6 style="padding: 10px;">
        <form-confirm
          v-if="formConfirm && formConfirm.length > 0"
          :config="config"
          :formConfirm="formConfirm"
          :locale="locale"
          :loading="loading"
          :showForm="showFormConfirm"
          :onShowForm="() => {showFormConfirm = true}"
          :onCancel="handleCancelFormConfirm"
          :onConfirm="handleConfirmOrder"
          :terms="terms"
        />
        <v-btn
          v-else
          block
          :color="config.BGColor"
          :disabled="loading"
          @click="handlePrepareConfirmOrder"
          :style="{ color: config.Color }"
        >
          {{$t('dtouch.dishOrders.confirmOrder', locale)}}
        </v-btn>
      </v-flex>
      <v-snackbar
        key="result"
        v-model="showSnackbar"
        :timeout="timeout"
      >
        <p v-if="title">
          {{ title }}
        </p>
        <p v-if="subtitle">
          <small>
            {{ subtitle }}
          </small>
        </p>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="handleCloseConfirmOrderMessage"
          >
            {{ $t('dtouch.dishOrders.close', locale) }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-layout>
    <div
      v-if="showSnackbar"
      style="padding: 20px 20px 5px 20px;"
    >
      <v-alert 
        type="success"
        dense
        outlined
      >
        <span v-if="title">
          {{ title }}
        </span>
        <span v-if="subtitle">
          <small>
            {{ subtitle }}
          </small>
        </span>
      </v-alert>
    </div>
      <!--center
        v-if="showSnackbar"
        style="width: 100%;"
      >
        <p v-if="title">
          {{ title }}
        </p>
        <p v-if="subtitle">
          <small>
            {{ subtitle }}
          </small>
        </p>
      </center-->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apiDishOrders from '@/services/apiDishOrders'
import utils from '@/services/utils'
import FormConfirm from './FormConfirm'
export default {
  components: {
    FormConfirm,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      default: '€',
    },
    percentage: {
      type: Number,
      default: null,
    },
    allInclusive: {
      type: Boolean,
      default: false,
    },
    terms: {
      type: Object,
      default: null,
    },
    note: {
      type: Object,
      default: null,
    },
    formConfirm: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    headers: [],
    order: null,
    orderItems: [],
    total: null,
    total2: null,
    totalPercentage: null,
    sTotal: null,
    sTotal2: null,
    sTotalPercentage: null,

    showSnackbar: false,
    timeout: 5000,//1000,
    title: null,
    subtitle: null,

    showFormConfirm: false,
  }),
  computed: {
    ...mapState('app',['dtouchDishOrder']),
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
    },
    orderID () {
      return this.dtouchDishOrder ? this.dtouchDishOrder.OrderID : null
    },
    pointID () {
      return this.dtouchDishOrder ? this.dtouchDishOrder.point : null
      //return this.$route.query.point
    },
    headers_() {
      return [
        {
          text: this.$t('dtouch.dishOrders.image', this.locale),
          align: 'center',
          sortable: false,
          value: 'image',
          class: 'header-image',
          cellClass: 'cell-image',
        },

        {
          text: this.$t('dtouch.dishOrders.name', this.locale),
          align: 'start',
          sortable: false,
          value: 'Name',
          class: 'header',
          cellClass: 'cell',
        },
        {
          text: this.$t('dtouch.dishOrders.price', this.locale),
          align: 'end',
          sortable: false,
          value: 'Price',
          class: 'header',
          cellClass: 'cell',
        },
        { text: '', 
          value: 'actions', 
          align: 'end',
          sortable: false,
          class: 'header-action',
          cellClass: 'cell-action',
        },
      ]
    },
    cssProps () {
      return {
        '--bg-color-content': this.config.BGColorContent,
        '--color-content': this.config.ColorContent,
      }
    },
  },
  watch: {
    'dtouchDishOrder.LMD' () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    prepareHeader (containsImage) {
      const aux = []
      if (containsImage) aux.push({
          text: this.$t('dtouch.dishOrders.image', this.locale),
          align: 'center',
          sortable: false,
          value: 'image',
          class: 'header-image',
          cellClass: 'cell-image',
        })
      aux.push(...[
        {
          text: this.$t('dtouch.dishOrders.name', this.locale),
          align: 'start',
          sortable: false,
          value: 'Name',
          class: 'header',
          cellClass: 'cell',
        },
        {
          text: this.$t('dtouch.dishOrders.price', this.locale),
          align: 'end',
          sortable: false,
          value: 'Price',
          class: 'header',
          cellClass: 'cell',
        },
        { text: '', 
          value: 'actions', 
          align: 'end',
          sortable: false,
          class: 'header-action',
          cellClass: 'cell-action',
        },
      ])
      this.headers = aux
    },
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareName (v, locale, def) {
      const aux = utils.getTranslation(v, locale)
      return aux && aux.length > 0 ? aux : def
    },
    handleGetData () {
      if (!this.orderID) {
        this.order = null
        this.orderItems = []
        this.total = null
        return 
      }
      apiDishOrders.getAllOrderItemByOrderID (this.workspaceID, this.orderID)
        .then((response) => {
          if(!response) return 
          this.order = response.shift()
          if (this.order.OrderPointID !== this.pointID) {
            this.handleCancelOrder()
            return
          }
          this.orderItems = this.order ? this.order.Items.map(item => {
            item.Currency = this.currency
            return item
          }) : null
          this.prepareHeader(this.orderItems.filter(x => x.FoodData.FoodImage).length > 0)
          this.total = !this.order ? 0 :this.order.Items.reduce((accumulator, o) => {
            return accumulator + o.Price;
          }, 0)

          this.totalPercentage = !this.order || !this.percentage ? 0 : this.order.Items.reduce((accumulator, o) => {
            return accumulator + o.PriceDiscount
          }, 0)
          this.sTotal = `${!this.total ? 0 : this.total.toFixed(2)}${this.currency}`
          this.sTotalPercentage = `${!this.totalPercentage ? 0 : this.totalPercentage.toFixed(2)}${this.currency}`
          if (this.order.Items.filter(x => x.Price2 !== null).length > 0) {
            this.total2 = !this.order ? 0 : this.order.Items.reduce((accumulator, o) => {
              return accumulator + (o.Price2 !== null ? o.Price2 : o.Price)
            }, 0)
            if (this.total2 !== null) this.sTotal2 = `${this.total2.toFixed(2)}${this.currency}`
          }
          else this.total2 = null
        })
    },
    async handleDeleteItem (v) {
      // TODO: JHM
      if (this.$route.query.point && this.workspaceID) await apiDishOrders.preparePoint()

      apiDishOrders.deleteOrderItem (v.ID)
        .then(() => {
          this.handleGetData()
        })
    },
    handleClear () {
      apiDishOrders.setOrderSession('')
      this.$store.state.app.dtouchDishOrder.OrderID = null
      this.$store.state.app.dtouchDishOrder.LMD = new Date()
      this.handleGetData()
    },
    async handleCancelOrder () {
      // TODO: JHM
      if (this.$route.query.point && this.workspaceID) await apiDishOrders.preparePoint()

      apiDishOrders.deleteOrder ()
        .then(() => {
          this.handleClear()
        })
    },
    handleCloseConfirmOrderMessage () {
      this.showSnackbar = false
      this.handleClear()
      this.loading = false
    },
    handlePrepareConfirmOrder () {
      if(this.formConfirm && this.formConfirm.length > 0) alert('TODO: formConfirm')
      else this.handleConfirmOrder()
    },
    async handleConfirmOrder (v) {
      // TODO: JHM
      if (this.$route.query.point && this.workspaceID) await apiDishOrders.preparePoint()

      this.loading = true
      apiDishOrders.confirmOrder ('specific', v)
        .then(() => {
          if (v) this.showFormConfirm = false // if formConfirm
            this.title = this.$t('dtouch.dishOrders.messages.orderConfirmed', this.locale)
            this.showSnackbar = true
            setTimeout(() => {
              this.handleClear()
              this.loading = false
              this.showSnackbar = false
            }, this.timeout)
          //this.handleClear()
        })
    },
    handleCancelFormConfirm () {
      this.showFormConfirm = false
    },
  },
}
</script>
<style>
.order-cart {
  background-color: var(--bg-color-content) !important;
  color: var(--color-content) !important;
}
.order-cart .note {
  opacity: 0.6;
  float: right;
  font-size: 0.875rem;
  padding-right: 20px;
  font-style: italic;
}
.order-cart .v-data-table {
  background-color: var(--bg-color-content) !important;
  color: var(--color-content) !important;
  /*background-image: var(--gradient);*/
}
.order-cart .header-action {
  font-weight: bold !important;
  color: var(--color-content) !important;
}
.order-cart .cell-action {
  font-weight: normal;
  vertical-align: middle !important;
  text-align: center;
}

.order-cart .header-image {
  font-weight: bold !important;
  color: var(--color-content) !important;
  max-width: 80px !important;
}
.order-cart .cell-image {
  font-weight: normal;
  vertical-align: middle !important;
  max-width: 80px !important;
  padding: 5px 0!important;
}
.order-cart .header {
  font-weight: bold !important;
  color: var(--color-content) !important;
}
.order-cart .cell {
  font-weight: normal;
  vertical-align: top !important;
}
.order-cart .cell-action {
  font-weight: normal;
  vertical-align: top !important;
  text-align: right !important;
}
</style>

