<template>
  <div>
    <canvas 
      ref="canvas"
      :width="config.width"
      :height="config.height > 980 ? 980 : config.height"
      style="border:0 solid black;background-size: cover;"
      :style="{ backgroundImage: `url(${urlBackground})` }" 
      :locale="locale"
    />
    <legend-map
      :top="top"
      :left="left"
      :config="config"
      :locale="locale"
    />
    <map-info :value="selectedPoint" :onClose="() => {selectedPoint = null}" :locale="locale" />
  </div>
</template>
<script>
import { fabric } from 'fabric'
import MapInfo from './MapInfo'
import LegendMap from './LegendMap'
export default {
  components: {
    MapInfo,
    LegendMap,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    top: 0,
    left: 0,
    main: null,
    image: null,
    background: null,
    urlBackground: null,
    selectedPoint: null,
  }),
  computed: {
    seatMapBackground () {
      return 'http://localhost:3803/assets/72c0948f-7eef-4d82-ab84-f94862c7a37f/a2bca2ff-309f-4e84-8db6-b5d52900ca67.png'
    },
  },
  mounted () {
      this.top = this.$refs.canvas.getBoundingClientRect().top + 20
      this.left = this.$refs.canvas.getBoundingClientRect().left + 20
      this.main = new fabric.Canvas(this.$refs.canvas)
      this.background = new fabric.Image()
      this.background.onload = () => {
        this.main.renderAll()
      }
      this.main.remove(...this.main.getObjects())
      this.main.on('object:modified', this.handleChange)
      if (this.seatMapBackground) {
        this.background.src = this.config.background
        this.urlBackground = this.config.background
      }
    this.preparePoints()
  },
  methods: {
    preparePoints () {
      if (!this.config.points) return
      for (const item of this.config.points) {
        const w = item.W ? item.W : 30
        const h = item.H ? item.H : 30

        var c = new fabric.Circle({
          left: 0,
          top: 0,
          fill: item.BGColor ? item.BGColor : '#5afffa',
          stroke: '#666',
          selectable: true,
          centeredScaling:true,
          padding:2,
          hasRotatingPoint: false,
          borderColor: 'black',
          cornerColor: 'black',
          radius: (w / 2),
        })

        var t = new fabric.Text(item.Pos.toString(), {
          fontFamily: 'Calibri',
          fontSize: h*0.9,
          fill: item.Color ? item.Color : '#000000',
          textAlign: 'center',
          originX: 'center',
          originY: 'center',
          left: 0.5*w, top: 0.5*h,
        })

        var g = new fabric.Group([c, t], {
          top: item.Y * this.config.scale,
          left: item.X * this.config.scale,
          scaleX: this.config.scale > 2 ? 2 : this.config.scale,
          scaleY: this.config.scale > 2 ? 2 : this.config.scale,
          subTargetCheck: true,
        })
        g.set('selectable', false)

        g.on('mouseup', () => { 
          this.handleClick(item)
        })

        this.main.add(g)
        /*
        g.setControlsVisibility({
          bl: false,
          tl: false,
          tr: false,
          br: false,
          mt: false, // middle top disable
          mb: false, // midle bottom
          ml: false, // middle left
          mr: false, // I think you get it
          mtr: false,// rotate
        })*/
/*
        var g = new Group([c],{
            // any group attributes here
          top: item.Y * this.scale,
          left: item.X * this.scale,
          scaleX: this.scale,
          scaleY: this.scale,
        })
        
        .setControlsVisibility({
          bl: false,
          tl: false,
          tr: false,
          br: false,
          mt: false, // middle top disable
          mb: false, // midle bottom
          ml: false, // middle left
          mr: false, // I think you get it
          mtr: false,// rotate
        })*/ 
        
      }
    },
    handleClick (v) {
      this.selectedPoint = JSON.parse(JSON.stringify(v))
    },
  },
}
</script>

