<template>
  <div>
    <v-tooltip v-model="showFoodVegan" top>
      <span>{{ $t('food.vegan', locale) }}</span>
    </v-tooltip>
    <v-tooltip v-model="showFoodVegetarian" top>
      <span>{{ $t('food.vegetarian', locale) }}</span>
    </v-tooltip>
    <v-tooltip v-model="showFoodNew" top>
      <span>{{ $t('food.new', locale) }}</span>
    </v-tooltip>
    <v-tooltip v-model="showFoodRecommended" top>
      <span>{{ $t('food.recommended', locale) }}</span>
    </v-tooltip>
    <v-tooltip v-model="showFoodSlowFood" top>
      <span>Slow food</span>
    </v-tooltip>
    <p
      class="food-title"
      :style="{ 'color': config.FoodDrinkTextColor ? config.FoodDrinkTextColor : config.ColorContent,
        'font-size': `${config.FontSizeContent}px`,
        'font-family': config.FontContent, 
      }"
    >
      <v-icon v-if="content.FoodVegan && !config.FoodIconRight" style="color: #a9c366;" @click="showFoodVegan=!showFoodVegan">mdi-leaf</v-icon>
      <v-icon v-if="content.FoodVegetarian && !config.FoodIconRight" style="color: #436f45;" @click="showFoodVegetarian=!showFoodVegetarian">mdi-leaf</v-icon>
      <v-icon v-if="content.FoodNew && !config.FoodIconRight" style="color: red;" @click="showNew=!showNew">mdi-new-box</v-icon>
      <v-icon v-if="content.FoodRecommended && !config.FoodIconRight" style="color: orange;" @click="showFoodRecommended=!showFoodRecommended">mdi-star-box</v-icon>
      <v-icon v-if="content.FoodSlowFood && !config.FoodIconRight" style="color: red;" @click="showFoodSlowFood=!showFoodSlowFood">mdi-snail</v-icon>
      {{ name }}
      <v-icon v-if="content.FoodVegan && config.FoodIconRight" style="color: #a9c366;" @click="showFoodVegan=!showFoodVegan">mdi-leaf</v-icon>
      <v-icon v-if="content.FoodVegetarian && config.FoodIconRight" style="color: #436f45;" @click="showFoodVegetarian=!showFoodVegetarian">mdi-leaf</v-icon>
      <v-icon v-if="content.FoodNew && config.FoodIconRight" style="color: red;" @click="showFoodNew=!showFoodNew">mdi-new-box</v-icon>
      <v-icon v-if="content.FoodRecommended && config.FoodIconRight" style="color: orange;" @click="showFoodRecommended=!showFoodRecommended">mdi-star-box</v-icon>
      <v-icon v-if="content.FoodSlowFod && config.FoodIconRight" style="color: red;" @click="showFoodSlowFood=!showFoodSlowFood">mdi-snail</v-icon>
    </p>
  </div>
</template>
<script>
import utils from '@/services/utils'
export default {
  name: 'FoodTitle',
  props: {
    content: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    name: '',
    showFoodVegan: false,
    showFoodVegetarian: false,
    showFoodNew: false,
    showFoodRecommended: false,
  }),
  watch: {
    'content.FoodName' () {
      this.prepareData()
    },
    locale () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.content.FoodName) this.name = utils.getTranslation (this.content.FoodName, this.locale) 
    }
  },
}
</script>
<style scoped>
.food-title {
  margin-bottom: 5px;
  line-height: 1.2;
  padding: 5px 0 0 0;
}
</style>

