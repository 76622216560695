<template>
  <v-row>
    <v-snackbar
      v-model="snackbar"
      color="error"
    >
      ERROR: {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{$t('common.close')}}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <template v-slot:activator="{  }">
        <!--char-auth :dialogAuth="dialogAuth" :onAuth="handleAuth" /-->
        <v-chip
          class="ma-2"
          :color="color"
          small
          dark
          style="margin-left: 10px !important;"
          :disabled="activity.Availability === 0"
          @click="checkAvailability"
        >
          {{$t('activity.book').toUpperCase()}}
        </v-chip>
      </template>
      <v-card v-if="reservationResult && authData">
        <v-btn :color="color" dark @click="dialog = false" block style="height:100px;margin-bottom:20px;">{{$t('common.close')}}</v-btn>
      </v-card>
      <v-card v-else-if="!reservationResult">
        <v-toolbar dark :color="color">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{title}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <!-- <v-btn dark text @click="handleCreateReservation">{{$t('activity.book')}}</v-btn> -->
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader :style="{ 'color': color }">{{ $t('acttiv.form.title') }}</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('acttiv.form.numPeople')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  v-model="numPeople"
                  type="number"
                  :min="1"
                  :label="$t('acttiv.form.numPeople')"
                  single-line
                  @input="handleChangeNumPeople"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-layout style="padding: 20px;" wrap>
              <v-flex xs12 
                v-for="(item, index) in participants"
                :key="index"
                style="margin-bottom:20px;"
              >
                <v-card
                  style="background-color: #CCCCCC80;"
                >
                <h2 style="padding:10px;">{{$t('acttiv.form.participant')}} {{index+1}}</h2>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{$t('acttiv.form.participantName')}}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-text-field
                          v-model="item.nombre"
                          :label="$t('acttiv.form.participantName')"
                          single-line
                        />
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="activity.KidsOnly">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('acttiv.form.age')}}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-text-field
                          v-model="item.edad"
                          type="number"
                          :min="0"
                          :max="120"
                          :label="$t('acttiv.form.age')"
                          single-line
                          @change="handleChangeAge"
                        />
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{$t('acttiv.form.allergy')}}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-text-field
                          v-model="item.alergia"
                          :label="$t('acttiv.form.allergy')"
                          single-line
                        />
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="activity.KidsOnly && item.edad < 13">
                        <v-checkbox
                          hide-details
                          v-model="item.menor_puede_circular_solo"
                          :label="$t('acttiv.form.youngerExit')"
                        />
                  </v-list-item>
                  <v-list-item v-if="activity.KidsOnly && item.edad < 13">
                        <v-checkbox
                          v-model="item.autoriza_asear_menor_emergencia"
                          :label="$t('acttiv.form.youngerEmergency')"
                          hide-details
                        />
                  </v-list-item>
                </v-card>
              </v-flex>
            </v-layout>
            <v-divider />
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t(someChildParticipant ? 'acttiv.form.tutorName' : 'acttiv.form.name')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  :counter="50"
                  v-model="body.nombre_tutor"
                  :label="$t(someChildParticipant ? 'acttiv.form.tutorName' : 'acttiv.form.name')"
                  single-line
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('acttiv.form.lastName')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  v-model="body.apellidos_tutor"
                  :label="$t('acttiv.form.lastName')"
                  single-line
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('acttiv.form.dni')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  v-model="body.dni_tutor"
                  :label="$t('acttiv.form.dni')"
                  single-line
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('acttiv.form.phone')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  v-model="body.telefono_tutor"
                  :label="$t('acttiv.form.phone')"
                  single-line
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('acttiv.form.room')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  v-model="body.habitacion"
                  append-icon="mdi-search"
                  :label="$t('acttiv.form.room')"
                  single-line
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('acttiv.form.email')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  v-model="body.email"
                  append-icon="mdi-search"
                  :label="$t('acttiv.form.email')"
                  single-line
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{$t('acttiv.form.note')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-textarea
                  v-model="body.nota"
                  :label="$t('acttiv.form.note')"
                  single-line
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-container>
          <v-alert type="error" v-if="error">
            {{error}}
          </v-alert>
        </v-container>
        <v-btn :color="color" dark @click="handleCreateReservation" block style="height:100px;margin-bottom:20px;">{{$t('activity.book')}}</v-btn>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbarOK"
      :multi-line="multiLine"
      :timeout="-1"
    >
      {{ locator }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbarOK = false"
        >
          {{$t('common.close')}}
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
  import axios from 'axios'
  import _ from 'lodash'
  import uuidv1 from 'uuid/v1'
  //import CharAuth from '@/components/CharAuth'

  export default {
    name: 'reservation-form',
    components: {
    //  CharAuth
    },
    props: {
      hotelID: {
        type: String,
        required: true
      },
      ID: {
        type: Number,
        required: true
      },
      color: {
        type: String,
        required: true
      },
      token: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: false,
        default: ''
      },
      activity: {
        type: Object,
        required: true
      },
      onShowNotification: {
        type: Function,
        required: true
      }
    },
    data () {
      return {
        dialogAuth: false,
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        snackbar: false,
        snackbarOK: false,
        message: '',
        error: null,
        someChildParticipant: false,

        numPeople: 1,
        participants: [
            {
              nombre: null,
              edad: 0,
              alergia: null,
              menor_puede_circular_solo: false,
              autoriza_asear_menor_emergencia: false
            }
          ],
        body: {
          id_tarea_pia: this.ID,
          nombre_tutor: this.authData && this.authData.Name ? this.authData.Name : null,
          apellidos_tutor: null,
          dni_tutor: null,
          telefono_tutor: null,
          habitacion: null,
          idioma: null,
          email: null,
          fecha_entrada: null,
          fecha_salida: null,
          notas: null,
          asistentes: []
        },
        reservationResult: null,

        multiLine: true,
        locator: ''
      }
    },
    computed: {
      forChildren () {
        return this.activity.KidsOnly || this.activity.AdultsAndKids
      },
      authData () {
        return this.$store.state.app.authData
      }
    },
    created () {
      this.handleChangeNumPeople = _.debounce(() => {
        this.onChangeNumPeople()
      }, 500)      
    },
    methods: {
      validations() { 
        return {
          //name: { required: true, maxLength: maxLength(50) },
        }
      },
      handleChangeAge () {
        this.someChildParticipant = this.participants.filter(x => x.edad < 13).length > 0
      },
      onChangeNumPeople () {
        this.participants = []
        for (let i = 0; i < this.numPeople; i++) {
          this.participants.push({
            nombre: null,
            edad: 0,
            alergia: null,
            menor_puede_circular_solo: false,
            autoriza_asear_menor_emergencia: false
          })
        }
      },
      clearData () {
        this.reservationResult = null
        this.participants= [
            {
              nombre: null,
              edad: 0,
              alergia: null,
              menor_puede_circular_solo: false,
              autoriza_asear_menor_emergencia: false
            }
          ],
        this.body = {
          localizador: `${uuidv1()}`,
          id_tarea_pia: this.ID,
          nombre_tutor: null,
          apellidos_tutor: null,
          dni_tutor: null,
          telefono_tutor: null,
          habitacion: null,
          idioma: null,
          email: null,
          fecha_entrada: null,
          fecha_salida: null,
          notas: null,
          asistentes: []
        }

        if (this.authData) {
          this.body.nombre_tutor = this.authData.Name ? this.authData.Name : null
          this.body.apellidos_tutor = this.authData.Surname ? this.authData.Surname : null
          this.body.telefono_tutor = this.authData.Phone ? this.authData.Phone : null
          this.body.email = this.authData.Email ? this.authData.Email : null
          this.body.habitacion = this.authData.Room ? this.authData.Room : null
          this.participants[0].nombre = this.authData.Name ? this.authData.Name : null
        }
      },
      handleAuth() {
        if (this.dialog) {
          this.dialog = false
          setTimeout(() => {
            this.dialog = true
          }, 500)
        } else this.dialog = true
      },
      checkAvailability () {
        if(!this.authData) {
          if (this.dialogAuth) {
            this.dialogAuth = false
            setTimeout(() => {
              this.dialogAuth = true
            }, 500)
          } else this.dialogAuth = true
        } else {
          this.clearData()
          const www = process.env.VUE_APP_ENV === 'development' ? '' : 'www.'
          const url = `${window.location.protocol}//${www}${process.env.VUE_APP_API_URL}/public/acttiv/availability/${this.ID}?token=${this.token}`

          axios.get(url)
          .then(response => {
            const availability = response.data.disponibilidad_actual
            if (!availability || availability > 0) this.dialog = true
          })
          .catch(function (error) {
            console.log(error)
          })
        }        
      },
      checkForm () {
        // Os env�o los datos del formulario de adulto (con asterisco los campos que son obligatorios y en rojo aquellos que son opcionales):
        // Nombre y apellido/s*

        // N�mero de habitaci�n*
        // Idioma*
        // Correo electr�nico*
        // Alergia (opcional)
        
        // Y aqu� los del formulario para las actividades de ni�o:
        // Nombre y apellido/s padre/tutor*

        // DNI/PASAPORTE/NIE (opcional)
        // Idioma*
        // Correo electr�nico*
        // Tel�fono m�vil*
        // Nombre y apellidos menor*
        // Rango edad � 13 a�os*
        // N�mero de habitaci�n*
        // Fecha entrada (opcional)
        // Fecha salida (opcional)
        // Menor con alergia/enfermedad (opcional)
        // Menor puede salir/entrar sol@ del club (opcional)
        // No autoriza a asear al menor en caso de emergencia (opcional)
        if (this.someChildParticipant) {
          if (!this.body.telefono_tutor || this.body.telefono_tutor.length === 0) {
            this.error = this.$t('acttiv.form.error.telefono_tutorNotValid')
            return false
          }
        }
        if (!this.body.habitacion || this.body.habitacion.length === 0) {
          this.error = this.$t('acttiv.form.error.habitacionNotValid')
          return false
        }
        if (!this.body.email || this.body.email.length === 0) {
          this.error = this.$t('acttiv.form.error.emailNotValid')
          return false
        }
        if (!this.body.nombre_tutor || this.body.nombre_tutor.length === 0) {
          this.error = this.$t('acttiv.form.error.tutorNameNotValid')
          return false
        }
        for(const item of this.participants) {
          if (!item.nombre || item.nombre.length === 0) {
            this.error = this.$t('acttiv.form.error.participantNameNotValid')
            return false
          }
        }
        return true
      },
      handleCreateReservation () {
        if (!this.checkForm()) return
        this.error = null
        const www = process.env.VUE_APP_ENV === 'development' ? '' : 'www.'
        const url = `${window.location.protocol}//${www}${process.env.VUE_APP_API_URL}/public/acttiv/reservations?token=${this.token}`

        const that = this
        this.body.asistentes = this.participants
        
        this.body.GuestID = this.authData.ID
        this.body.activity = this.activity

        axios.post(url, this.body)
          .then(response => {
            if (response.data.resultado === 'ERROR') {
              that.error = response.data.error
            } else {
              that.handleCheckReservations(response.data.localizador)
            }
          })
          .catch((error) => {
            that.error = error.error
            console.log(error)
          })
      },
      handleCheckReservations (locator) {
        this.locator = locator
        this.snackbarOK = true
        this.dialog = true
        const www = process.env.VUE_APP_ENV === 'development' ? '' : 'www.'
        const url = `${window.location.protocol}//${www}${process.env.VUE_APP_API_URL}/public/acttiv/reservations/${this.hotelID}/${locator}?token=${this.token}`

        axios.get(url)
          .then(response => {
            this.reservationResult = response
            this.onShowNotification(response)
            this.dialog = false
          })
          .catch((error) => {
            console.log(error)
          })
      },
    },
  }
</script>
