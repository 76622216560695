<template>
  <div>
    <v-layout wrap>
      <v-flex v-bind="{ [columnType]: true }"
        v-for="item in selectedAllergens"
        :key="item.ID"
        style="padding:3px;"
        :style="{'max-width': isDesktop ? '30px' : null}"
      >
        <center>
          <v-img     
            :src="item.Image"
            style="width: 24px;height:auto"
          />
        </center>
      </v-flex>
    </v-layout>    
  </div>
</template>
<script>
  import utils from '@/services/utils'
  export default {
    props: {
      allergens: {
        type: Array,
        required: true,        
      },
      config: {
        type: Object,
        required: true,
      },
      columnType: {
        type: String,
        required: false,
        default: 'xs2'
      },
      dtouchWorkspace: {
        type: String,
        required: true,
      },

    },
    data () {
      return {
        allergensResponse: [],
        selectedAllergens: []
      }
    },
    computed: {
      isDesktop () {
        return !utils.isMobile();
      },
    },
    mounted () {
      this.getAllergens ()
      /*
      const enterpriseIDDomain = this.$store.state.app.enterpriseID ? this.$store.state.app.enterpriseID : utils.getDomain()
      api.getItem('utils', false, `allergens/${enterpriseIDDomain}`).then(response => {
        this.allergensResponse = response.data.map(item => {
          item.FullName = item.Name,
          item.Name = utils.getTranslation (item.Name, this.locale)
          item.Image = `https://www.dtouch.es/assets/allergens/${item.Image}.png`
          return item
        })
        
        this.selectedAllergens = this.allergensResponse.filter(x => this.allergens.indexOf(x.ID)>=0)
      })
      */
    },
    methods: {
      getAllergens () {
        const urlBase = process.env.VUE_APP_API_URL_DTOUCH
        const url = `${window.location.protocol}//${urlBase}/v1/public/workspaces/${this.dtouchWorkspace}/allergens`
        fetch(url, { 
          method: 'get', 
          headers: new Headers({
            'Content-Type': 'application/json'
          }), 
        })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          const response = data.shift().data
          this.allergensResponse = response.map(item => {
          item.FullName = item.Name,
          item.Name = utils.getTranslation (item.Name, this.locale)
          item.Image = `https://www.dtouch.es/assets/allergens/${item.Image}.png`
          return item
        })
        
        this.selectedAllergens = this.allergensResponse.filter(x => this.allergens.indexOf(x.ID)>=0)

        })
      },
    }
  }
</script>
