<template>
  <v-img
    v-if="content.Image"
    :src="content.Image"
    style="width: 100%"
  />
</template>
<script>
export default {
  name:'Item4Image',
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

