<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          style="cursor: pointer;"
          @click="onChange"
        >
             (*) {{$t('dtouch.dishOrders.formConfirm.acceptTerms.accept', locale)}}
        </span>
        <span
          v-bind="attrs"
          v-on="on"
          style="cursor: pointer;text-decoration: underline;"
        >
          {{$t('dtouch.dishOrders.formConfirm.acceptTerms.terms', locale)}}
        </span>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2" style="text-align: left !important;">
          {{$t('dtouch.dishOrders.formConfirm.acceptTerms.title', locale)}}
        </v-card-title>

        <v-card-text>
          <br />
          {{sTerms}}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
          >
            {{ $t('common.close', locale) }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="handleChange"
          >
            {{ $t('common.ok', locale) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  export default {
    props: {
      locale: {
        type: String,
        required: true,
      },
      sTerms: {
        type: String,
        default: ''
      },
      onChange: {
        type: Function,
        required: true,
      },
    },
    data: () => ({
      dialog: false,
    }),
    methods: {
      handleChange () {
        this.onChange()
        this.dialog = !this.dialog
      },
    },
  }
</script>
