<template>
  <center
    style="padding-left: 50px;padding-right:50px; padding-top:10px;padding-bottom:10px;"    
  >
    <v-btn
      block
      style="height: 50px;"
      :color="config.BGColorSecundary"
      :style="{ 'color': config.ColorSecundary}" 
      dark
      @click="handleGoTo"
    >
      {{ text }}
    </v-btn>
  </center>
</template>
<script>
import utils from '@/services/utils'
export default {
  name:'Item32LinkExternal',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    textColor: null,
  }),
  computed: {
    text () {
      return this.content.LinkExternalName ? utils.getTranslation(this.content.LinkExternalName, this.locale) : ''
    },
  },
  methods: {
    handleGoTo () {
      const url = utils.getTranslation (this.content.LinkExternal, this.locale)
      if (this.content.LinkExternalNewTab)
        window.open(
          url,
          '_blank' // <- This is what makes it open in a new window.
        )
      else window.location.href = url
    }        
  }
}
</script>

