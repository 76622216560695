<template>
  <div v-if="config">
    <v-row
      v-for="item of items"
      :key="item.ID"
      align="center"
      justify="center"
      :style="{ color: config.ColorContent }"
      style="margin:0;"
    >
      <v-col :cols="12" style="text-align: center;padding: 5px 10px;">
        <v-card style="width:100%;">
          <v-container style="padding: 5px !important">
            <table class="requests-result" >
              <tr style="min-height: 150px;">
                <td style="vertical-align:top;max-width:100px;width:30%;">
                  <v-img 
                    v-if="item.Image"
                    :src="item.Image"
                    style="width:100%;height: auto;"
                  />
                </td>
                <td 
                  class="name" 
                  style="text-align: left;padding-left:10px;"
                  :style="{ 
                    'font-family': config.FontContent, 
                  }"
                >
                    <p 
                      style="line-height:1;margin:0;"
                      :style="{ 
                        'font-size': `${config.FontSizeContent}px`,
                      }"
                    >
                      {{ getTranslation(item.Name, locale) }}
                    </p>
                    <p 
                      v-if="item.Description"
                      style="line-height:1;margin:0;"
                      :style="{ 
                        'font-size': `${config.FontSizeContent-5}px`,
                      }"
                    >
                      {{ getTranslation(item.Description, locale) }}
                    </p>
                    <!--small 
                      v-if="item.Description"
                    >
                      {{ getTranslation(item.Description, locale) }}
                    </small-->
                </td>
                <td class="action" style="vertical-align:middle">
                  <div style="padding:20px 0;">
                    <v-btn
                      :color="config.BtnBackgroundColor"
                      dark
                      style
                      small
                      :elevation="0"
                      :style="{ color: config.BtnTextColor }"
                      @click="handleSelectedItem(item.ID)"
                    >
                      {{$t(isIssues ? 'dtouch.request.report' : 'dtouch.request.request', locale)}}
                    </v-btn>
                    <!--v-btn
                      :color="config.ColorContent"
                      dark
                      style
                      small
                      :style="{ color: config.BGColorContent }"
                      @click="handleSelectedItem(item.ID)"
                    >
                      {{$t(isIssues ? 'dtouch.request.report' : 'dtouch.request.request', locale)}}
                    </v-btn-->
                  </div>
                </td>
              </tr>
            </table>
          </v-container>
        </v-card>
      </v-col>
      <!--v-col :cols="3" style="text-align: center;">
        <v-img 
          v-if="item.Image"
          :src="item.Image"
          style="width:100%;height: auto;"
        />
      </v-col>
      <v-col :cols="6" style="text-align: left;padding: 10px 0;">
        <p style="line-height:1;margin:0;">
          {{ getTranslation(item.Name, locale) }}aaaaa
        </p>
        <small v-if="item.Description">
          {{ getTranslation(item.Description, locale) }}
        </small>
      </v-col>
      <v-col :cols="3" style="padding:10px;vertical-align:middle;">
        <v-btn
          :color="config.ColorContent"
          dark
          style
          small
          :style="{ color: config.BGColorContent }"
          @click="handleSelectedItem(item.ID)"
        >
          {{$t(isIssues ? 'dtouch.request.report' : 'dtouch.request.request', locale)}}
        </v-btn>
      </v-col-->
    </v-row>
    <confirm-form
      :workspaceID="workspaceID"
      :locale="locale"
      :config="config"
      :selectedID="selectedID"
      :onClose="handleClose"
      :onSave="handleSave"
      :isIssues="isIssues"
      :loading="loading"
    />
  </div>
</template>
<script>
import apiDtouch from '@/services/apiDtouch'
import utils from '@/services/utils'
import ConfirmForm from './ConfirmForm'
export default {
  name:'Item52Requests53Issues',
  components: {
    ConfirmForm,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    items: [],
    selectedID: null,
  }),
  computed: {
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
      //return utils.getWorkspaceIdentification ()
    },
    isIssues () {
      return this.type === 53
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetData () {
      apiDtouch.getAll (`v2/public/workspaces/${this.workspaceID}/${this.isIssues ? 'issues' : 'requests'}-manager`)
        .then(response => {
          if (!response) return
          this.items = response.map(item => {
            return item
          })
        })
    },
    handleSelectedItem (v) {
      this.selectedID = v
    },
    handleClose () {
      this.selectedID = null
    },
    handleSave (v) {
      this.loading = true
      apiDtouch.addItem (`v2/public/${this.isIssues ? 'issues' : 'requests'}`, v)
        .then(response => {
          if (!response) return
          if (response.data.status === 'OK') {
            if (v.Room && v.Room.length > 0) localStorage.setItem('f_room', v.Room)
            if (v.FullName && v.FullName.length > 0) localStorage.setItem('f_name', v.FullName)
            if (v.Email && v.Email.length > 0) localStorage.setItem('f_email', v.Email)

            alert(this.$t('dtouch.request.messages.ok', this.locale))
            this.handleClose()
          } else alert(this.$t(`dtouch.request.messages.${response.data.message}`, this.locale))
          
          this.loading = false
        })
        .catch(() => {
          alert ('ERROR')
          this.loading = false
        })
    },
  },
}
</script>
<style scoped>
  .requests-result {
    table-layout: fixed;
    width: 100% !important;
    max-width: 100% !important;
  }
  .requests-result .name {
    width: auto;
    line-height: 1;
  }
  .requests-result .action {
    width: 100px;
    /*white-space: nowrap;*/
    text-align: right;
    vertical-align: top;
  }
</style>

