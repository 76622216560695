<template>
  <div 
    v-if="content"
    style="padding-left: 20px;padding-right:20px;"
  >
    <table width="100%">
      <tr>
        <td width="20%" style="vertical-align: middle;">
          <table width="100%" height="50px;">
            <tr>
              <td width="auto" />
              <td width="50px" style="text-align:right;">
                <v-img
                  v-if="content.IconListImage"
                  style="width: 50px; height: 50px;postion: absolute; right:0px;"
                  :src="content.IconListImage"        
                />
              </td>
            </tr>
          </table>
        </td>
        <td width="80%" style="vertical-align: middle;line-height:1; padding-left: 10px;"
          :style="{ 'font-family' : config.FontContent, 'font-size': `${config.FontSizeContent}px`, 'color': config.ColorContent}"
        >
          {{title}}<br />
          <span style="opacity: 70%" :style="{ 'font-size': `${config.FontSizeContent -2}px`}">{{description}}</span>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import utils from '@/services/utils'
export default {
  name:'Item37IconList',
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: null,
    },

  },
  computed: {
    title () {
      return this.content.IconListTitle ? utils.getTranslation (this.content.IconListTitle, this.locale) : ''
    },
    description () {
      return this.content.IconListDescription ? utils.getTranslation (this.content.IconListDescription, this.locale) : ''
    },
  },
}
</script>

