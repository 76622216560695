<template>
  <div v-if="!loading">
    <pdf-translate-visor
      v-for="(item, index) in pdfData"
      :key="`item${index}`"
      v-show="locale === item.locale"
      :url="item.url"
      :downloadPdf="content.DownloadPdfTranslate"
      :pdfTranslateLink="content.PDFTranslateLink"
      :pdfTranslateLinkName="content.PDFTranslateLinkName"
    />
  </div>
</template>
<script>
import utils from '@/services/utils'
import PdfTranslateVisor from './PDFTranslateVisor'
export default {
  name:'Item31PDFTranslate',
  components: {
    PdfTranslateVisor,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    pdfData: null,
  }),
  computed: {
    languages () {
      return this.config.Languages
    },
  },
  watch: {
    'content.URLPdfTranslate' (v) {
      if (v) this.loadPDFData(v)
    },
  },
  mounted () {
    if (this.content.URLPdfTranslate) this.loadPDFData(this.content.URLPdfTranslate)
  },
  methods: {
    errorPDF (v) {
      console.log(v)
    },
    loadPDFData (v) {
      if (!v) return 
      this.loading = true
      const aux = []
      for (const lng of this.languages) {
        const url = utils.getTranslation(v, lng)
        if (url && url.length > 0)
          aux.push({
            url,
            locale: lng
          })
      }
      this.pdfData = aux
      this.loading = false
    },
  }
}
</script>

