<template>
  <center
    style="padding-left: 50px;padding-right:50px; padding-top:10px;padding-bottom:10px;"    
  >
    <v-btn
      block
      style="height: 50px;"
      :color="config.BGColorSecundary"
      :style="{ 'color': config.ColorSecundary}" 
      dark
      @click="handleGoTo"
    >
      {{ getTranslation(content.LinkDomainName, locale) }}
    </v-btn>
  </center>
</template>
<script>
import utils from '@/services/utils'
export default {
  name:'Item28LinkDomain',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isTotem () {
      return this.$route.path.indexOf('/totem') === 0
    },
    dtouchIsTouchPublic () {
       return this.$store.state.app.dtouchIsTouchPublic
    },
    dfileResp () {
      return this.$session ? this.$session.get('dfilesResponse') : null
    },
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGoTo () {
      if (this.isTotem) this.handleTotemChangeEnterprise (this.content.LinkDomain)
      else {
        let url = `${window.location.protocol}//${this.content.LinkDomain}.${process.env.VUE_APP_API_URL}`
        url += `#/?back=${utils.getDomain()}`
        if (this.locale !== 'es' || this.dtouchIsTouchPublic) url += '&locale=' + this.locale
        if (this.dtouchIsTouchPublic) url += '&touchPublic=true&forceIMG=true'
        
        if (this.dfileResp) url += `&dfileResp=${this.dfileResp}`
        
        window.location.href = url
      }        
    },
    handleTotemChangeEnterprise (domain) {
      this.$store.state.app.totemBackDomain = this.$store.state.app.totemDomain
      this.$store.state.app.totemDomain = domain
    },
  }
}
</script>

