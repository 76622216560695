<template>
  <table
    style="width: 100%"
  >
    <tr
      v-for="(row, index) of rows"
      :key="row.id"
    >
      <td
        :class="(index+1) === rows.length ? 'rows-no-border' : 'rows'"
      >
        <p class="row-name" style="font-weight:900 !important;">{{row.name}}</p>
        <p 
          class="row-option"
          v-for="option of row.options"
          :key="option.id"
          style="font-weight: 400;"
        >
        <span v-if="option.name && option.name.length > 0 && getValue(mealID, row.id, option.id)" style="font-weight:900 !important">{{option.name}}</span><v-icon v-else-if="getValue(mealID, row.id, option.id)" :color="color2">mdi-circle-medium</v-icon> {{ getValue(mealID, row.id, option.id) }}
        </p>
      </td>
    </tr>
  </table>
</template>
<script>
export default {
  name: 'MealRow',
  props: {
    rows: {
      type: Array,
      required: true,
    },
    mealID: {
      type: String,
      required: true,
    },
    values: {
      type: Array,
      required: true,
    },
    color2: {
      type: String,
      default: '#ed7b0a'
    }
  },
  data: () => ({

  }),
  methods: {
    getValue (mealID, rowID, optionID) {
      let result = this.values.filter(x => x.Meal === mealID && x.Row === rowID && x.Option === optionID).map(item => item.Value).shift()
      result = result && result !== undefined && result.length > 0 ? result : null
      return result
    },
  },
}
</script>
<style scoped>
p {
  line-height: 1;
}
td {
  padding: 15px 3%;
}
.rows {
  border-bottom: 3px dotted; 
}
.rows-no-border {
  border: none;
}

.row-name {
  font-size: 1.2rem;
}
.row-option {
  font-size: 1rem;
  padding-left: 10px;
}

</style>


