<template>
  <v-expansion-panels
    v-if="show"
    v-model="panel"
    accordion
    active-class="menu-main"
    :style="cssProps"
  >
    <v-expansion-panel
      active-class="menu-panel"
    >
      <v-expansion-panel-header
        hide-actions
        class="menu"
        v-bind:id="item.id"
        style="border-radius: 0"
        :style="{
          'background-color': item.bgColor ? item.bgColor : item.isPrimary ? config.BGColor : config.BGColorSecondary,
          'color': item.textColor ? item.textColor : item.isPrimary ? config.Color : config.ColorSecondary
        }"
      >
      <div class="menu-container">
        <div class="menu-simulated">
          <center>
            <v-icon 
              :color="item.textColor ? item.textColor : item.isPrimary ? config.Color : config.ColorSecondary"
              :style="{
                'font-size': config.FontSize + 'px'
              }"
              style="margin-right: 5px;"
            >
              mdi-shopping-outline
            </v-icon>
            {{ getTranslation(item.name, locale) }}</center>
        </div>
      </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="menu-content"
        v-bind:id="item.id.concat('content')"
        :style="{
          'background-color': item.bgColorContent ? item.bgColorContent : config.BGColorContent,
          'color': item.textColorContent ? item.textColorContent : config.ColorContent
        }"
      >
        <list 
          :locale="locale"
          :config="config"
          :currency="currency"
          :note="note"
          :percentage="percentage"
          :allInclusive="allInclusive"
          :formConfirm="formConfirm"
          :terms="terms"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import apiDishOrders from '@/services/apiDishOrders'
import List from './List'
export default {
  name: 'DishOrdersCart',
  components: {
    List,
  },
  props: {
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    show: false,
    panel: null,
    item: null,
    orderID: null,
    currency: '€',
    percentage: null,
    allInclusive: false,
    note: null,
    terms: null,
  }),
  computed: {
    ...mapState('app',['dtouchDishOrder', 'dtouchWorkspace']),
    point () {
      return this.$route.query.point
    },
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
    },
    cssProps () {
      return {
        '--line': this.config.LineMenu === 1 ? '0.5px solid rgba(255, 255, 255, 0.3)' : null,
        // menu
        //'--bg-color': this.config.BGColor,
        //'--color': this.config.Color,
        '--font': this.config.Font,
        '--font-size': this.config.FontSize + 'px',
        '--text-transform': this.config.TextFormat === 1 ? 'uppercase' : 'initial',
        '--gradient': this.config.GradientMenu === 1 ? 'linear-gradient(to top, rgba(128, 128, 128, 0), rgba(0, 0, 0, 0.2))' : 'none',
        // secondary
        //'--bg-color-secondary': this.config.BGColorSecundary,
        //'--color-secondary': this.config.ColorSecundary,
        '--font-secondary': this.config.FontSecundary,
        '--font-size-secondary': this.config.FontSizeSecundary + 'px',
        '--text-transform-secondary': this.config.TextFormatSecondary === 1 ? 'uppercase' : 'initial',
        '--gradient-secondary': this.config.GradientSubmenu === 1 ? 'linear-gradient(to top, rgba(128, 128, 128, 0), rgba(0, 0, 0, 0.2))' : 'none',
        // content
        //'--bg-color-content': this.config.BGColorContent,
        //'--color-content': this.config.ColorContent,
        '--font-content': this.config.FontContent,
        '--font-size-content': this.config.FontSizeContent + 'px',
      }
    },
  },
  watch: {
    panel () {
      console.log(this.panel)
    },
    dtouchWorkspace () {
      this.preparePointToken()
    },
    dtouchDishOrder () {
      this.prepareMenu()
    },
  },
  mounted () {
    this.preparePointToken()
      /*
    if (!this.dtouchDishOrder && apiDishOrders.getOrderSession()) {
      //apiDishOrders.getOrderData()
      this.$store.state.app.dtouchDishOrder = {}
      this.$store.state.app.dtouchDishOrder.OrderID = apiDishOrders.getOrderSession()
      this.$store.state.app.dtouchDishOrder.LMD = new Date()
    } else this.prepareMenu()
      */
  },
  methods: {
    preparePointToken () {
      if (this.dtouchWorkspace) apiDishOrders.preparePoint()
      this.prepareMenu()
    },
    getColor(type, color) {
      return color ? color : this.config[type]
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareCssStyle (v) {
      if (!v.ID) return
      if (document.getElementById(v.ID) !== null) {
        document.getElementById(v.ID).style.backgroundColor = v.bgColor ? v.bgColor : this.level === 0 ? this.config.BGColor : this.config.BGColorSecondary
        document.getElementById(v.ID).style.color = v.textColor ? v.textColor : this.level === 0 ? this.config.Color : this.config.ColorSecondary
      }
      if (document.getElementById(v.ID.concat('content')) !== null) {
        document.getElementById(v.ID.concat('content')).style.backgroundColor = v.bgColorContent ? v.bgColorContent : this.config.BGColorContent
        document.getElementById(v.ID.concat('content')).style.color = v.textColorContent ? v.textColorContent : this.config.ColorContent
      }
    },
    prepareMenu () {
      if (!this.dtouchDishOrder) {
        this.show = false
        this.item = null
        return null
      }

      this.currency = this.dtouchDishOrder.config && this.dtouchDishOrder.config.Currency ? this.dtouchDishOrder.config.Currency : '€'
      this.percentage = this.dtouchDishOrder.config && this.dtouchDishOrder.config.Percentage ? this.dtouchDishOrder.config.Percentage : null
      this.allInclusive = this.dtouchDishOrder.config && this.dtouchDishOrder.config.AllInclusive ? true : false
      this.formConfirm = []
      const fc = this.dtouchDishOrder.config && this.dtouchDishOrder.config.FormConfirm ? this.dtouchDishOrder.config.FormConfirm.split(',') : null
      if (fc)
        for (const item of fc) {
          this.formConfirm.push({
            name: item,
            type: 'text',
            value: null
          })
        }
      this.note = this.dtouchDishOrder.config && this.dtouchDishOrder.config.Note ? JSON.parse(this.dtouchDishOrder.config.Note) : null //{ es: 'Euro | All Inclusive' }
      this.terms = this.dtouchDishOrder.config && this.dtouchDishOrder.config.Terms ? JSON.parse(this.dtouchDishOrder.config.Terms) : null //{ es: 'Euro | All Inclusive' }

      this.item = {
        id: 'dishOrders',
        name: { es: 'Lista de pedidos', en: 'Order list' },
        isMenuWithSubmenus: false,
        isPrimary: true,
        bgColor: this.dtouchDishOrder.config && this.dtouchDishOrder.config.BGColor ? this.dtouchDishOrder.config.BGColor : null,
        textColor: this.dtouchDishOrder.config && this.dtouchDishOrder.config.TextColor ? this.dtouchDishOrder.config.TextColor : null,
      }
      this.show = this.dtouchDishOrder.config.FixCart || this.dtouchDishOrder.OrderID
      this.panel = 0
      /*
      console.log('-----jhm', this.dtouchDishOrder.token)
      console.log('-----jhm', this.dtouchDishOrder.point, this.dtouchDishOrder.config)
      console.log('-----jhm', this.dtouchDishOrder)
      apiDishOrders.getConfig (this.workspaceID)
        .then(response => {
          let ordersBGColor = response && response.BGColor ? response.BGColor : null
          let ordersTextColor = response && response.TextColor ? response.TextColor : null

          this.currency = response && response.Currency ? response.Currency : '€'

          this.note = response && response.Note ? JSON.parse(response.Note) : null //{ es: 'Euro | All Inclusive' }

          this.item = {
            id: 'dishOrders',
            name: { es: 'Lista de pedidos', en: 'Order list' },
            isMenuWithSubmenus: false,
            isPrimary: true,
            bgColor: ordersBGColor,
            textColor: ordersTextColor,
          }
          
          this.loading = false
        })*/
    },
  },
}
</script>
<style>
.simulation .menu {
  /*background-color: var(--bg-color);*/
  background-image: var(--gradient);
  border-top: var(--line);
  border-bottom: var(--line);
}
.simulation .menu center {
  font-size: var(--font-size);
  /*color: var(--color);*/
  font-family: var(--font);
  text-transform: var(--text-transform);
}
.simulation .menu-secondary {
  /*background-color: var(--bg-color-secondary);*/
  background-image: var(--gradient-secondary);
  border-top: var(--line);
  border-bottom: var(--line);
}
.simulation .menu-secondary center {
  /*color: var(--color-secondary);*/
  font-size: var(--font-size-secondary);
  font-family: var(--font-secondary);
  text-transform: var(--text-transform-secondary);
}
.menu-panel .v-expansion-panels {
  border-radius: 0 !important;
}
.menu-content {
  /*background-color: var(--bg-color-content);
  color: var(--color-content);*/
  font-size: var(--font-size-content);
  font-family: var(--font-content);
}
.menu-content .v-expansion-panel-content__wrap {
  padding: 0;
}
.menu-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.menu-simulated {
  width: 100%;
  position: absolute;  
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.menu-action {
  width: 100%;
  z-index: 10;
  text-align: right;
}
</style>


