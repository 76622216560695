<template 
>
   <v-layout wrap
      justify-center
               :style="cssVars"
   >
      <v-flex xs12>
         <center style="padding: 0 20px;">
            <v-date-picker
               class="booking-by-days-picker"
               no-title
               v-model="selectedDate"
               :event-color="date => date[9] % 2 ? 'red' : 'yellow'"
               :events="functionEvents"
               :picker-date.sync="pickerDate"
               @change="handleSelectDate"
               style="width: 100%"
               :locale="locale"
               :min="min"
               elevation="0"
               first-day-of-week="1"
            />
         </center>
      </v-flex>
      <v-flex xs12 style="padding: 0 20px;">
         <activities 
            v-for="(item, index) of selectedActivities"
            :key="index"
            :activity="item"
            :locale="locale"
            :content="content"
            :config="config"
            :autoExpand="selectedActivities.length ===1"
         />
      </v-flex>
   </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import utils from '@/services/utils'
import Activities from './Activities'
export default {
   components: {
      Activities,
   },
   props: {
      locale: {
         type: String,
         required: true,
      },
      content: {
         type: Object,
         required: true,
      },
      config: {
         type: Object,
         required: true,
      },
   },
   data: () => ({
      loading: false,
      selectedDate: null,
      pickerDate: null,
      arrayEvents: [],
      events: null,
      selectedEvents: null,
      selectedActivities: null,
   }),
   computed: {
      ...mapState('app', ['dtouchWorkspace']),
      min () {
         return new Date().toISOString().split('T').shift()
      },
      cssVars () {
         return {
            '--time-bg-color': this.config.BGColor,
         }
      },
   },
   watch: {
      pickerDate (v) {
         this.handleGetEvents(v)
      },
   },
   mounted () {
      /*
      this.arrayEvents = [...Array(6)].map(() => {
         const day = Math.floor(Math.random() * 30)
         const d = new Date()
         d.setDate(day)
         return d.toISOString().substr(0, 10)
      })
      */
   },
   methods: {
      handleGetEvents (v) {
         this.loading = true
         const [year, month] = v.split('-')
         const body = {
            year, 
            month
         }
         const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/cm/${this.dtouchWorkspace}/events-month${this.content.BookingActWorkspaceID ? '/'.concat(this.content.BookingActWorkspaceID) : ''}`
         axios.post(url, body)
            .then((response) => {
          console.log(response.data)
               if (this.content.BookingActActivityID) {
                  // filter by activityID
                  response.data = response.data.filter(x => x.ActivityID === this.content.BookingActActivityID)
               }
               this.arrayEvents = response.data.map(item => item.Date.split('T').shift())
               this.events = response.data

               // selected now
               this.selectedDate = new Date().toISOString().split('T').shift()
               this.handleSelectDate()
            })
            .catch((error) => {
               console.log(error)
            })
            .then(() => {
               this.loading = false
            })
      },
      functionEvents (date) {
         if (this.arrayEvents.indexOf(date) >= 0) return [this.content.BookingActTimeColor]
/*
         const [,, day] = date.split('-')
         if ([12, 17, 28].includes(parseInt(day, 10))) return true
         if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', 'green']
         return false
         */
      },
      handleSelectDate () {
         this.selectedEvents = this.events ? this.events.filter(x => x.Date.split('T').shift() === this.selectedDate) : null
         this.getActivitiesBySelectedEvents(this.selectedEvents)
      },
      getActivitiesBySelectedEvents (v) {
         this.selectedActivities = null
         //this.selectedActivities = [...new Set(v.map(item => {return { ActivityID: item.ActivityID, ActivityName: item.ActivityName }}))]
         if (!v) return
         const unique = v.map(item => item.ActivityID)
                        .filter((value, index, self) => self.indexOf(value) === index)
         
         if (unique.length === 0) return 

         this.selectedActivities = unique.map(item => {
            const aux = v.filter(x => x.ActivityID === item).shift()

            return {
               ID: aux.ActivityID,
               Name: aux.ActivityName,
               Subtitle: aux.ActivitySubtitle,
               AllowReservations: aux.AllowReservations,
               Description: aux.ActivityDescription,
               Image: aux.ActivityImage,
               CategoryName: aux.CategoryName,
               PlaceID: aux.PlaceID,
               MeetingPointID: aux.MeetingPointID,
               Events: v.filter(x => x.ActivityID === item).sort((a, b) => a.Time - b.Time).map(item => {
                  item.sTime = utils.convertTime(item.Time)
                  item.type = 'contentmanager'
                  item.Source = 'contentmanager'
                  return item
               })
            }
         })
      },
   },
}
</script>
<style scoped>
.v-btn__content {
   z-index: 1;
}
.v-date-picker-table__events .yellow {
   height: 34px;
   width: 34px;
   margin-top: -19px;
   margin-left: -2px;
   border-radius: 4px;
   background-color: var(--time-bg-color) !important;
}
.v-application .yellow {
   height: 34px;
   width: 34px;
   margin-top: -19px;
   margin-left: -2px;
   border-radius: 4px;
   background-color: var(--time-bg-color) !important;
}
               .booking-by-days-picker {
   /*
   background-color: blue !important;
   transition-property: none !important;
   transition: none !important;
   outline: none !important;
   border-width: thin !important;*/
   box-shadow: none;
               }
</style>

