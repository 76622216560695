<template>
  <v-layout
    justify-center
    class="activity"
  >
    <v-flex
      xs12
      style="padding-bottom: 0px;padding-top: 0px;padding-left: 20px;padding-right: 20px;"
    >
      <div
        style="padding-top: 15px; padding-bottom: 15px;font-size:22px;"
        class="text-center"
        :style="{ 'color': ColorContent}"
      >
        {{ $t(sDay.pre, locale).toUpperCase() }} {{ $t(sDay.month, locale).toUpperCase() }} {{ sDay.pos.toUpperCase() }}
      </div>
      <div
        style="border-radius:5px;position: static; top:0px;margin-bottom:20px;margin-top:20px;"
        :style="{ 'background-color': `${primaryColor}` }"
      >
        <v-btn-toggle v-model="daysToAdd" style="width: 100%;"
          :style="{ 'background-color': `${secundaryColor}` }"
        >
          <v-btn
            v-for="(day, index) in week"
            :key="`week${index}`"
            style="width:14.3%;min-width:14.2%;"
            :style="{ 'color': secundaryColor }"
            @click="() => handleAddDays(index)"
            :disabled="index === daysToAdd"
            :dark="!isLight(secundaryColor)"
            :light="isLight(secundaryColor)"
          >
           <span :style="{ 'color': isLight(secundaryColor) ? 'black' : 'white' }">{{ $t(`common.${day}`, locale).substring(0, 2) }}</span>
          </v-btn>          
        </v-btn-toggle>
        <div 
          v-if="!typeOriginalActivity && typesActivity"
          style="padding:20px 20px 0 20px;"
        >
          <v-select
            v-model="typeActivity"
            :items="typesActivity"
            item-text="Name"
            item-value="ID"
            solo
            :value="typeActivity"
            @change="handleChangeTypeActivity"
            :item-color="enterpriseColor"
            :color="enterpriseColor"
          />
        </div>
      </div>
      <v-container
        fluid
        grid-list-md
        style="padding: 5px"
      >
        <v-layout
          row
          wrap
          style="margin-bottom: 20px;"
        >
          <v-flex
            v-if="!activities || activities.length === 0"
            :style="{ 'background-color': ColorContent, 'color': BGColorContent }"
            style="margin: 15px;text-align: center;padding-top:10px;padding-bottom:10px;opacity:0.9;"
          >
            {{ $t('activity.messages.noActivities').toUpperCase() }}
          </v-flex>
          <v-flex
            v-for="(activity, index) in activities"
            :key="activity.ID"
            v-bind="{ [`xs${!isSimulation && isDesktop ? 6 : 12}`]: true }"
            style="margin-top: 5px;margin-bottom: 6px;"
            :style="{ 'padding-right': index % 2 === 0 && !isSimulation && isDesktop ? '10px' : '0px', 'padding-left': index % 2 !== 0 && !isSimulation && isDesktop ? '10px' : '0px' }"
          >
            <v-card
            :style="{ 'opacity': activity.Availability.finished ? '0.3' : '1'}"
              style="border-radius:5px;height:100%;"
            >
              <v-layout
                row
                wrap
                style="height:100%;"
              >
                <v-flex
                  xs4
                  style="padding: 0;height:100%;"
                >
                  <v-img
                    :src="activity.Image"
                    :lazy-src="activity.Image"
                    height="100%"
                    width="90%"
                    aspect-ratio="1.7"
                    class="grey lighten-2"
                    style="border-radius: 5px 0 0px 5px;"
                  />
                </v-flex>
                <v-flex
                  :style="{ 'color': colorActivityContent}"
                  xs8
                >
                  <v-layout
                    row
                    wrap
                    style="margin-top:5px;margin-bottom:0px;"
                  >
                    <v-flex
                      xs8
                      style="text-align: left;padding-bottom:5px;line-height:1.2;"
                      :style="{ 'color': titleColor}"
                    >
                      {{ activity.Title.toUpperCase() }}
                    </v-flex>
                    <v-flex
                      xs4
                      style="text-align: right;padding-right: 15px;padding-bottom:0px;"
                    >
                      <add-calendar
                        v-if="activity && !activity.Availability.finished"
                        :start="activity.Date"
                        :end="activity.Date"
                        :startTime="activity.TimeStart"
                        :endTime="activity.TimeEnd"
                        :title="activity.Title"
                        :description="activity.Description ? activity.Description : ''"
                        :color="secundaryColor"
                        :active="daysToAdd === 0 && activity.Availability.time"
                        :locale="locale"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      style="text-align: left;padding-top: 0px;padding-bottom: 0px;"
                    >
                      <v-icon
                        :style="{ 'color': activity.Availability.time && activity.Availability.daysToAdd === '0' ? 'rgb(85,173,11)' : descriptionColor}"                  
                        dark
                        class="activity-button"
                        style="margin-left: 0px;font-weight: 500;font-size: 17px;"
                      >
                        mdi-clock-outline
                      </v-icon>
                      <span
                        :style="{ 'color': activity.Availability.time && activity.Availability.daysToAdd === '0' ? 'rgb(85,173,11)' : descriptionColor}"                  
                        style="font-weight: 500;font-size: 17px;"
                      >
                        {{ activity.TimeStart.substring(0, 5) }}-{{ activity.TimeEnd.substring(0, 5) }}
                      </span>
                    </v-flex>
                    <v-flex
                      xs12
                      style="text-align: left;padding-top: 0px;padding-bottom: 7px; padding-right:10px;"
                      :style="{ 'color': `${descriptionColor} !important`}"
                    >
                      <span
                        :style="{ 'opacity': activity.Description && activity.Description.length > 0 ? '1' : '0'}"
                        style="font-size: 13px;line-height: normal;"
                        v-html="activity.Description && activity.Description.length > 0 ? activity.Description : '.'"
                      >
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>
<script>
import tinycolor from 'tinycolor2'
import utils from '@/services/utils'
import api from '@/services/api'
import AddCalendar from './AddCalendar'
export default {
  name:'Item13Activities',
  components: {
    AddCalendar,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dviewcontentID: null,
    activities: null,
    daysToAdd: 0,
    week: [],
    typesActivity: null,
    typeActivity: 0,
    typeOriginalActivity: null,
  }),
  computed: {
    isSimulation () {
      return false
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
    sDate () {
      return `${this.$t(this.sDay.pre, this.locale).toUpperCase()} ${this.$t(this.sDay.month, this.locale).toUpperCase()} ${this.sDay.pos.toUpperCase()}`
    },
    sDay () {
      let dToday = new Date()
      const dActual = new Date().setDate(dToday.getDate() + this.daysToAdd)
      const pos = `${new Date(dActual).getDate()}, ${new Date(dActual).getFullYear()}`

      const result = {
        pre: this.daysToAdd === 0 ? 'common.today' : this.daysToAdd === 1 ? 'common.tomorrow' : '',
        month: `common.m${new Date(dActual).getMonth()}`,
        pos
      }
      return result
    },
    enterpriseColor () {
      return this.config.BGColor
    },
    BGColorContent () {
      return this.config.BGColorContent
    },
    ColorContent () {
      return this.config.ColorContent
    },
    primaryColor () {
      return this.content.ActivityPrimaryColor ? this.content.ActivityPrimaryColor : this.config.BGColor
    },
    secundaryColor () {
      return this.content.ActivitySecundaryColor ? this.content.ActivitySecundaryColor : this.config.BGColorSecundary
    },
    titleColor () {
      return this.content.ActivityColor ? this.content.ActivityColor : this.ColorContent
    },
    colorActivityContent () {
      return this.content.ActivitySecundaryColor ? this.content.ActivitySecundaryColor : this.ColorContent
    },
    descriptionColor () {
      return this.content.ActivityDescriptionColor ? this.content.ActivityDescriptionColor : this.ColorContent
    },
  },
  watch: {
  },
  mounted () {
    this.init ()
    this.handleGetTypesActivity()
  },
  methods: {
    init () {
      this.typeActivity = this.content.TypeActivity ? this.content.TypeActivity : 0
      if(this.typeActivity >= 0) this.typeOriginalActivity = this.typeActivity
      const dSemenaActual = new Date().getUTCDay() - 1
      const aux = []
      for (let i = 0; i < 7; i++) {
        const x = ((dSemenaActual + i) >= 7) ? (dSemenaActual + i - 7) : dSemenaActual + i
        aux.push(`s${x}`)
      }
      this.week = aux
    },
    isLight (value) {
      return tinycolor(value).isLight()
    },
    handleGetTypesActivity () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/activities-types`)
        .then(response => {
          this.typesActivity = response.types
          this.dviewcontentID = response.dviewcontentID
          this.handleGetActivities()
        })
    },
    handleAddDays (value) {
      this.daysToAdd = value
      this.handleGetActivities()
    },
    handleChangeTypeActivity() {
      this.handleGetActivities()
    },
    handleGetActivities () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/activities-dviewcontent/${this.daysToAdd}/${this.typeActivity}`)
        .then(response => {
          const aux = response.data && response.data.activities ? response.data.activities : []
          const locale = this.locale === 'ca' ? 'ot' : this.locale
          for (let i = 0; i < aux.length; i++) {
            aux[i].Title = utils.getTranslation(aux[i].Title, locale)
            aux[i].Description = utils.getTranslation(aux[i].Description, locale)
          }
          this.activities = aux
        })
    },
  },
}
</script>

