<template>
   <by-days v-if="content.BookingActType === 'byDays'"
      :content="content"
      :config="config"
      :locale="locale"
      :isDesktop="isDesktop"
   />
   <by-activities v-else-if="content.BookingActType === 'byActivities'"
      :content="content"
      :config="config"
      :locale="locale"
      :isDesktop="isDesktop"
   />
   <by-events v-else-if="content.BookingActType === 'byEvents'"
      :content="content"
      :config="config"
      :locale="locale"
      :isDesktop="isDesktop"
   />
   <by-hours v-else
      :content="content"
      :config="config"
      :locale="locale"
      :isDesktop="isDesktop"
   />
</template>

<script>
import ByEvents from './byEvents/Index'
import ByHours from './byHours/Index'
import ByDays from './byDays/Index'
import ByActivities from './byActivities/Index'
export default {
   name:'Item48BookActivities',
   components: {
      ByEvents,
      ByHours,
      ByDays,
      ByActivities,
   },
   props: {
      content: {
         type: Object,
         default: null,
      },
      config: {
         type: Object,
         default: null,
      },
      locale: {
         type: String,
         default: null,
      },
      isDesktop: {
         type: Boolean,
         default: false,
      },
   },
}
</script>

