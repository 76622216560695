<template>
  <p
    v-if="note && note.length > 0" 
    class="food-note"
    :style="{ 'color': config.ColorContent,
      'font-size': `${(config.FontSizeContent - 5)}px`,
      'font-family': config.FontContent, 
    }"
  >
    {{ note }}
  </p>
</template>
<script>
import utils from '@/services/utils'
export default {
  name: 'FoodNote',
  props: {
    content: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    note: '',
  }),
  watch: {
    'content.FoodNote' () {
      this.prepareData()
    },
    locale () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.content.FoodNote) this.note = utils.getTranslation(this.content.FoodNote, this.locale) 
    }
  },
}
</script>
<style scoped>
.food-note {
  line-height: 1;
  margin-top:-10px;
}
</style>

