<template>
  <div
    v-if="loading"
    class="text-center" 
    style="padding: 20px;"
  >
    <v-progress-circular
      :size="50"
      :width="7"
      :color="config.BGColor"
      indeterminate
      style="opacity: 0.5;"
    />
  </div>
  <iframe
    v-else-if="url"
    id="iframeCustom"
    :src="url"
    :height="content && content.IframeHeight"
    frameborder="0"
    width="100%"
    seamless="seamless"
    @load="handleLoadComplete"
  />
</template>
<script>
export default {
  name:'Item5Iframe',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: true,
    url: null,
  }),
  computed: {
    isLopesan () {
      return this.content && this.content.IframeURL && this.content.IframeURL.indexOf('$LOPESAN$') > 0 
    },
    authData () {
        return this.$store.state.app.authData
    },
  },
  watch: {
    'content.IframeURL' () {
      this.prepareURL()
    }
  },
  created () {
    setTimeout(() => { this.loading = false }, 500)
  },
  mounted () {
    this.prepareURL()
  },
  methods: {
    prepareURL() {
      if (this.content && this.content.IframeURL) {
        if (this.isLopensan) this.url = this.authData && this.authData.id ? this.content.IframeURL.replace('$LOPESAN$', this.authData.id) : null
        else this.url = this.content.IframeURL
      }
      console.log(this.url)
    },
    handleLoadComplete () {
    },
  },
}
</script>
<style>
#iframeCustom {
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: hidden;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
#iframeCustom::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
  width: 0;
}
</style>

