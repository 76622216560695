<template>
  <div
    :style="{ 'height': `${content.Size}px` }"
  />
</template>
<script>
export default {
  name:'Item3Space',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

