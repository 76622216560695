<template>
  <v-btn
    v-if="!loading && !extras"
    small
    block
    @click="handleOrder"
    :color="config.BGColor"
    :style="{ color: config.Color }"
  >
    {{ $t('dtouch.dishOrders.order', locale) }}
  </v-btn>
  <div v-else-if="!loading && extras">
      <v-btn
        small
        block
        @click="showExtras = true"
        :color="config.BGColor"
        :style="{ color: config.Color }"
      >
        {{ $t('dtouch.dishOrders.order', locale) }}
      </v-btn>
    <v-row justify="center">
      <v-dialog
        v-model="showExtras"
        persistent
        scrollable
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ getTranslation(label, locale) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-for="item of extras"
                  :key="item.ExtraID"
                  cols="12"
                  sm="12"
                  md="12"
                  style="padding: 10px 0;"
                >
                  <v-card v-if="config">
                    <table class="extra">
                      <tr>
                        <td
                          style="width: 100%;"
                          :style="{ 'color': config.FoodDrinkTextColor ? config.FoodDrinkTextColor : config.ColorContent,
                            'font-size': `${config.FontSizeContent}px`,
                            'font-family': config.FontContent, 
                          }"
                        >
                          {{getTranslation(item.Name, locale)}}
                        </td>
                        <td rowspan="2"
                          style="text-align: right;width:auto !important;vertical-align: middle;"
                        >
                          <v-btn 
                            style="margin: 0 10px 10px 10px;"
                            elevation="0"
                            :color="config.BGColor"
                            small
                            dark
                            @click="item.Price ? handleAddExtra(item.ExtraID) : handleOrderWithoutExtra()"
                          >
                            {{ $t('common.add', locale) }}
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td 
                          class="price"
                          style="width: auto;"
                          :style="{ 'color': config.ColorContent,
                            'font-size': `${config.FontSizeContent-5}px`,
                            'font-family': config.FontContent, 
                          }"
                        >
                          {{item.Price}}
                        </td>
                      </tr>
                    </table>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions style="padding: 0 20px 20px 0; margin-top: -10px;">
            <v-spacer />
            <v-btn
              @click="handleClose"
              text
            >
              {{ $t('common.cancel', locale) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import utils from '@/services/utils'
import apiDishOrders from '@/services/apiDishOrders'
export default {
  props: {
    contentID: {
      type: String,
      required: true,
    },
    onOrder: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    label: {
      type: [Object,String],
      default: null,
    },
  },
  data: () => ({
    loading: true,
    extras: null,
    showExtras: false,
    selectedExtras: [],
  }),
  mounted () {
    this.handleGetExtras ()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    getExtra (v, locale) {
      let aux = this.extras.filter(x => x.ExtraID === v)
      return aux && aux.length > 0 ? utils.getTranslation(aux[0].Name, locale).concat(` (${aux[0].Price})`) : ''
    },
    handleGetExtras () {
      if (!this.contentID) return
      apiDishOrders.getExtras(this.contentID) 
        .then((response) => {
          this.extras = response && response.length > 0 ? response.map(item => {
            item.Name = item.Name ? JSON.parse(item.Name) : { es: '' }
            return item
          }) : null
          this.loading = false
        })
    },
    handleClose () {
      this.selectedExtras = []
      this.showExtras = false
    },
    handleAddExtra (v) {
      this.selectedExtras.push(v)
      this.handleOrder()
    },
    handleOrder () {
      this.onOrder(this.selectedExtras && this.selectedExtras ? this.selectedExtras : null)
      this.handleClose()
    },
    handleOrderWithoutExtra () {
      this.onOrder()
      this.handleClose()
    }
  }
}
</script>
<style scoped>
.extra {
  width: 100%;
}
.extra td {
  padding: 10px 10px 0 10px;
}
.extra .price {
  line-height:1;
  padding: 10px 10px 10px 10px;
}
</style>

