<template>
  <div :style="{ 'padding': `${content.DividerUp ? content.DividerUp : 0}px ${content.DividerRight ? content.DividerRight : 15}px ${content.DividerDown ? content.DividerDown : 0}px ${content.DividerLeft ? content.DividerLeft : 15}px ` }">
    <v-divider
      :dark="content.DividerDark ? true : false"
    />
  </div>
</template>
<script>
export default {
  name:'Item18Divider',
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

