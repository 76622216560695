<template>
   <v-card
      class="mx-auto"
      max-width="344"
      style="margin: 20px;"
   >
   
   <v-img
      v-if="activity.Image"
      :src="activity.Image"
      :key="activity.Image"
      height="200px"
      @click="show = !show"
    />

      <v-card-title
         @click="show = !show"
         :style="{ color: content.BookingActTitleColor }"
      >
       {{ getTranslation(activity.Name, locale) }}
    </v-card-title>

    <v-card-subtitle
        @click="show = !show"
         :style="{ color: content.BookingActTitleColor }"
       style="padding: 0 15px;"
       >
       {{ subtitle ? getTranslation(subtitle, locale) : '' }}
    </v-card-subtitle>

    <v-card-actions>
      <v-btn
        :color="content.BookingActWeekSecondaryColor"
        text
        @click="show = !show"
      >
         {{ $t(`booking.${activity.AllowReservations != 0 ? 'reservation' : 'info'}`, locale) }}
      </v-btn>

      <v-spacer/>

      <v-btn
         v-if="showDetails"
        icon
        @click="show = !show"
        :color="content.BookingActWeekSecondaryColor"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition v-if="showDetails">
      <div v-show="show">
        <v-divider/>
      <div style="padding: 10px 10px 0 10px;text-align: center;">
      <reservation-form
         v-for="(item, index) of activity.Events"
         :key="index"
         :event="item"
         :locale="locale"
         :color="content.BookingActTimeColor"
         :timeLimit="activity.TimeLimit !== null"
         small
      />
      </div>

         <v-card-text 
            v-if="activity.Description"
            :style="{ color: content.BookingActContentColor }"
         >
           <p style="font-size: 10px;font-weight: 300;line-height: 1.2;" v-html="getTranslation(activity.Description, locale)" />
           <place
              :placeID="activity.PlaceID"
              :label="$t('booking.place', locale)"
              :locale="locale"
           />
           <place
              :placeID="activity.MeetingPointID"
              :label="$t('booking.meetingPoint', locale)"
              :locale="locale"
           />
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import utils from '@/services/utils'
import ReservationForm from './ReservationForm'
import Place from '../Place'
export default {
   components: {
      ReservationForm,
      Place,
   },
   props: {
      activity: {
         type: Object,
         required: true,
      },
      locale: {
         type: String,
         required: true,
      },
      content: {
         type: Object,
         required: true,
      },
      config: {
         type: Object,
         required: true,
      },
      autoExpand: {
         type: Boolean,
         default: false,
      },
   },
   data: () => ({
      show: false,
      description: null,
      subtitle: null,
   }),
   computed: {
      ...mapState('app', ['dtouchWorkspace']),
      showDetails () {
         return true //this.getTranslation(this.activity.Description, this.locale).length > 0 || this.activity.PlaceID || this.activity.MeetingPointID 
      },
   },
   watch: {
      'activity.ID' () {
         this.handleGetActivity()
      },
   },
   mounted () {
      this.handleGetActivity()
      this.show = this.autoExpand
   },
   methods: {
      handleGetActivity () {
         const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/cm/activities/${this.activity.ID}`
         axios.get(url)
            .then((response) => {
               if (!response || !response.data) return
               const aux = response.data.shift()
               this.subtitle = aux && aux.Subtitle ? JSON.parse(aux.Subtitle) : null
            })
            .catch(error => {
               console.log('jjh', error)
            })
      },
      getTranslation(v, locale) {
         return utils.getTranslation(v, locale)
      },
      getSubtitle (locale) {
         const aux = this.getTranslation(this.activity.Subtitle, locale)
         if (aux && aux.length > 0) return aux
         //else return this.getTranslation(this.category.Name, locale)
         return ''
      },
      convertTime (v) {
         return utils.convertTime(v)
      },
   },
}
</script>

