<template>
  <div
    class="result-html"
    :style="{ padding: isDesktop ? '15px 30px !important' : '12px 15px !important' }"
  >
    <v-flex
      v-html="text" 
      class="ql-editor ql-blank"
      xs12
    />
  </div>
</template>
<script>

import utils from '@/services/utils'
export default {
  name:'Item16Text',
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    text () {
      return this.content.Text ? utils.getTranslation (this.content.Text, this.locale) : ''
    },
  },
}
</script>
<style>
  .ql-editor p {
    padding: 0 !important;
  }
  .result-html .ql-toolbar.ql-snow {
    height: 0px !important;
    padding: 0px !important;
    border: 0px !important;
  }
  .result-html .ql-container.ql-snow {
    border: 0px !important;
  }
  .result-html .ql-editor {
    min-height: 0px !important;
  }   
</style>

