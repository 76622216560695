<template>
  <div
    style="padding: 5px 10px;"
    v-if="content"
  >
    <v-card>
      <v-container>
        <table class="product-result">
          <tr>
            <td class="name">
              <v-tooltip v-model="showLeaf" top>
                <template>
                  
                </template>
                <span>{{$t('product.promotion', locale)}}</span>
              </v-tooltip>
              <v-tooltip v-model="showPromotion" top>
                <template >
                  
                </template>
                <span>{{$t('product.promotion', locale)}}</span>
              </v-tooltip>
              <p
                :style="{ 'color': productTextColor,
                  'font-size': `${FontSizeContent}px`,
                  'font-family': FontContent, 
                }"
                style="margin-bottom: 5px;"
              >
                <v-icon v-if="content.ProductPromotion" style="color: #F2C96C;" @click="showPromotion=!showPromotion">mdi-decagram</v-icon>{{getTranslation(content.ProductName, locale)}}
              </p>
              <p
                :style="{ 'color': ColorContent,
                  'font-size': `${(FontSizeContent - 5)}px`,
                  'font-family': FontContent, 
                }"
              >
                {{getTranslation(content.ProductDescription, locale)}}
              </p>
            </td>
            <td class="price">
              <p
                :style="{ 'color': ColorContent,
                  'font-size': `${FontSizeContent}px`,
                  'font-family': FontContent, 
                }"
              >
                {{content.ProductPrice}}
              </p>                
              <p
                  v-if="getTranslation(content.ProductNote, locale).length > 0" 
                  style="line-height: 1;margin-top:-10px;"
                  :style="{ 'color': ColorContent,
                    'font-size': `${(FontSizeContent - 5)}px`,
                    'font-family': FontContent, 
                  }"
                >
                {{getTranslation(content.ProductNote, locale)}}
                </p>
 
              <v-layout wrap :class="`justify-end`">
                <v-flex
                  v-if="content.ProductImage"
                  v-bind="{ [`xs12`]: true }"
                  :style="{ 'width' : `75px`}"
                >
                  <image-modal :data="content" :locale="locale" :config="config" />
                </v-flex>
              </v-layout>
            </td>
          </tr>
        </table>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import utils from '@/services/utils'
import ImageModal from './Image'
export default {
name:'Item30ProductService',
  components: {
    ImageModal
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showLeaf: false,
    showPromotion: false
  }),
  computed: {
    //...mapState('app',['fontcontent', 'fontsizecontent', 'colorcontent']),
    FontContent () {
      return this.config.FontContent
    },
    FontSizeContent () {
      return this.config.FontSizeContent
    },
    ColorContent () {
      return this.config.ColorContent
    },
    productTextColor () {
      return this.config.FoodDrinkTextColor && this.config.FoodDrinkTextColor !== '' ? this.config.FoodDrinkTextColor : this.ColorContent
    },
  },
  methods: {
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
  }
}
</script>
<style scoped>
.product-result {
  width: 100%;
}
.product-result .name {
  width: auto;
}
.product-result .price {
  width: 1%;
  white-space: nowrap;
  text-align: right;
  vertical-align: top;
}
</style>
