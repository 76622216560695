<template>
   <div 
      v-if="place && getTranslation(place.Address, locale).length > 0"
      style="margin-top: 20px;"
   >
      <p style="margin-bottom: 0;">
         {{ label }}
      </p>
      <p style="font-size: 10px;">
         {{ getTranslation(place.Address, locale) }}
      </p>
   </div>
</template>
<script>
import { mapState } from 'vuex'
import utils from '@/services/utils'
import axios from 'axios'
export default {
   props: {
      placeID: {
         type: String,
         default: null,
      },
      label: {
         type: String,
         required: true,
      },
      locale: {
         type: String,
         required: true,
      },
   },
   data: () => ({
      loading: false,
      place: null,
   }),
   computed: {
      ...mapState('app', ['dtouchWorkspace']),
   },
   mounted () {
      this.handleGetPlace()
   },
   methods: {
      getTranslation (v, locale) {
         return utils.getTranslation(v, locale)
      },
      handleGetPlace () {
         if (!this.placeID) return
         const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/cm/${this.dtouchWorkspace}/places/${this.placeID}`
         axios.get(url)
            .then((response) => {
               const aux = response.data.shift()
               if (aux && aux.Address) this.place = aux
            })
            .catch((error) => {
               console.log(error)
            })
            .then(() => {
               this.loading = false
            })
      },
   },
}
</script>

