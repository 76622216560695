<template>
  <div>
    <v-btn
      x-small
      dark
      :color="secondaryColor"
      style="margin: 10px 10px 10px 0;float:left;"
      @click.stop="handleShowMoreInfo"
    >
      {{ $t('booking.moreInfo', locale).toUpperCase() }}
    </v-btn>
    <v-dialog
      content-class="more-info"
      v-model="showMoreInfo"
      style="max-width: 400px !important;"
    >
      <v-card style="border-radius:5px;">
        <v-card-text style="padding: 0px;">
          <v-layout wrap>
            <v-flex xs12 style="padding: 20px 20px 0 20px">
              <div 
                style="cursor: pointer;padding-right:20px;padding-top:10px;z-index: 300;
                        right:0;margin-top:-25px;margin-right:-15px;color:red;position:absolute"
                @click="showMoreInfo = false"
              >
              <v-btn fab x-small>
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              </div>
              <v-img
                 :src="event.image.replace('_dtouch/', '/')"
                lazy-src="https://www.dviewcontent.com/activity/acttiv/images/loaging.jpg"
                 width="100%"
                 aspect-ratio="1.7"
                 class="grey lighten-2"
              />
            </v-flex>
            <v-flex
              xs12
              class="title"
              :style="{ 'color': titleColor }"
            >
              {{ event.title.toUpperCase() }}
            </v-flex>
            <v-flex
              xs6
              style="text-align: left;padding: 10px 0 10px 20px;font-family: Roboto Condensed;font-size:15px !important;"
            >
              <v-icon
                :style="{ 'color': event.active ? 'rgb(85,173,11)' : timeColor}"                  
                dark
                class="activity-button"
                style="margin: -3px 0 0 0;margin-font-weight: 500;font-size:1rem"
              >
                mdi-clock-outline
              </v-icon>
              <span
                :style="{ 'color': event.active ? 'rgb(85,173,11)' : timeColor}"                  
                style="font-weight: 500;"
              >
                {{ event.rangeTime }}
              </span>
            </v-flex>
            <v-flex xs6
              style="padding: 10px 20px 0 0;text-align:right;"
            >
            <v-spacer />
              <reservation-form
                 v-if="!isTotem && !dtouchIsTouchPublic && event.AllowReservations && !event.outdated"
                 :color="primaryColor"
                 :event="event"
                 :locale="locale"
                 :right="true"
              />
              <div
                   v-else-if="event.ReservationRequired"
                   style="padding:0;"
              >
                <span>
                   {{ $t('booking.reservationRequired', locale).toUpperCase() }}
               </span>
              </div>
            </v-flex>
            <v-flex xs12
                v-if="event.description"
                class="description"
                :style="{ 'color': contentColor }"
                v-html="event.description"
             />
            <v-flex xs12
              v-if="!isTotem && !dtouchIsTouchPublic"
              style="padding:30px 20px 0 20px;"
            >
              <add-calendar-in-line 
                :event="event"
                :locale="locale"
                :primaryColor="titleColor"
              />
            </v-flex>
            <v-flex xs12
              v-if="event.Place"
              style="padding: 20px 20px 0 20px;"
            >
              <place-info 
                :item="event.Place"
                :label="$t('booking.place', locale)"
                :primaryColor="titleColor"
                :secondaryColor="contentColor"
              />
            </v-flex>
            <v-flex xs12
              v-if="event.MeetingPoint"
              style="padding: 10px 20px 0 20px;"
            >
              <place-info :item="event.MeetingPoint" 
                :label="$t('booking.meetingPoint', locale)"
                :primaryColor="titleColor"
                :secondaryColor="contentColor"
              />
            </v-flex>
            <v-flex xs12 style="padding-top: 30px;">
              <map-info :item="event.Place" />
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import utils from '@/services/utils'
import ReservationForm from './ReservationForm'
import AddCalendarInLine from './AddCalendarInLine'
import PlaceInfo from './PlaceInfo'
import MapInfo from './Map'
export default {
  name: 'MoreInfo',
  components: {
    ReservationForm,
    AddCalendarInLine,
    PlaceInfo,
    MapInfo,
  },
  props: {
    locale: {
      type: String,
      required: true
    },
    primaryColor: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
      required: true,
    },
    contentColor: {
      type: String,
      required: true,
    },
    timeColor: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showMoreInfo: false,
      //addToCalendarButtons: '',
    }
  },
   computed: {
      dtouchIsTouchPublic () {
         return this.$store.state.app.dtouchIsTouchPublic
      },
      isTotem () {
        //return this.$route.path === '/totem'
        return this.$route.path === '/totem' || utils.isTotem()
      },
   },
  methods: {
    handleShowMoreInfo () {
      this.showMoreInfo = true
    },
    handleShowCalendar () {
      /*
      const start = new Date(Date.parse(this.start, 'Y-m-d'))
      const end = new Date(Date.parse(this.end, 'Y-m-d'))

      const startTime = this.startTime.split(':')
      start.setHours(startTime[0], startTime[1], '00')
      const endTime = this.endTime.split(':')
      end.setHours(endTime[0], endTime[1], '00')
      const duration = parseInt((end - start) / (1000 * 60), 10)

      // eslint-disable-next-line
      const myCalendar = createCalendar({
        options: {
          class: 'my-class',
          id: 'my-id'
        },
        data: {
          title: this.title,
          start: start,
          duration: duration,
          end: end,
          address: '',
          description: this.description
        }
      })
      let aux = myCalendar.replace(new RegExp('######', 'g'), this.color)
      this.addToCalendarButtons = aux
      this.showAddToCalendar = true
        */
    }
  }
}
</script>
<style>
.more-info {
  max-width: 600px!important;
  width: auto;
}
.more-info .title {
  text-align: left;
  padding:20px 20px 5px 20px;
  line-height:1.2;
  font-size: 1.25rem;
  font-weight:300;
}
.more-info .description {
  padding: 10px 20px 0 20px;
  text-align: left;
  font-family: Roboto Condensed;
  line-height:1.2;
  font-size:16px;
  font-weight:300;
}
.btnAdd {
  border: 1px solid!important;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  color: red;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 2px;
}
.btnAdd:hover {
  border: 1px solid !important;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  color: red;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 2px;
  background-color: #d4d9dc;
}
</style>

