<template>
  <table
    class="add-calendar"
    cellpadding="0"
    cellspacing="0" 
    :style="{ borderColor: transparentPrimaryColor, color: primaryColor }"
  >
    <tr v-if="addCalendarHref">
      <td class="label">
        <span :style="{ color: labelColor }">{{ $t('booking.addCalendar', locale) }}</span>
      </td>
      <td
        class="button"
        @click="handleAddCalendar('yahoo')"
        :style="{ borderColor: transparentPrimaryColor }"
      >
        <v-icon :color="primaryColor">mdi-yahoo</v-icon>
      </td>
      <td
        class="button"
        @click="handleAddCalendar('outlook')"
        :style="{ borderColor: transparentPrimaryColor }"
      >
        <v-icon :color="primaryColor">mdi-microsoft-outlook</v-icon>
      </td>
      <td
        class="button"
        @click="handleAddCalendar('ical')"
        :style="{ borderColor: transparentPrimaryColor }"
      >
        <v-icon :color="primaryColor">mdi-apple</v-icon>
      </td>
      <td
        class="button"
        @click="handleAddCalendar('google')"
        :style="{ borderColor: transparentPrimaryColor }"
      >
        <v-icon :color="primaryColor">mdi-google</v-icon>
      </td>
    </tr>
  </table>
</template>
<script>
import calendar from '@/services/calendar'
import utils from '@/services/utils'
export default {
  props: {
    event: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    addCalendarHref: null,
    calendarType: null,
  }),
  computed: {
    labelColor () {
      return this.primaryColor ? this.primaryColor.substring(0,7).concat('CC') : ''
    },
    transparentPrimaryColor () {
      return this.primaryColor ? this.primaryColor.substring(0,7).concat('4D') : ''
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    prepareTime (v) {
      let aux = typeof v === 'string' ? v.split('T').pop() : v.toISOString().split('T').pop()
      if (!aux) return 
      aux = aux.substring(0,5).split(':')
      return aux
    },
    init () {
      const start = new Date(Date.parse(this.event.start, 'Y-m-d'))
      const end = new Date(Date.parse(this.event.end, 'Y-m-d'))

      const startTime = this.prepareTime(this.event.start)
      start.setHours(startTime[0], startTime[1], '00')
      const endTime = this.prepareTime(this.event.end)
      end.setHours(endTime[0], endTime[1], '00')
      const duration = this.event.Duration//parseInt((end - start) / (1000 * 60), 10)

      const calendarEvent = {
        title: utils.getTranslation(this.event.ActivityName, this.locale),
        start: start,
        duration: duration,
        end: end,
        address: '',
        description: this.event.ActivityDescription ? utils.getTranslation(this.event.ActivityDescription, this.locale) : '',
      }
      this.addCalendarHref = calendar.generateCalendars(calendarEvent)
    },
    handleAddCalendar (v) {
      if (this.addCalendarHref[v]) window.location.href = this.addCalendarHref[v]
    },
  },
}
</script>
<style scoped>
.add-calendar {
  width:100%;
  border-radius: 8px;
  border: 1px solid #CCCCCC;
  font-family: Roboto Condensed;
}
.add-calendar .label {
  border-left: 0;
  text-align: center;
  cursor: inherit !important;
}
.add-calendar td {
  text-align: center;
  color: red;
  border-left: 1px solid #CCCCCC;
  padding: 10px;
  cursor: pointer !important;
}
.add-calendar .button:hover {
  background-color: #CCCCCC0D;
}
</style>

