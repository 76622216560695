<template>
 <v-layout
   v-if="content && config"
    justify-center
    class="activity"
    :style="{ 'width': isSimulation ? '375px' : `${sizeW}px !important`,
      'background-color' : BGColorContent
    }"
    wrap
  >
    <v-flex xs8 style="margin-top: 20px;">
      <center>
      <v-btn-toggle v-model="typeActivity" mandatory
        :style="{ 'background-color': `${secundaryColor} !important`, 'color': isLight(secundaryColor) ? 'black !important' : 'white !important' }"
      >
        <v-btn
            :style="{ 'color': secundaryColor, 'background-color': `${secundaryColor}`, 'color': isLight(secundaryColor) ? 'black' : 'white' }"
          >
          <span :style="{ 'color': isLight(secundaryColor) ? 'black' : 'white' }">{{$t('common.all', locale)}}</span>a
        </v-btn>
        <v-btn
            :style="{ 'color': secundaryColor, 'background-color': `${secundaryColor}`, 'color': isLight(secundaryColor) ? 'black' : 'white' }"
          >
          <v-icon 
            :style="{ 'color': isLight(secundaryColor) ? 'black' : 'white' }"
            small
          >mdi-human-child</v-icon>
        </v-btn>
        <v-btn
            :style="{ 'color': secundaryColor, 'background-color': `${secundaryColor}`, 'color': isLight(secundaryColor) ? 'black' : 'white' }"
          >
          <v-icon 
            :style="{ 'color': isLight(secundaryColor) ? 'black' : 'white' }"
          >mdi-human-male</v-icon>
        </v-btn>
      </v-btn-toggle>
      </center>
    </v-flex>
    <v-flex xs4 style="margin-top: 20px;">
      <center>
      <reservation-list :updateKey="updateKey"/>
      </center>
    </v-flex>
    <v-flex
      xs12
      style="padding-bottom: 0px;padding-top: 0px;padding-left: 20px;padding-right: 20px;"
    >
      <div
        style="border-radius:5px;position: static; top:0px;margin-bottom:20px;margin-top:20px;"
        :style="{ 'background-color': `${primaryColor}` }"
      >
        <v-btn-toggle v-model="daysToAdd" style="width: 100%;"
          :style="{ 'background-color': `${secundaryColor}` }"
        >
          <v-btn
            v-for="(day, index) in week"
            :key="`week${index}`"
            style="width:14.3%;min-width:14.2%;"
            :style="{ 'color': secundaryColor, 'background-color': `${secundaryColor}`, 'color': isLight(secundaryColor) ? 'black' : 'white' }"
            @click="() => handleAddDays(index)"   
          >
           <span :style="{ 'color': isLight(secundaryColor) ? 'black' : 'white' }">{{ $t(`common.${day}`, locale).substring(0, 2) }}</span>
          </v-btn>          
        </v-btn-toggle>
        <span
          :style="{ 'color': isLight(primaryColor) ? 'black' : 'white'}"
        >
          <center style="margin-top:10px;padding-bottom:10px;">
            {{ $t(sDay.pre, locale).toUpperCase() }} {{ $t(sDay.month, locale).toUpperCase() }} {{ sDay.pos.toUpperCase() }}
          </center>
        </span>
      </div>
      <v-container
        fluid
        grid-list-md
        style="padding: 5px"
      >
        <v-layout
          row
          wrap
          style="margin-bottom: 20px;"
        >
          <v-flex
            v-if="loading"
          >
            <center>
              <v-progress-circular
                :size="70"
                :width="7"
                :color="ColorContent"
                indeterminate
              />
            </center>
          </v-flex>
          <v-flex
            v-if="!loading && (!activities || activities.length === 0)"
            xs12
          >
            <center :style="{ 'color': ColorContent }">{{ $t('activity.messages.noActivities', locale).toUpperCase() }}</center>
          </v-flex>
          <activity-item        
            v-for="(activity, index) in activities"
            :key="activity.ID"
            :activity="activity"
            :index="index"
            :data="data"
            :token="token"
            :hotelID="hotelID"
            :isBook="isBook"
            :onRefreshList="onRefreshList"
          />
          <v-flex
            xs12
          >
            <center :style="{ 'color': ColorContent }">{{ $t('dtouch.acttiv.disclaimer', locale)}}</center>
          </v-flex>
          <v-flex
            xs12
            style="margin-top: 10px;"
          >
            <center v-if="isBook">
                <reservation-list />
            </center>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import uuidv1 from 'uuid/v1'
import tinycolor from 'tinycolor2'
import utils from '@/services/utils'
//import api from '@/services/api'
//import constants from '@/constants'
//import AddCalendar from './AddCalendar'
import ActivityItem from './ActivityItem'
import ReservationList from './ReservationList'
export default {
  components: {
    //AddCalendar,
    ActivityItem,
    ReservationList
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: null,
    baseURL: 'https://infarmaticaplus.es/api-pias/v1',
    // baseURL: 'https://intranet.acttiv.es/api-pias/v1',
    loading: true,
    primaryColor: null,
    secundaryColor: null,
    titleColor: null,

    hotelID: null,
    isBook: false,
    categories: null,
    hotels: null,
    pia: null,
    token: null,
    hotelName: '',

    DviewLink: null,
    activities: null,
    allActivities: null,
    daysToAdd: 0,
    week: [],
    typeActivity: 0,
    updateKey: null,
    acttivImage: null,
  }),
  computed: {
    ...mapState('app',['EnterpriseColor']),
    FontContent () {
      return this.config.FontContent
    },
    FontSizeContent () {
      return this.config.FontSizeContent
    },
    BGColorContent () {
      return this.config. BGColorContent
    },
    BGColor () {
      return this.config.BGColor
    },
    Color () {
      return this.config.Color
    },
    BGColorSecundary () {
      return this.config.ColorSecundary
    },
    ColorSecundary () {
      return this.config.ColorSecundary
    },
    ColorContent () {
      return this.config.ColorContent
    },
    sDate () {
      return `${this.$t(this.sDay.pre, this.locale).toUpperCase()} ${this.$t(this.sDay.month, this.locale).toUpperCase()} ${this.sDay.pos.toUpperCase()}`
    },
    isSimulation () {
      return true
      //return constants.simulationsRutes.indexOf(this.$route.path.split('/')[1]) >= 0
    },
    sizeW () {
      return window.innerWidth - (!utils.isMobile() ? 360 : 0)
    },
    enterpriseID () {
      return utils.getWorkspace('dtouch')
      //return this.$store.state.app.enterpriseID
    },
    sDay () {
      let dToday = new Date()
      const dActual = new Date().setDate(dToday.getDate() + this.daysToAdd)
      const pos = `${new Date(dActual).getDate()}, ${new Date(dActual).getFullYear()}`

      const result = {
        pre: this.daysToAdd === 0 ? 'common.today' : this.daysToAdd === 1 ? 'common.tomorrow' : '',
        month: `common.m${new Date(dActual).getMonth()}`,
        pos
      }
      return result
    }
  },
  watch: {
    content (v) {
      this.data = v
    },
    'data.ActtivPrimaryColor' () {
      this.checkActtivPrimaryColor()
    },
    'data.ActtivSecundaryColor' () {
      this.checkActtivSecundaryColor()
    },
    'data.ActtivColor' () {
      this.checkActtivTitleColor()
    },
    'typeActivity' () {
      this.getPias(this.daysToAdd)
    }
  },
  mounted () {
    this.data = this.content
    this.checkActtivPrimaryColor()
    this.checkActtivSecundaryColor()
    this.checkActtivTitleColor()
    const dSemenaActual = new Date().getUTCDay() - 1
    const aux = []
    for (let i = 0; i < 7; i++) {
      const x = ((dSemenaActual + i) >= 7) ? (dSemenaActual + i - 7) : dSemenaActual + i
      aux.push(`s${x}`)
    }
    this.week = aux
    this.getData()
  },
  methods: {
    fitlerPias () {
      switch (this.typeActivity) {
        case 0: 
          this.activities = this.allActivities
          break
        case 1:
          this.activities = this.allActivities.filter( x => x.KidsOnly || x.AdultsAndKids)
          break
        case 2:
          this.activities = this.allActivities.filter( x => x.AdultsOnly || x.AdultsAndKids)
      }
    },
    getActtivConfig () {
      const url = `https://www.dtouch.es/public/enterprises/acttiv/${this.enterpriseID ? this.enterpriseID : utils.getDomain()}`
      axios.get(url)
        .then(response => {
          if (!response || !response.data) return
          response = response.data.shift()
          this.hotelID = response.HotelID
          this.isBook = response.IsBook ? true : false
          this.acttivImage = response.ActtivImage && response.ActtivImage ? response.ActtivImage : null
          this.getPias()
        })
        .catch((error) => {
          console.log(error)
        })
/*
      api.getItem('enterprises/acttiv', false, `${this.enterpriseID ? this.enterpriseID : utils.getDomain()}`).then(response => {
        this.hotelID = response.HotelID
        this.isBook = response.IsBook ? true : false
        this.acttivImage = response.ActtivImage && response.ActtivImage ? response.ActtivImage : null
        this.getPias()
      })
      */
    },
    isLight (v) {
      return tinycolor(v).isLight()
    },
    checkActtivPrimaryColor (){
      this.primaryColor = this.data.ActtivPrimaryColor ? this.data.ActtivPrimaryColor : this.BGColor
    },
    checkActtivSecundaryColor (){
      this.secundaryColor = this.data.ActtivSecundaryColor ? this.data.ActtivSecundaryColor : this.BGColorSecundary
    },
    checkActtivTitleColor (){
      this.titleColor = this.data.ActtivColor ? this.data.ActtivColor : 
                        this.data.ActtivPrimaryColor ? this.data.ActtivPrimaryColor : this.BGColor
    },
    formatDate(date, short) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()

      if (month.length < 2) 
          month = '0' + month
      if (day.length < 2) 
          day = '0' + day
  
      if (short) return [day, month].join('/')
      else return [year, month, day].join('-')
    },
    handleAddDays (value) {
      this.daysToAdd = value
      this.getPias(this.daysToAdd)
    },
    getSelectedDate () {
      let aux = new Date()
      aux = new Date (aux.setDate(aux.getDate() + this.daysToAdd))
                
      return this.formatDate(aux)
    },
    firstDay () {
      let aux = new Date()
      aux = new Date (aux.setDate(aux.getDate() + this.daysToAdd))
      var day = aux.getDay(),
          diff = aux.getDate() - day + (day == 0 ? -6:1) // adjust when day is sunday
                
      return this.formatDate(new Date(aux.setDate(diff)))
    },
    getData () {
      //let www = process.env.VUE_APP_ENV === 'development' ? '' : 'www.'
      //const url = `${window.location.protocol}//${www}${process.env.VUE_APP_API_URL}/public/acttiv/token`
      const url = `https://www.dtouch.es/public/acttiv/token`
      axios.get(url)
        .then(response => {
          this.token = response.data.token
          //this.$session.set('acttivToken', this.token)
          this.getActtivConfig()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getHotels () {
      const url = 'https://intranet.acttiv.es/api-pias/v1/hotels'
      fetch(url, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8', // Indicates the content
          'Authorization': `Beaver ${this.token}`
        },
      })
      .then(response => response.json())
      .then(data => {
        this.hotels = data
      })
    },
    getCategories () {
      const url = 'https://intranet.acttiv.es/api-pias/v1/categories'
      fetch(url, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8', // Indicates the content
          'Authorization': `Beaver ${this.token}`
        },
      })
      .then(response => response.json())
      .then(data => {
        this.categories = data
      })
    },
    getIsActive(st, et) {
      const result = st <= new Date() && et >= new Date()
      return result
    },
    getIsOld(value) {
      return value < new Date() ? true : false
    },
    replaceAll(string, search, replace) {
      return string ? string.split(search).join(replace) : ''
    },
    prepareImage(v) {
      let url = `https://www.dviewcontent.com/activity/acttiv/images/${this.acttivImage ? this.acttivImage : 'default'}_dtouch/${this.formatImageName(v.nombre_tarea)}.jpg`
      if (!utils.urlExists(url)) url = `https://www.dviewcontent.com/activity/acttiv/images/${this.acttivImage ? this.acttivImage : 'default'}_dtouch/${v.codigo_categoria_actividad}.jpeg`
      const isPos20 = (new Date(v.hora_inicio).toTimeString().slice(0, 2) | 0) >= 20
      if (!utils.urlExists(url) && isPos20) url = `https://www.dviewcontent.com/activity/acttiv/images/${this.acttivImage ? this.acttivImage : 'default'}_dtouch/404.jpg`
      return url
    },
    formatImageName (value) {
      let aux = value
      aux = this.replaceAll(aux, ' ', '')
      aux = this.replaceAll(aux, '-', '')
      aux = this.replaceAll(aux, '_', '')
      aux = this.replaceAll(aux, 'á', 'a')
      aux = this.replaceAll(aux, 'é', 'e')
      aux = this.replaceAll(aux, 'í', 'i')
      aux = this.replaceAll(aux, 'ó', 'o')
      aux = this.replaceAll(aux, 'ú', 'u')
      aux = this.replaceAll(aux, 'ñ', 'n')
      return aux.toLowerCase()
    },
    convertPias (data) {
      this.activities = null
      this.allActivities = null
      this.pia = data
      this.hotelName = this.pia.nombre_hotel
      const result = []
      for (const program of this.pia.programa_hotel) {          
        //const programs = []
        for (const plan of program.dia_planif) {
          /*
          const result_plan = {
            Events: []
          }
          */
          for (const tar of plan.tarea_pia) {
            const title = tar.nombre_tarea
            const name = {
              es: tar.nombre_tarea_es ? tar.nombre_tarea_es : tar.nombre_tarea,
              en: tar.nombre_tarea_en,
              de: tar.nombre_tarea_de,
              fr: tar.nombre_tarea_fr,
            }
            const startTime = new Date(tar.hora_inicio.replace(/-/g, '/'))
            const endTime = new Date(tar.hora_fin.replace(/-/g, '/'))
            
            if (plan.fecha === this.formatDate(this.getSelectedDate())) {
              result.push({
                TareaPiaID: tar.id_tarea_pia,
                ProgramID: program.id_programa_hotel,
                ProgramName: program.nombre_programa,
                Date: plan.fecha,
                Name: name,
                Title: title,
                Start: startTime,
                End: endTime,
                TimeLimit: tar.plazo_reserva_minutos && tar.plazo_reserva_minutos !== -1 ? new Date(startTime.getTime() + (-1 * tar.plazo_reserva_minutos) * 60000) : null,
                RangeTime: `${startTime.toTimeString().slice(0,5)} - ${endTime.toTimeString().slice(0,5)}`,
                ImageURL: this.prepareImage(tar), // `https://dviewcontent.com/activity/acttiv/images/${'default'}_dtouch/${this.formatImageName(title)}.jpg`,
                CategoryID: tar.codigo_categoria_actividad,
                Category: tar.nombre_categoria_actividad,
                IsActive: this.getIsActive(startTime, endTime),
                IsOLD: this.getIsOld(endTime),
                Availability: tar.disponibilidad_actual,
                MaxCapacity: tar.aforo_max,
                RequiresReservation: tar.requiere_reserva,
                RequiresAcceptPolicy: tar.aceptar_politica,
                AdultsOnly: tar.para_adultos && !tar.para_ninos,
                KidsOnly: tar.para_ninos && !tar.para_adultos,
                AdultsAndKids: tar.para_ninos && tar.para_adultos
              })
            }
          }
        }
      }
      this.allActivities = result.slice().sort((a, b) => {
        return a.Start - b.End
      })
      this.fitlerPias()
      this.loading = false  
    },
//    getPias (daysToAdd = 0) {
    getPias () {
      this.activities = null
      this.allActivities = null
      this.loading = true
 //     const www = process.env.VUE_APP_ENV === 'development' ? '' : 'www.'
      //const url = `${window.location.protocol}//${www}${process.env.VUE_APP_API_URL}/public/acttiv/pias?token=${this.token}&hotelID=${this.hotelID}&date=${this.firstDay()}`
      const url = `https://www.dtouch.es/public/acttiv/pias?token=${this.token}&hotelID=${this.hotelID}&date=${this.firstDay()}`
      const that = this
      axios.get(url)
        .then(response => {
          if (!response.data.error) this.convertPias(response.data)
          else {
            that.loading = false
          }
        })
        .catch(() => {
          that.loading = false
        })
    },
    onRefreshList () {
      this.updateKey = uuidv1()
    }
  }
}
</script>
<style scoped>
  .activity .container {
    padding: 0px;
  }
  .layout.activity.justify-center {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .activity .v-toolbar__content {
    padding: 0px !important;
    margin: auto !important;
  }
  .activity-button {
    margin-left: 10px;
  }
  .activity-button:hover {
    opacity: 0.7;
  }
  .td:hover {
    cursor: pointer;
  }
</style>

