<template>
  <div style="padding: 5px 10px;">
    <v-card :style="{ 'background-color': `${foodDrinkBGColor} !important`}" >
      <v-layout wrap>
        <v-flex xs12
        >
        <v-container style="padding: 5px !important">
          <table class="food-result" >
            <tr>
              <td v-if="showDishesImage && data.Image" style="vertical-align:top;max-width:100px;width:30%;">
                <image-in-line :data="data" :locale="locale" :config="config" />  
              </td>
              <td class="name" 
                :style="{ 'padding-left' : showDishesImage ? '10px' : '0',
                }">
                <v-tooltip v-model="showLeaf" top>
                  <template>
                    
                  </template>
                  <span>{{$t('food.vegan', locale)}}</span>
                </v-tooltip>
                <v-tooltip v-model="showVegetarian" top>
                  <template >
                    
                  </template>
                  <span>{{$t('food.vegetarian', locale)}}</span>
                </v-tooltip>
                <v-tooltip v-model="showNew" top>
                  <template >
                    
                  </template>
                  <span>{{$t('food.new', locale)}}</span>
                </v-tooltip>
                <v-tooltip v-model="showRecommended" top>
                  <template >
                    
                  </template>
                  <span>{{$t('food.recommended', locale)}}</span>
                </v-tooltip>
                <p
                  :style="{ 'color': foodDrinkTextColor,
                    'font-size': `${FontSizeContent}px`,
                    'font-family': FontContent, 
                  }"
                  style="margin-bottom: 5px;line-height: 1.2;padding: 5px 0 0 0;"
                >
                  <v-icon v-if="data.FoodVegan && !iconRight" style="color: #a9c366;" @click="showLeaf=!showLeaf">mdi-leaf</v-icon>
                  <v-icon v-if="data.FoodVegetarian && !iconRight" style="color: #436f45;" @click="showVegetarian=!showVegetarian">mdi-leaf</v-icon>
                  <v-icon v-if="data.FoodNew && !iconRight" style="color: red;" @click="showNew=!showNew">mdi-new-box</v-icon>
                  <v-icon v-if="data.FoodRecommended && !iconRight" style="color: orange;" @click="showRecommended=!showRecommended">mdi-star-box</v-icon>
                  {{ getTranslation(data.Name, locale) }}
                  <v-icon v-if="data.FoodVegan && iconRight" style="color: #a9c366;" @click="showLeaf=!showLeaf">mdi-leaf</v-icon>
                  <v-icon v-if="data.FoodVegetarian && iconRight" style="color: #436f45;" @click="showVegetarian=!showVegetarian">mdi-leaf</v-icon>
                  <v-icon v-if="data.FoodNew && iconRight" style="color: red;" @click="showNew=!showNew">mdi-new-box</v-icon>
                  <v-icon v-if="data.FoodRecommended && iconRight" style="color: orange;" @click="showRecommended=!showRecommended">mdi-star-box</v-icon>
                </p>
                <p
                  :style="{ 'color': ColorContent,
                    'font-size': `${(FontSizeContent - 5)}px`,
                    'font-family': FontContent, 
                  }"
                  style="line-height: 1.2;margin-bottom: 5px;padding-top: 5px;"
                >
                  {{ getTranslation(data.Ingredients, locale) }}
                </p>
                <allergens-in-line  v-if="showDishesAllergens && data && data.Allergens" 
                  :config="config"
                  :dtouchWorkspace="dtouchWorkspace"
                  :allergens="data.Allergens" :columnType="!showDishesImage || !data.Image ? 'xs2' : 'xs3'"
                />
              </td>
              <td class="price">
                <p
                  style="line-height: 1;padding: 5px 0 0 0;"
                  :style="{ 'color': ColorContent,
                    'font-size': `${FontSizeContent}px`,
                    'font-family': FontContent, 
                  }"
                >
                  {{data.Price}}
                </p>
                <p
                  v-if="data && data.Note" 
                  style="line-height: 1;margin-top:-10px;"
                  :style="{ 'color': ColorContent,
                    'font-size': `${(FontSizeContent - 5)}px`,
                    'font-family': FontContent, 
                  }"
                >
                  {{ getTranslation(data.Note, locale) }}
                </p>
                <v-layout wrap :class="`justify-end`">
                  <v-flex
                    v-if="data.Allergens && data.Allergens.length && !showDishesAllergens"
                    v-bind="{ [`xs${data.Image && data.Allergens && data.Allergens.length ? 6 : 12}`]: true }"                  
                    :style="{ 'width' : `${data.Image && data.Allergens && data.Allergens.length ? 75 : 30}px`}"
                  >
                    <allergens :data="data" :locale="locale" 
                      :config="config"
                      :dtouchWorkspace="dtouchWorkspace"
                    />
                  </v-flex>
                  <v-flex
                    v-if="data.Image && !showDishesImage"
                    v-bind="{ [`xs${data.Image && data.Allergens && data.Allergens.length ? 6 : 12}`]: true }"
                    :style="{ 'width' : `${data.Image && data.Allergens && data.Allergens.length ? 75 : 30}px`}"
                  >
                    <image-modal :data="data" :locale="locale" 
                      :config="config"
                    />
                  </v-flex>
                </v-layout>
              </td>
            </tr>
          </table>
        </v-container>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
  import constants from '@/constants'
  import utils from '@/services/utils'
  import Allergens from './Allergens'
  import ImageModal from './Image'
  import ImageInLine from './ImageInLine'
  import AllergensInLine from './AllergensInLine'
  export default {
    components: {
      Allergens,
      ImageModal,
      ImageInLine,
      AllergensInLine
    },
    props: {
      data: {
        type: Object,
        required: true,
      },
      config: {
        type: Object,
        required: true
      },
      locale: {
        type: String,
        required: true,
      },
      dtouchWorkspace: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      showLeaf: false,
      showVegetarian: false,
      showNew: false,
      showRecommended: false
    }),
    computed: {
      FontContent () {
        return this.config.FontContent
      },
      FontSizeContent () {
        return this.config.FontSizeContent
      },
      ColorContent () {
        return this.config.ColorContent
      },
      isDev () {
        return process.env.VUE_APP_ENV === 'development'
      },
      iconRight () {
        return this.config.FoodIconRight ? true : false
      },
      isSimulation () {
        return constants.simulationsRutes.indexOf(this.$route.path.split('/')[1]) >= 0
      },
      isDesktop () {
        return !utils.isMobile()
      },
      foodDrinkTextColor () {
        return this.config.FoodDrinkTextColor && this.config.FoodDrinkTextColor !== '' ? this.config.FoodDrinkTextColor : this.config.ColorContent
      },
      foodDrinkBGColor () {
        return this.config.FoodDrinkBGColor && this.config.FoodDrinkBGColor !== '' ? this.config.FoodDrinkBGColor : 'white'//'#000000'
      },
      showDishesImage () {
        return this.config.ShowDishesImage ? true : false
      },
      showDishesAllergens () {
        return this.config.ShowDishesAllergens ? true : false
      },
    },
    methods: {
      getTranslation(v, locale) {
        return utils.getTranslation(v, locale)
      },
    },
  }
</script>
<style scoped>
  .food-result {
    table-layout: fixed;
    width: 100% !important;
    max-width: 100% !important;
  }
  .food-result .name {
    width: auto;
  }
  .food-result .price {
    width: 100px;
    /*white-space: nowrap;*/
    text-align: right;
    vertical-align: top;
  }
</style>
