<template>
  <v-layout
    justify-center
    class="activity"
    style="background-color: black;"
  >
    <parrilla 
      v-if="items && items.length > 0"
      :data="items"
      :onFinished="handleFinished"
      :loop="true"
      :isSimulation="isSimulation"
    />
    <v-img
      v-if="ShowMoscaDFeel"
      style="position:absolute;opacity:0.8"
      :width="isDesktop && !isSimulation ? '1200px' : '600px'"
      src="https://www.dtouch.es/img/MOSCA%20DFEEL%20CHANNEL.png"
    />
  </v-layout>
</template>
<script>
import utils from '@/services/utils'
import api from '@/services/api'
import Parrilla from './Parrilla'
export default {
  name:'Item22Parrilla',
  components: {
    Parrilla,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    items: null,
    ShowMoscaDFeel: false,
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
    isSimulation () {
      return true
    },
  },
  mounted () {
    this.handleGetItems()
  },
  methods: {
    handleGetItems () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/parrilla-dviewcontent`)
        .then(response => {
          this.items = response.data ? response.data.Items : []
          this.ShowMoscaDFeel = response.data ? response.data.ShowMoscaDFeel : false
        })
    },
    handleFinished () {
      console.log('finish')
    },
  },
}
</script>

