<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card style="padding: 20px">
        <booking-reservation-form
          :editedID="editedID"
          :event="event"
          :token="token"
          :onClose="handleCloseForm"
          :onUpdate="onUpdate"
          :onDelete="onDelete"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import utils from '@/services/utils'
import BookingReservationForm from '@/components/common-components/bookingReservationForm/Index'
export default {
  components: {
    BookingReservationForm,
  },
  props: {
    editedBooking: {
      type: Object,
      default: null,
    },
    token: {
      type: String,
      default: null,
    },
    onUpdate: {
      type: Function,
      default: null,
    },
    onDelete: {
      type: Function,
      default: null,
    },
    onClose: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    event: null,
    editedID: null,
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    editedBooking () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      if (this.editedBooking) {
        this.editedID = this.editedBooking.ID
        this.event = {
            ID: this.editedBooking.BookedEventID,
            IsActtiv: this.editedBooking.Source === 'acttiv',
            Type: this.editedBooking.Type,
            WorkspaceID: this.workspaceID,
            ScheduleID: this.editedBooking.ScheduleID, 
            ActivityID: this.editedBooking.ActivityID, 
            ActivityName: this.editedBooking.ActivityName, 
            DateTime: this.editedBooking.DateTime, 
            Date: this.editedBooking.Date, 
            Time: this.editedBooking.Time, 
            Duration: this.editedBooking.Duration, 
            Free: this.editedBooking.Free, 
            Book: this.editedBooking.Book, 
            Cancel: this.editedBooking.Cancel,
          }
        this.dialog = true
      } else this.dialog = false
    },
    handleCloseForm () {
      this.onClose()
    },
  },
}
</script>

