var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{staticStyle:{"padding":"5px 10px"}},[_c('v-card',[_c('v-container',[_c('table',{staticClass:"product-result"},[_c('tr',[_c('td',{staticClass:"name"},[_c('v-tooltip',{attrs:{"top":""},model:{value:(_vm.showLeaf),callback:function ($$v) {_vm.showLeaf=$$v},expression:"showLeaf"}},[void 0,_c('span',[_vm._v(_vm._s(_vm.$t('product.promotion', _vm.locale)))])],2),_c('v-tooltip',{attrs:{"top":""},model:{value:(_vm.showPromotion),callback:function ($$v) {_vm.showPromotion=$$v},expression:"showPromotion"}},[void 0,_c('span',[_vm._v(_vm._s(_vm.$t('product.promotion', _vm.locale)))])],2),_c('p',{staticStyle:{"margin-bottom":"5px"},style:({ 'color': _vm.productTextColor,
                'font-size': (_vm.FontSizeContent + "px"),
                'font-family': _vm.FontContent, 
              })},[(_vm.content.ProductPromotion)?_c('v-icon',{staticStyle:{"color":"#F2C96C"},on:{"click":function($event){_vm.showPromotion=!_vm.showPromotion}}},[_vm._v("mdi-decagram")]):_vm._e(),_vm._v(_vm._s(_vm.getTranslation(_vm.content.ProductName, _vm.locale))+" ")],1),_c('p',{style:({ 'color': _vm.ColorContent,
                'font-size': (((_vm.FontSizeContent - 5)) + "px"),
                'font-family': _vm.FontContent, 
              })},[_vm._v(" "+_vm._s(_vm.getTranslation(_vm.content.ProductDescription, _vm.locale))+" ")])],1),_c('td',{staticClass:"price"},[_c('p',{style:({ 'color': _vm.ColorContent,
                'font-size': (_vm.FontSizeContent + "px"),
                'font-family': _vm.FontContent, 
              })},[_vm._v(" "+_vm._s(_vm.content.ProductPrice)+" ")]),(_vm.getTranslation(_vm.content.ProductNote, _vm.locale).length > 0)?_c('p',{staticStyle:{"line-height":"1","margin-top":"-10px"},style:({ 'color': _vm.ColorContent,
                  'font-size': (((_vm.FontSizeContent - 5)) + "px"),
                  'font-family': _vm.FontContent, 
                })},[_vm._v(" "+_vm._s(_vm.getTranslation(_vm.content.ProductNote, _vm.locale))+" ")]):_vm._e(),_c('v-layout',{class:"justify-end",attrs:{"wrap":""}},[(_vm.content.ProductImage)?_c('v-flex',_vm._b({style:({ 'width' : "75px"})},'v-flex',( _obj = {}, _obj["xs12"] = true, _obj ),false),[_c('image-modal',{attrs:{"data":_vm.content,"locale":_vm.locale,"config":_vm.config}})],1):_vm._e()],1)],1)])])])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }