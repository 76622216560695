<template>
  <v-layout
    justify-center
    class="activity"
    :style="{ color: `${config.ColorContent} !important`}"
    wrap
  >
    <v-flex xs12 style="margin-top:10px;">
      <v-menu offset-y :value="showFilter">
        <!--template v-slot:activator="{ on }"-->
        <template>
          <v-text-field
            v-model="searchText"
            :label="$t('common.search', locale)"
            append-icon="mdi-magnify"
            @input="handleSearch"
            @click:append-outer="onSearch"
            @click:prepend="handleShowFilter"
            solo
            style="margin: 10px 10px 20px 10px"
            prepend-icon="mdi-filter"
            hide-details
          />
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in types"
            :key="index"
            @click="handleChangeType(item.ID)"
          >
            <v-list-item-title>{{ item.Name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-flex>
    <template v-if="channelListFilter">
    <v-flex xs12
      v-for="(item) in channelListFilter"
      :key="item.ID"
      style="margin: 0 10px;"
      class="main-channel"
    >
      <v-layout
        wrap
      >
        <v-flex xs2 class="channel">
          <center style="margin: 20px 0 0 0;">{{item.Number}}</center>
        </v-flex>
        <v-flex xs3 class="image">
          <v-img :key="item.Name" :src="item.Image" :lazy-src="item.Image" style="height:80%;margin-top:10%;width:auto" />
        </v-flex>
        <v-flex xs7 class="detail">
          <p class="title">{{item.Name}}</p>
          <p class="subtitle">{{item.NativeName}}</p>
        </v-flex>
      </v-layout>
    </v-flex>
    </template>
  </v-layout>
</template>
<script>
import _ from 'lodash'
import api from '@/services/api'
import utils from '@/services/utils'
import isoLangs from '@/constants/ISOLangs'
export default {
  name:'Item33ChannelList',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    handleSearch: null,
    searchText: '',
    channelList: null,
    channelListFilter: null,
    DviewLink: null,
    shows: null,
    daysToAdd: 0,
    week: [],
    typeActivity: 0,
    types: [],
    selectedType: null,
    showFilter: false
  }),
  computed: {
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    'content.ChannelListType' () {
      this.getData()
    },
  },
  mounted () {
    this.channelListType = this.content.ChannelListType ? this.content.ChannelListType : null
    this.getData()
    this.handleSearch = _.debounce(() => {
      this.onSearch()        
    }, 500)
  },
  methods: {
    handleShowFilter () {
      if (this.showFilter) {
        this.showFilter = false
        setTimeout(() => {
          this.showFilter = true
        }, 500)
      } else this.showFilter = true
    },
    handleChangeType (v) {
      this.selectedType = v
      this.showFilter = false
      this.onSearch()
    },
    getData () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/channel-list-types`)
        .then(response => {            
          this.types = [
            {
              ID: null,
              Name: this.$t('common.all', this.locale)
            }
          ]
          this.types.push(...response.map (item => {
            item.Name = isoLangs[item.ID] ? isoLangs[item.ID].nativeName.split(',')[0] : ''
            return item
          }))
        })
      //api.getAll('contents', false, 1, 100000, '', this.workspaceID.concat('/channel-list/'))
      api.getAllWithoutLimit('dtouch', `v1/private/workspaces/${this.workspaceID}/channel-lists`)
        .then(response => {          
          this.channelList = []
          this.channelList = this.channelListType && this.channelListType != 0 ? response.filter(x => x.Country === this.channelListType) : response          
          this.channelList = this.channelList.map(item => {        
            item.NativeName = isoLangs[item.Country] ? isoLangs[item.Country].nativeName.split(',')[0] : ''
            return item
          })
          this.onSearch()
        })
    },
    onSearch () {
      this.channelListFilter = []
      this.channelListFilter = this.channelList.filter(x => 
        (x.Number.toUpperCase() === this.searchText.toUpperCase() || x.Name.toUpperCase().indexOf(this.searchText.toUpperCase()) >= 0) 
        && (!this.selectedType || x.Country.toUpperCase() === this.selectedType.toUpperCase())
      )
    },
  },
}
</script>
<style scoped>
.main-channel {
  border-bottom: 1px solid #0000004D;
}
.channel {
  font-size: 25px;
  font-weight: 700;
}
.detail {
  padding: 0 5px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
.title {
  font-weight: 400;
  font-size: 24px;
  margin: 15px 0 0 0;
  line-height: 1;    
}
.subtitle {
  font-weight: 200;
  font-size: 15px;
  margin: 0px;
  text-transform: capitalize;
}
.image {
  padding: 5px;
}
</style>

