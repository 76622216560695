<template>
  <div class="simulation">
    <dish-orders-cart
      :config="config"
      :locale="dtouchSimulationLocale"
    />
    <edited-menu
      v-for="(item, index) of menus"
      :key="item.ID"
      :id="item.ID"
      :config="config"
      :locale="dtouchSimulationLocale"
      :refreshSortContent="refreshSortContent"
      :autoExpand="isDev && index === 0 ? true : false"
      :onEditItem="onEditItem"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import EditedMenu from './editedMenu/Index'
import DishOrdersCart from '@/views/private/dtouch/dishOrders/dishOrdersCart/Index'
export default {
  components: {
    EditedMenu,
    DishOrdersCart,
  },
  props: {
    config: {
      type: Object,
      default: null,
    },
    refreshSortContent: {
      type: Object,
      default: null,
    },
    onEditItem: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    menus: [],
  }),
  computed: {
    ...mapState('app',[ 'dtouchSimulationLocale', 'dtouchWorkspace', 'dtouchSortUpdated' ]),
    isDev () {
      return process.env.NODE_ENV === 'development'
    },
  },
  watch: {
    dtouchWorkspace () {
      this.getAllMenus()
    },
    dtouchSortUpdated () {
      this.getAllMenus()
    },
  },
  mounted () {
    this.getAllMenus()
  },
  methods: {
    getAllMenus () {
      api.getAllWithoutLimit ('dtouch', `v1/public/workspaces/${this.dtouchWorkspace}/menus/`)
        .then((response) => {
          this.menus = response
          //if(this.isNew) response.push(JSON.parse(JSON.stringify(defaultMenu))) // add defaultMenu to simulation when is new
          //this.menus = response.map(item => this.prepareMenu(item))
        })
    },
  }
}
</script>

