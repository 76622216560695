var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",style:({ 'background-color': _vm.config.BtnBackgroundColor}),attrs:{"fab":"","dark":"","x-small":""}},on),[_c('v-icon',{style:({ 'color': _vm.config.BtnTextColor}),attrs:{"dark":""}},[_vm._v("mdi-camera")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticStyle:{"padding":"10px 0px 0px 0px"}},[_c('div',{staticStyle:{"width":"100%","text-align":"right","padding":"0 40px"}},[_c('button',{staticStyle:{"margin-right":"-30px","margin-top":"-10px"},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{staticClass:"activity-button",staticStyle:{"margin-left":"0px","color":"white","font-size":"30px"},style:({ 'color': _vm.config.BtnBackgroundColor })},[_vm._v(" mdi-close ")])],1)]),_c('center',{staticStyle:{"margin-bottom":"20px"}},[_c('button',{staticStyle:{"border-radius":"50%","height":"55px","width":"55px","text-align":"center","padding":"5px 5px 2px 5px","opacity":"0.7"},style:({ 'background-color': _vm.config.BtnBackgroundColor}),attrs:{"disabled":"true"}},[_c('v-icon',{staticClass:"activity-button",staticStyle:{"margin-left":"0px","color":"white","font-size":"35px"},style:({ 'color': _vm.config.BtnTextColor}),attrs:{"dark":""}},[_vm._v(" mdi-camera ")])],1)]),_c('center',{staticStyle:{"margin":"0 10px"},style:({ 'color': _vm.config.FoodDrinkTextColor,
            'font-size': ((_vm.config.FontSizeContent) + "px"),
            'font-family': _vm.config.FontContent, 
          })},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('center',{staticStyle:{"margin":"0 10px"},style:({ 'color': _vm.config.ColorContent,
            'font-size': ((_vm.config.FontSizeContent - 5) + "px"),
            'font-family': _vm.config.FontContent, 
          })},[_vm._v(" "+_vm._s(_vm.ingredients)+" ")]),_c('center',{staticStyle:{"margin-top":"5px"},style:({ 'color': _vm.config.ColorContent,
            'font-size': ((_vm.config.FontSizeContent) + "px"),
            'font-family': _vm.config.FontContent, 
          })},[_vm._v(" "+_vm._s(_vm.data.FoodPrice)+" ")]),_c('center',{staticStyle:{"margin-top":"20px"}},[_c('img',{staticStyle:{"margin-bottom":"-6px"},attrs:{"src":_vm.data.FoodImage,"width":"100%","heigth":"auto","alt":"item.Name"}})])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }