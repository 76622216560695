<template>
  <div>
    <v-layout wrap>
      <v-flex v-bind="{ [columnType]: true }"
        v-for="item in selectedAllergens"
        :key="item.ID"
        style="padding:3px;"
      >
        <center>
          <v-img     
            :src="item.Image"
            style="height:auto"
            :style="{ 'width': columnType === 'xs2' ? '75%': '100%'}"
          />
        </center>
      </v-flex>
    </v-layout>    
  </div>
</template>
<script>
  import api from '@/services/api'
  import utils from '@/services/utils'
  export default {
    props: {
      allergens: {
        type: [Array, String],
        required: true,        
      },
      columnType: {
        type: String,
        required: false,
        default: 'xs2'
      }
    },
    data () {
      return {
        allergensResponse: [],
        selectedAllergens: []
      }
    },
    computed: {
      workspaceID () {
        return utils.getWorkspace('dtouch')
      },
    },
    mounted () {
      api.getItem ('dtouch', `v1/public/workspaces/`, this.workspaceID.concat('/allergens'))
        .then((response) => {
          this.allergensResponse = response.data.map(item => {
            item.FullName = item.Name,
            item.Name = utils.getTranslation (item.Name, this.locale)
            item.Image = `https://www.dtouch.es/assets/allergens/${item.Image}.png`
            return item
          })
          this.selectedAllergens = this.allergensResponse.filter(x => utils.checkJSON(this.allergens).indexOf(x.ID)>=0)
        })
      /*
      const enterpriseIDDomain = this.$store.state.app.enterpriseID ? this.$store.state.app.enterpriseID : utils.getDomain()
      api.getItem('utils', false, `allergens/${enterpriseIDDomain}`)
        .then(response => {
        this.allergensResponse = response.data.map(item => {
          item.FullName = item.Name,
          item.Name = utils.getTranslation (item.Name, this.locale)
          item.Image = `https://www.dtouch.es/assets/allergens/${item.Image}.png`
          return item
        })
        
        this.selectedAllergens = this.allergensResponse.filter(x => this.allergens.indexOf(x.ID)>=0)
      })
        */
    },
    methods: {
      checkJSON (v) {
        return typeof v == 'object' ? v : JSON.parse(v)
      },
    },
  }
</script>
