<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="490"
      dark
    >
      <v-card 
        v-if="value"       
        style="opacity: 90%;"
      >
        <v-card-title class="text-h5">
          {{ getTranslation(value.Title, locale)}}
          <v-spacer/>
          <v-btn
            color="#CCCCCC"
            fab
            x-small
            elevation="0"
            @click="onClose"
          >
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ getTranslation(value.Description, locale)}}
        </v-card-text>
          <v-img
            v-if="value.Image"
            :src="value.Image"
            width="100%"
          />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import utilsService from '@/services/utils'
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      requried: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
  watch: {
    value () {
      this.dialog = this.value ? true : false
    },
  },
  methods: {
    getTranslation (v, locale) {
      if (!v) return ''
      return utilsService.getTranslation(v, locale)
    },
  },
}
</script>

