<template>
   <v-card 
      class="mx-auto"
      max-width="344"
      style="margin: 20px;"
   >
      <v-img
         v-if="activity.Image"
         :src="activity.Image"
         height="200px"
            @click="handleShow"
       />

      <v-card-title
            @click="handleShow"
         :style="{ color: content.BookingActTitleColor }"
      >
         {{ getTranslation(activity.Name, locale) }}
      </v-card-title>

      <v-card-subtitle
         v-if="(activity && activity.Subtitle) || (category && category.Name)"
         @click="handleShow"
         style="padding: 0 15px;-webkit-box-decoration-break: clone;box-decoration-break: clone;"
         :style="{ color: content.BookingActTitleColor }"
      >
         {{ getSubtitle(locale) }}
       </v-card-subtitle>
      <v-card-actions>
         <v-btn
            :color="content.BookingActWeekSecondaryColor"
            text
            @click="handleShow"
         >
            {{ $t('booking.reservation', locale) }}
         </v-btn>

         <v-spacer/>

         <v-btn
           icon
            @click="handleShow"
            :color="content.BookingActWeekSecondaryColor"
         >
           <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
         </v-btn>
       </v-card-actions>
      <v-expand-transition>
         <div
            v-if="show"
         >
            <dates 
               :selectedActivity="activity"
               :content="content"
               :locale="locale"
               :config="config"
            />
            <div 
               class="ql-editor ql-blank"
               style="padding: 0 20px 20px 20px;min-height: auto !important;"
               :style="{ color: content.BookingActContentColor }"
            >
               <div style="text-align: none;" v-html="getTranslation(activity.Description, locale)">{{getTranslation(activity.Description, locale)}}</div>
               <place
                 :placeID="activity.PlaceID"
                 :label="$t('booking.place', locale)"
                 :locale="locale"
               />
               <place
                 :placeID="activity.MeetingPointID"
                 :label="$t('booking.meetingPoint', locale)"
                 :locale="locale"
                />
            </div>
         </div>
      </v-expand-transition>
   </v-card>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import utils from '@/services/utils'
import Dates from './Dates'
import Place from '../Place'
export default {
   components: {
      Dates,
      Place
   },
   props: {
      autoExpand: {
         type: Boolean,
         default: false,
      },
      locale: {
         type: String,
         required: true,
      },
      activity: {
         type: Object,
         required: true,
      },
      content: {
         type: Object,
         required: true,
      },
      config: {
         type: Object,
         required: true,
      },
   },
   data: () => ({
      loading: false,
      show: false,
      category: null,
   }),
   computed: {
      ...mapState('app', ['dtouchWorkspace']),
   },
   created () {
      this.show = this.autoExpand
   },
   mounted () {
      this.handleGetCategory()
   },
   methods: {
      parseJSON (v) {
         return typeof v === 'string' ? JSON.parse(v) : v
      },
      getTranslation (v, locale) {
         return utils.getTranslation(v, locale)
      },
      getSubtitle (locale) {
         const aux = this.activity && this.activity.Subtitle ? this.getTranslation(this.activity.Subtitle, locale) : null
         if (aux && aux.length > 0) return aux
         else return this.category && this.category.Name ? this.getTranslation(this.category.Name, locale) : ''
      },
      handleShow () {
         this.show = !this.show
      },
      handleGetCategory () {
         if (!this.activity.CategoryID) return
         const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/cm/${this.dtouchWorkspace}/categories/${this.activity.CategoryID}`
         console.log('jhm23', url)
         axios.get(url)
            .then((response) => {
               const aux = response.data.shift()
               this.category = aux
               //this.category = response && response.data ? response.data.shift() : null
            })
            .catch((error) => {
               console.log(error)
            })
            .then(() => {
               this.loading = false
            })
      },
   },
}
</script>
<style>
  .ql-editor p {
    padding: 0 !important;
  }
  .result-html .ql-toolbar.ql-snow {
    height: 0px !important;
    padding: 0px !important;
    border: 0px !important;
  }
  .result-html .ql-container.ql-snow {
    border: 0px !important;
  }
  .result-html .ql-editor {
    min-height: 0px !important;
  }   
</style>

