<template>
  <div v-if="config">
    <custom-canvas
      v-if="configPoints"
      :config="configPoints"
      :locale="locale"
    />
  </div>
</template>
<script>
import apiDtouch from '@/services/apiDtouch'
import utils from '@/services/utils'
import CustomCanvas from './CustomCanvas'
export default {
  name:'Item57InteractiveMaps',
  components: {
    CustomCanvas,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    configPoints: null,
  }),
  computed: {
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
      //return utils.getWorkspaceIdentification ()
    },
    isIssues () {
      return this.type === 53
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    async handleGetData () {
      try {
        if (!this.content.InteractiveMapsID) return
        const map = await new Promise((resolve, reject) => apiDtouch.getItem(`v2/public/interactive-maps/${this.content.InteractiveMapsID}`)
          .then(response => {
            resolve(response)
          }).catch (error => {
            reject(error)
          }))
        const points = await new Promise((resolve, reject) => apiDtouch.getAll(`v2/public/interactive-maps/${this.content.InteractiveMapsID}/points`)
          .then(response => {
            resolve(response.map(item => {
              item.Title = JSON.parse(item.Title)
              item.Description = JSON.parse(item.Description)
              return item
            }))
          }).catch (error => {
            reject(error)
          }))
        const w = (document.getElementsByClassName('simulation')[0].clientWidth)
        //const scale = w > v.Width ? 1 : w / v.Width
        const scale =  w / map.Width
        const width = map.Width * scale | 0
        const height = map.Height * scale | 0

        this.configPoints = {
          background: map.Image,
          scale,
          width,
          height,
          points,
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
<style scoped>
  .requests-result {
    table-layout: fixed;
    width: 100% !important;
    max-width: 100% !important;
  }
  .requests-result .name {
    width: auto;
    line-height: 1;
  }
  .requests-result .action {
    width: 100px;
    /*white-space: nowrap;*/
    text-align: right;
    vertical-align: top;
  }
</style>


