<template>
  <div v-if="content.WeeklyMenuContentID" style="padding: 0 10px;">
      <week-menu
         :disabled="loading"
         :day="daysToAdd"
         :primaryColor="weekColor1"
         :secondaryColor="weekColor2"
         :locale="locale"
         :onChange="handleChangeDay"
      />
      <meals-item
        :id="cmDviewcontentContentID"
        :daysToAdd="daysToAdd"
        :style="{ fontFamily: config ? config.FontContent : null }"
        :color1="color1"
        :color2="color2"
      />
  </div>
</template>
<script>
import WeekMenu from './WeekMenu'
import MealsItem from './MealsItem'
export default {
  name:'Item51WeeklyMenu',
  components: {
    WeekMenu,
    MealsItem,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    daysToAdd: 0,
  }),
  computed: {
    cmDviewcontentContentID () {
      return this.content.WeeklyMenuContentID
    },
    weekColor1 () {
      return this.content.WeeklyMenuWeekColor1
    },
    weekColor2 () {
      return this.content.WeeklyMenuWeekColor2
    },
    color1 () {
      return this.content.WeeklyMenuColor1
    },
    color2 () {
      return this.content.WeeklyMenuColor2
    },
  },
  methods: {
    handleChangeDay (v) {
      this.daysToAdd = v
    },
  },
}
</script>

