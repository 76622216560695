<template>
  <div 
    v-if="point"
    class="dish-orders"
  >
  <v-layout wrap justify-end>
    <v-flex xs12>
      <v-layout wrap justify-center style="width:200px;float:right;">
        <v-flex xs2>
          <center>
            <v-icon @click="numItems > 1 ? numItems-- : null">mdi-minus</v-icon>
          </center>
        </v-flex>
        <v-flex xs2>
          <center class="numItems">
            {{ numItems }}
          </center>
        </v-flex>
        <v-flex xs2>
          <center>
            <v-icon @click="numItems++">mdi-plus</v-icon>
          </center>
        </v-flex>
        <v-flex xs6>
          <center v-if="content.MenuContentID">
            <dish-orders-extras
              v-if="!content.FoodOnlyOne"
              :contentID="content.MenuContentID"
              :onOrder="handleOrder"
              :locale="locale"
              :config="config"
              :label="content.FoodLabelExtras"
            />
            <dish-orders-extras-only-one
              v-else
              :contentID="content.MenuContentID"
              :onOrder="handleOrder"
              :locale="locale"
              :config="config"
              :label="content.FoodLabelExtras"
            />
          <!--v-btn
            small
            block
            @click="handleOrder"
            :color="config.BGColor"
            :style="{ color: config.Color }"
          >
            {{ $t('dtouch.dishOrders.order', locale) }}
          </v-btn-->
          </center>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="timeout"
    >
      <p v-if="title">
        {{ title }}
      </p>
      <p v-if="subtitle">
        <small>
          {{ subtitle }}
        </small>
      </p>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{ $t('dtouch.dishOrders.close', locale) }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apiDishOrders from '@/services/apiDishOrders'
import DishOrdersExtras from '../../../../../dishOrders/foodDrinkExtrasForm/DishOrdersExtras'
import DishOrdersExtrasOnlyOne from '../../../../../dishOrders/foodDrinkExtrasForm/DishOrdersExtrasOnlyOne'
export default {
  components: {
    DishOrdersExtras,
    DishOrdersExtrasOnlyOne,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    numItems: 1,
    showSnackbar: false,
    timeout: 5000,

    title: null,
    subtitle: null,
  }),
  computed: {
    ...mapState('app',['dtouchDishOrder']),
    point () {
      return this.dtouchDishOrder ? this.dtouchDishOrder.point : null
      //return this.$route.query.point
    },
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
    },
    contentID () {
      return this.content.MenuContentID
    },
  },
  watch: {
    'dtouchDishOrder.point'() {
      this.preparePoint()
    },
  },
  mounted () {
    this.preparePoint()
  },
  methods: {
    preparePoint () {
      this.show = this.point
    },
    async handleOrder (extras) {
      // TODO: JHM
      if (this.$route.query.point && this.workspaceID) await apiDishOrders.preparePoint()

      if (!this.point || !this.workspaceID) alert('error')

      const body = {
        NumItems: this.numItems,
        WorkspaceID: this.workspaceID,
        PointID: this.point,
        MenuContentID: this.content.MenuContentID || this.content.ID,
        Extras: extras
      }
      apiDishOrders.addOrder(body) 
        .then((response) => {
          if (!response || !response.data || response.data.status !== 'OK') return alert(response ? response.data.error : 'error')
          this.title = this.$t('dtouch.dishOrders.messages.orderAdded', this.locale)
          this.subtitle = this.$t('dtouch.dishOrders.messages.rememberConfirmOrder', this.locale)
          this.showSnackbar = true

          if (!this.dtouchDishOrder) this.$store.state.app.dtouchDishOrder = {}
          this.$store.state.app.dtouchDishOrder.OrderID = response.data.ID
          this.$store.state.app.dtouchDishOrder.LMD = new Date()

          apiDishOrders.setOrderSession(response.data.ID)
        })
    },
  },
}
</script>
<style scoped>
.dish-orders {
  width: 100%;
  padding: 10px;
}
.numItems {
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
}
</style>

