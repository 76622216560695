<template>
  <div v-if="!isTotem">
    <v-card
      v-if="selectedMeteo && this.labels"
      color="#88B7C2"
      dark
      style="border-radius:0px;width:100%;"
      :style="{ 'padding': !isSimulation && isDesktop ? '40px' : '0'}"
    >
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline"><center>{{selectedMeteo.Lugar.toUpperCase()}}</center></v-list-item-title>
          <v-list-item-subtitle><center>{{selectedMeteo.sDay}}</center></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-card-text style="padding: 0 30px 0 20px !important;">
        <v-layout align-center>
          <v-flex xs6 display-3 style="text-align:right;">
            {{selectedMeteo.Max}}&deg;C
          </v-flex>
          <v-flex xs6>
            <v-img
              :src="selectedMeteo.Icono"
              :eager="true"
              width="200"
            />
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-send</v-icon>
        </v-list-item-icon>
        <v-list-item-subtitle>{{selectedMeteo.Km_h}} km/h</v-list-item-subtitle>
      </v-list-item>

      <v-divider v-if="isTotem" />
      <v-slider
        v-else
        v-model="time"
        :max="6"
        :tick-labels="labels"
        class="mx-4"
        ticks
        style="cursor: pointer !important;"
        @click="handleChangeDay"
      />

      <v-list class="transparent">
        <v-list-item
          v-for="index in 3"
          :key="index"
        >
          <v-list-item-title>{{ meteoData[index].DayOfWeek }}</v-list-item-title>

          <v-list-item-icon>
            <v-img
              :src="meteoData[index].Icono"
              width="120"
            />
          </v-list-item-icon>

          <v-list-item-subtitle class="text-right">
            {{ meteoData[index].Temp }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
  <div v-else>
  <v-card
    v-if="selectedMeteo && this.labels"
    class="mx-auto"
      color="#88B7C2"
    dark
    style="border-radius:0px;padding: 80px;height: calc(100vh)"
    elevation="0"
  >
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline"><center style="font-size: 3rem !important;">{{selectedMeteo.Lugar.toUpperCase()}}</center></v-list-item-title>
        <v-list-item-subtitle><center style="font-size: 1.8rem !important;">{{getTranslateDay(selectedMeteo.sDay, locale)}}</center></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-card-text style="padding: 0 30px 0 20px !important;">
      <v-layout align-center>
        <v-flex xs6 display-3 style="text-align:right;font-size: 5rem !important;">
          {{selectedMeteo.Max}}&deg;C
        </v-flex>
        <v-flex xs6>
          <v-img
            :src="selectedMeteo.Icono"
            eager="true"
            width="200"
          />
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-list-item>
      <v-list-item-icon>
        <v-icon>mdi-send</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle style="font-size: 1.5rem;">{{selectedMeteo.Km_h}} km/h</v-list-item-subtitle>
    </v-list-item>

    <v-divider />

    <v-slider
      v-model="time"
      :max="6"
      :tick-labels="labels"
      class="mx-4"
      ticks
      style="cursor: pointer !important;"
      @click="handleChangeDay"
    />

    <v-list class="transparent">
      <v-list-item
        v-for="index in 3"
        :key="index"
      >
        <v-list-item-title
          style="font-size: 1.8rem !important"
        >{{ getTranslateDayOfWeek(meteoData[index].DayOfWeek, locale) }}</v-list-item-title>

        <v-list-item-icon>
          <v-img
            :src="meteoData[index].Icono"
            width="200"
          />
        </v-list-item-icon>

        <v-list-item-subtitle class="text-right" style="font-size: 1.8rem !important">
          {{ meteoData[index].Temp }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-card>
  </div>
</template>
<script>
import utils from '@/services/utils'
export default {
  name:'Item9Meteo',
  props: {
    config: {
      type: Object,
      default: null,
    },
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    isSimulation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mainColor: null,
    meteoData: null,
    labels: null,
    selectedMeteo: null,
    time: null,
  }),
  computed: {
    sizeW () {
      return window.innerWidth - (this.isMobile() ? 360 : 0)
    },
    queryIcon () {
      return this.$route.query.icon ? `${this.$route.query.icon}` : this.isTotem ? '22' : '8'
    },
    isTotem () {
      return this.$route.path === '/totem'
    },
    isMobile () {
      return !this.isDesktop
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    getTranslateDay (v, locale) {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      const aux = new Date(v)
      return aux.toLocaleDateString(locale, options)
    },
    getTranslateDayOfWeek (v, locale) {
      const aux = new Date(v)
      return aux.toLocaleDateString(locale, {weekday: 'long'}).toUpperCase()
    },
    prepareData () {
      this.mainColor = this.config.BGColor 
      this.getData()
    },
    getData () {
      fetch(`${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/workspaces/${this.workspaceID}/meteo`)
        .then(response => response.json())
        .then(data => {
          this.meteoData = data.map(item => {
            return ({
              Dia: item.Dia,
              sDay: item.Dia,//aux.toLocaleDateString(this.locale, options),
              DayOfWeek: item.Dia, //aux.toLocaleDateString(this.locale, {weekday: 'long'}).toUpperCase(),
              Icono: item.Icono.replace('imagenesmeteo/', `imagenesmeteo${this.queryIcon}/`),
              Km_h: item.Km_h,
              Lugar: item.Lugar,
              Max: item.Max,
              Min: item.Min,
              Temp: `${item.Max}\xB0/${item.Min}\xB0`
            })
          })
          this.labels = []
          for (let i=0; i < this.meteoData.length; i++) {
            //this.labels.push(new Date(this.meteoData[i].DayOfWeek).getDate())
            this.labels.push(this.getTranslateDayOfWeek(this.meteoData[i].DayOfWeek, this.locale).substring(0, 2))
          }
          this.selectedMeteo = this.meteoData[0]
        })
    },
    handleChangeDay () {
      this.selectedMeteo = this.meteoData[this.time]
    },
  }
}
</script>
<style>
  .v-slider__tick-label {
    cursor: pointer !important;
  }
</style>

