<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ $t('acttiv.reservationCreated', locale)}}
        </v-card-title>

        <v-card-text>
          {{$t('acttiv.locator', locale)}}: {{locator}}
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            {{$t('common.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    props: {
    },
    data () {
      return {
        dialog: false,
        locator: null
      }
    },
    computed () {
      return this.$store.state.app.locale
    },
    watch: {
      'data.localizador' (newValue) {
        this.locator = newValue
        this.dialog = true
      }
    },
    mounted () {
      this.locator = this.data.localizador
      this.dialog = true
    }
  }
</script>
