<template>
   <v-card
      :style="{ 'opacity': event.outdated ? '0.3' : '1'}"
      style="border-radius: 5px; height:100%;"
   >
      <v-layout
         wrap
         style="height:100%;"
      >
         <v-flex
            xs4
            style="padding: 0;height:100%;"
         >
            <v-img
               :src="event.image"
               lazy-src="https://www.dviewcontent.com/activity/acttiv/images/loading.jpg"
               height="100%"
               width="90%"
               aspect-ratio="1.7"
               class="grey lighten-2"
               style="border-radius: 5px 0 0px 5px;"
            />
         </v-flex>
         <v-flex
            :style="{ 'color': contentColor}"
            xs8
         >
            <v-layout
               wrap
               style="margin-top: 5px;margin-bottom:0px;"
            >
               <v-flex
                  xs12
                  style="text-align: left;padding-bottom:5px;line-height:1.2;font-weight: 300 !important;"
                  :style="{ 'color': titleColor }"
               >
                  {{ event.title.toUpperCase() }}
               </v-flex>
               <!--v-flex
                  xs8
                  style="text-align: left;padding-bottom:5px;line-height:1.2;"
                  :style="{ 'color': titleColor }"
               >
                  {{ event.title.toUpperCase() }}
               </v-flex>
               <v-flex
                  xs4
                  style="text-align: right;padding-right: 15px;padding-bottom:0px;"
               >
                  <add-calendar
                     v-if="event && !event.outdated"
                     :start="event.date"
                     :end="event.date"
                     :startTime="new Date(event.start).toTimeString().slice(0,5)"
                     :endTime="new Date(event.end).toTimeString().slice(0,5)"
                     :title="event.title"
                     :color="titleColor"
                     :active="event.active"
                     :locale="locale"                
                  />
               </v-flex-->
               <v-flex
                  xs12
                  style="text-align: left;padding-top: 0px;padding-bottom: 0px;font-family: Roboto Condensed;font-size:15px !important;"
               >
                  <v-icon
                     :style="{ 'color': event.active ? 'rgb(85,173,11)' : timeColor}"                  
                     dark
                     class="activity-button"
                     style="margin: -3px 0 0 0;font-weight: 500;font-size:1rem;"
                  >
                     mdi-clock-outline
                  </v-icon>
                  <span
                     :style="{ 'color': event.active ? 'rgb(85,173,11)' : timeColor}"                  
                     style="font-weight: 500;"
                  >
                     {{ event.rangeTime }}
                  </span>
               </v-flex>
               <v-flex xs12
                  v-if="event.description"
                  style="padding-right: 10px;text-align: left;font-family: Roboto Condensed;font-size:10px !important;line-height:1.2;font-weight:300;"
                  :style="{ 'color': contentColor }"
                  v-html="event.description"
               />
               <v-flex
                  xs12
                  style="min-height:40px;text-align: left;padding-bottom:5px;padding-top: 0px;padding-right:10px;"       
               >
               <table>
                  <tr>
                     <td>
                        <more-info
                           :event="event"
                           :primaryColor="primaryColor"
                           :secondaryColor="secondaryColor"
                           :titleColor="titleColor"
                           :contentColor="contentColor"
                           :timeColor="timeColor"
                           :locale="locale"
                        />
                     </td>
                     <td>
                        <reservation-form
                           v-if="!dtouchIsTouchPublic && event.AllowReservations && !event.outdated && !isTotem"
                           :color="primaryColor"
                           :event="event"
                           :locale="locale"
                        />
                        <v-btn
                           v-else-if="event.ReservationRequired"
                           x-small
                           text
                           style="margin: 10px 0;"
                           disabled
                         >
                           {{ $t('booking.reservationRequired', locale).toUpperCase() }} {{ event.ClosedReservations ? ' (*)' : '' }}
                         </v-btn>
                     </td>
                  </tr>
               </table>
                  <!--reservation-form
                     v-if="event.allowReservation && (!activity.TimeLimit || (new Date().getTime() - activity.TimeLimit.getTime()) > 0)"
                     :color="titleColor"
                     :event="event"
                  /-->
                  <!--v-btn
                     x-small
                     test
                     dark
                     :color="secondaryColor"
                     style="margin: 10px 0 10px 10px;float:left;"
                   >
                     {{ $t('booking.moreInfo', locale).toUpperCase() }}
                   </v-btn-->
               </v-flex>
            </v-layout>
         </v-flex>
      </v-layout>
   </v-card>
</template>
<script>
import utils from '@/services/utils'
//import AddCalendar from './AddCalendar'
import ReservationForm from './ReservationForm'
import MoreInfo from './MoreInfo'
export default {
   name: 'ActivityItem',
   components: {
      //AddCalendar,
      ReservationForm,
      MoreInfo,
   },
   props: {
      event: {
         type: Object,
         required: true,
      },
      locale: {
         type: String,
         default: null,
      },
      contentColor: {
         type: String,
         required: true,
      },
      titleColor: {
         type: String,
         required: true,
      },
      primaryColor: {
         type: String,
         required: true,
      },
      secondaryColor: {
         type: String,
         required: true,
      },
      timeColor: {
         type: String,
         required: true,
      },
   },
   computed: {
      dtouchIsTouchPublic () {
         return this.$store.state.app.dtouchIsTouchPublic
      },
      isTotem () {
        //return this.$route.path === '/totem'
        return this.$route.path === '/totem' || utils.isTotem()
      },
   },
   methods: {

   },
}
</script>

