<template>
  <v-row justify="center" style="margin: 0 -12px;">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on }">
        <v-img
          if="imageFood"
          ref="imageFood"
          :src="content.FoodImage"
          contain
          aspect-ratio="1"
          v-on="on"
          style="padding: 0 10px;"
        />
        <div
          style="position:absolute;top:10px;left:10px;opacity:0.5;"
        >
          <v-icon color="white">mdi-arrow-expand</v-icon>
        </div>
      </template>
      <v-card>
        <v-card-text style="padding: 10px 0px 0px 0px;">
          <div style="width:100%;text-align:right;padding: 0 40px">
            <button 
            style="margin-right:-30px;margin-top:-10px;"
              @click="dialog = false"
            >
              <v-icon
                style="margin-left: 0px; color: white;font-size:30px;"
                :style="{ 'color': config.BtnBackgroundColor }"
                class="activity-button"
              >
                mdi-close
              </v-icon>
            </button>
          </div>
          <center style="margin-bottom: 20px;">
            <button 
            disabled="true"
            style="border-radius:50%;height: 55px;width:55px;text-align:center;padding:5px 5px 2px 5px;opacity:0.7;"
            :style="{ 'background-color': config.BtnBackgroundColor}"
          >
            <v-icon
              style="margin-left: 0px; color: white;font-size:35px;"
              dark
              class="activity-button"
              :style="{ 'color': config.BtnTextColor}"
            >
              mdi-camera
            </v-icon>
          </button>
          </center>
          <center
            :style="{ 'color': config.FoodDrinkTextColor,
              'font-size': `${config.FontSizeContent}px`,
              'font-family': config.FontContent, 
            }"
            style="margin: 0 10px"
          >
            {{ name }}
          </center>
          <center
            :style="{ 'color': config.ColorContent,
              'font-size': `${config.FontSizeContent - 5}px`,
              'font-family': config.FontContent, 
            }"
            style="margin: 0 10px"
          >
            {{ ingredients }}
          </center>
          <center 
            style="margin-top:5px;"
            :style="{ 'color': config.ColorContent,
              'font-size': `${config.FontSizeContent}px`,
              'font-family': config.FontContent, 
            }"
          >
            {{ content.FoodPrice }}
          </center>
          <center style="margin-top:20px;">
            <img
              :src="content.FoodImage"
              width="100%"
              heigth="auto"
              alt="name"
              style="margin-bottom: -6px;"
            > 
          </center>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import utils from '@/services/utils'
  export default {
    props: {
      content: {
        type: Object,
        required: true
      },
      config: {
        type: Object,
        required: true,
      },
      locale: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        dialog: false,
        name: '',
        ingredients: '',
        iconWidth: null
      }
    },
    computed: {
      enterpriseColor () {
        return this.$store.state.app.enterpriseColor
      },
    },
    watch: {
      locale () {
        this.prepareData()
      },
    },
    mounted () {
      this.prepareData()
    },
    methods: {
      prepareData () {
        this.name = this.content.FoodName ? utils.getTranslation (this.content.FoodName, this.locale) : ''
        this.ingredients = this.content.FoodIngredients ? utils.getTranslation (this.content.FoodIngredients, this.locale) : ''
      },
    }
  }
</script>
<style scoped>
  .modal-allergens {
    background-color: rgba(241, 241, 241);
  }
</style>

