var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"5px 10px"}},[_c('v-card',{style:({ 'background-color': (_vm.foodDrinkBGColor + " !important")})},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-container',{staticStyle:{"padding":"5px !important"}},[_c('table',{staticClass:"food-result"},[_c('tr',[(_vm.showDishesImage && _vm.data.Image)?_c('td',{staticStyle:{"vertical-align":"top","max-width":"100px","width":"30%"}},[_c('image-in-line',{attrs:{"data":_vm.data,"locale":_vm.locale,"config":_vm.config}})],1):_vm._e(),_c('td',{staticClass:"name",style:({ 'padding-left' : _vm.showDishesImage ? '10px' : '0',
              })},[_c('v-tooltip',{attrs:{"top":""},model:{value:(_vm.showLeaf),callback:function ($$v) {_vm.showLeaf=$$v},expression:"showLeaf"}},[void 0,_c('span',[_vm._v(_vm._s(_vm.$t('food.vegan', _vm.locale)))])],2),_c('v-tooltip',{attrs:{"top":""},model:{value:(_vm.showVegetarian),callback:function ($$v) {_vm.showVegetarian=$$v},expression:"showVegetarian"}},[void 0,_c('span',[_vm._v(_vm._s(_vm.$t('food.vegetarian', _vm.locale)))])],2),_c('v-tooltip',{attrs:{"top":""},model:{value:(_vm.showNew),callback:function ($$v) {_vm.showNew=$$v},expression:"showNew"}},[void 0,_c('span',[_vm._v(_vm._s(_vm.$t('food.new', _vm.locale)))])],2),_c('v-tooltip',{attrs:{"top":""},model:{value:(_vm.showRecommended),callback:function ($$v) {_vm.showRecommended=$$v},expression:"showRecommended"}},[void 0,_c('span',[_vm._v(_vm._s(_vm.$t('food.recommended', _vm.locale)))])],2),_c('p',{staticStyle:{"margin-bottom":"5px","line-height":"1.2","padding":"5px 0 0 0"},style:({ 'color': _vm.foodDrinkTextColor,
                  'font-size': (_vm.FontSizeContent + "px"),
                  'font-family': _vm.FontContent, 
                })},[(_vm.data.FoodVegan && !_vm.iconRight)?_c('v-icon',{staticStyle:{"color":"#a9c366"},on:{"click":function($event){_vm.showLeaf=!_vm.showLeaf}}},[_vm._v("mdi-leaf")]):_vm._e(),(_vm.data.FoodVegetarian && !_vm.iconRight)?_c('v-icon',{staticStyle:{"color":"#436f45"},on:{"click":function($event){_vm.showVegetarian=!_vm.showVegetarian}}},[_vm._v("mdi-leaf")]):_vm._e(),(_vm.data.FoodNew && !_vm.iconRight)?_c('v-icon',{staticStyle:{"color":"red"},on:{"click":function($event){_vm.showNew=!_vm.showNew}}},[_vm._v("mdi-new-box")]):_vm._e(),(_vm.data.FoodRecommended && !_vm.iconRight)?_c('v-icon',{staticStyle:{"color":"orange"},on:{"click":function($event){_vm.showRecommended=!_vm.showRecommended}}},[_vm._v("mdi-star-box")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getTranslation(_vm.data.Name, _vm.locale))+" "),(_vm.data.FoodVegan && _vm.iconRight)?_c('v-icon',{staticStyle:{"color":"#a9c366"},on:{"click":function($event){_vm.showLeaf=!_vm.showLeaf}}},[_vm._v("mdi-leaf")]):_vm._e(),(_vm.data.FoodVegetarian && _vm.iconRight)?_c('v-icon',{staticStyle:{"color":"#436f45"},on:{"click":function($event){_vm.showVegetarian=!_vm.showVegetarian}}},[_vm._v("mdi-leaf")]):_vm._e(),(_vm.data.FoodNew && _vm.iconRight)?_c('v-icon',{staticStyle:{"color":"red"},on:{"click":function($event){_vm.showNew=!_vm.showNew}}},[_vm._v("mdi-new-box")]):_vm._e(),(_vm.data.FoodRecommended && _vm.iconRight)?_c('v-icon',{staticStyle:{"color":"orange"},on:{"click":function($event){_vm.showRecommended=!_vm.showRecommended}}},[_vm._v("mdi-star-box")]):_vm._e()],1),_c('p',{staticStyle:{"line-height":"1.2","margin-bottom":"5px","padding-top":"5px"},style:({ 'color': _vm.ColorContent,
                  'font-size': (((_vm.FontSizeContent - 5)) + "px"),
                  'font-family': _vm.FontContent, 
                })},[_vm._v(" "+_vm._s(_vm.getTranslation(_vm.data.Ingredients, _vm.locale))+" ")]),(_vm.showDishesAllergens && _vm.data && _vm.data.Allergens)?_c('allergens-in-line',{attrs:{"config":_vm.config,"dtouchWorkspace":_vm.dtouchWorkspace,"allergens":_vm.data.Allergens,"columnType":!_vm.showDishesImage || !_vm.data.Image ? 'xs2' : 'xs3'}}):_vm._e()],1),_c('td',{staticClass:"price"},[_c('p',{staticStyle:{"line-height":"1","padding":"5px 0 0 0"},style:({ 'color': _vm.ColorContent,
                  'font-size': (_vm.FontSizeContent + "px"),
                  'font-family': _vm.FontContent, 
                })},[_vm._v(" "+_vm._s(_vm.data.Price)+" ")]),(_vm.data && _vm.data.Note)?_c('p',{staticStyle:{"line-height":"1","margin-top":"-10px"},style:({ 'color': _vm.ColorContent,
                  'font-size': (((_vm.FontSizeContent - 5)) + "px"),
                  'font-family': _vm.FontContent, 
                })},[_vm._v(" "+_vm._s(_vm.getTranslation(_vm.data.Note, _vm.locale))+" ")]):_vm._e(),_c('v-layout',{class:"justify-end",attrs:{"wrap":""}},[(_vm.data.Allergens && _vm.data.Allergens.length && !_vm.showDishesAllergens)?_c('v-flex',_vm._b({style:({ 'width' : ((_vm.data.Image && _vm.data.Allergens && _vm.data.Allergens.length ? 75 : 30) + "px")})},'v-flex',( _obj = {}, _obj[("xs" + (_vm.data.Image && _vm.data.Allergens && _vm.data.Allergens.length ? 6 : 12))] = true, _obj ),false),[_c('allergens',{attrs:{"data":_vm.data,"locale":_vm.locale,"config":_vm.config,"dtouchWorkspace":_vm.dtouchWorkspace}})],1):_vm._e(),(_vm.data.Image && !_vm.showDishesImage)?_c('v-flex',_vm._b({style:({ 'width' : ((_vm.data.Image && _vm.data.Allergens && _vm.data.Allergens.length ? 75 : 30) + "px")})},'v-flex',( _obj$1 = {}, _obj$1[("xs" + (_vm.data.Image && _vm.data.Allergens && _vm.data.Allergens.length ? 6 : 12))] = true, _obj$1 ),false),[_c('image-modal',{attrs:{"data":_vm.data,"locale":_vm.locale,"config":_vm.config}})],1):_vm._e()],1)],1)])])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }