<template>
  <v-layout wrap class="place" v-if="getPlaceName(place.name, locale) && getPlaceName(place.name, locale).length > 0">
    <v-flex xs12 v-if="place">
      <p class="label" :style="{ color: primaryColor }">{{ label }} :</p>
      <!--p :style="{ color: secondaryColor }" @click="handleClickPlace">{{ place.name }}</p-->
      <p :style="{ color: secondaryColor }" @click="handleClickPlace">{{ getPlaceName(place.name, locale) }}</p>
    </v-flex>
  </v-layout>
</template>
<script>
import utils from '@/services/utils'
export default {
  name: 'PlaceInfo',
  props: {
    item: {
      type: [Object, String],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
    secondaryColor: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    place: null,
  }),
  mounted () {
    this.place = typeof this.item === 'string' ? JSON.parse(this.item) : this.item
  },
  methods: {
    getPlaceName (v, locale) {
      console.log(v, locale)
      if (typeof v === 'string') return v
      else return utils.getTranslation(v, locale)
    },
    handleClickPlace () {
      if (!this.place.coordinates) return
      const coordinates = this.place.coordinates.split(',')
      if (coordinates.length !== 2) return
      const url = `https://maps.google.com/?q=${coordinates[0]}%2C${coordinates[1]}`
      //window.location = url
      window.open(url, '_blank')
    }
  },
}
</script>
<style scoped>
.place p {
  margin: 0 !important;
  font-size: 16px;
}
.place .label {
  font-size: 0.8rem;
}
</style>

