<template>
  <div
    class="brochure"
    :style="{
      'padding': '15px'
    }"
  >
    <v-card
      class="mx-auto"
      style="border-radius: 10px !important;padding-bottom: 15px !important;"
      :style="{ 'background-color': `${BGColorContent} !important`,
                'font-size': `${FontSizeContent}px !important`,
                'font-family': `${FontContent} !important`,
              }"
    >
      <v-img
        v-if="content.BrochureImage"
        :src="content.BrochureImage"
        height="130px"
        :style="{ 
            'max-width': isSimulation ? '345px' : `${(sizeW - 30)}px !important`
          }"
        @click="handleMainExpand"
      />

      <v-card-title
        @click="handleMainExpand"
      >
        <v-btn
          v-if="(title && title.length > 0) || (subtitle && subtitle.length > 0)"
          v-show="!hidden"
          :color="BGColorSecundary"
          fab
          dark
          x-small
          absolute
          top
          right
          :style="{ 'margin-top': content.BrochureImage ? '160px' : '30px' }"
          elevation="0"
          text
        >
          <!--v-icon :color="ColorSecundary">{{ mainExpand ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon-->
          <v-icon large :color="BGColorSecundary">{{ mainExpand ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
        <v-layout wrap>
          <v-flex xs11>
        <div style="width:100%;line-height:1.2;margin-left:5px;" v-if="title && title.length > 0" v-html="title" class="siteTitle"
          :style="{ 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent+3}px !important` }"
        />
        <p
          v-if="subtitle && subtitle.length > 0"
          :style="{ 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent-5}px !important` }"
          style="font-weight: 400;margin: 10px 10px 10px 5px !important;opacity:0.5"
        >
          {{subtitle}}
        </p>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-layout wrap 
        v-if="content.BrochureWithContact || content.BrochureWithLocation"
        v-show="mainExpand"
      >
        <v-card
          class="brochure-contact"
          :style="{ 'background-color': `${BGColorSecundary} !important`, 'color': `${ColorSecundary} !important` }"
          :elevation="0"
        >
        <v-toolbar
          :style="{ 'background-color': `${BGColorSecundary} !important`, 'color': `${ColorSecundary} !important` }"
          :elevation="0"
          style="border-radius: 8px;padding: 10px 0 0 0;height: auto;"
        >
            <v-icon :disabled="!phone" :style="{ color: `${ColorSecundary} !important`, opacity: !phone ? '0.2 !important' : null }" @click="handleClickPhone" >mdi-phone</v-icon>
          <v-spacer />
              <v-divider vertical :style="{ 'border-color': `${ColorSecundary} !important` }" style="opacity:0.3 !important;" />
          <v-spacer />
            <v-icon :disabled="!email" :style="{ color: `${ColorSecundary} !important`, opacity: !email ? '0.2 !important' : null }" @click="handleClickEmail" >mdi-email</v-icon>
          <v-spacer />
              <v-divider vertical :style="{ 'border-color': `${ColorSecundary} !important` }" style="opacity:0.3 !important;" />
          <v-spacer />
          <v-icon x-large @click="expandContact = !expandContact" :style="{ color: `${ColorSecundary} !important` }" >{{ expandContact ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
          <v-spacer />
              <v-divider vertical :style="{ 'border-color': `${ColorSecundary} !important` }" style="opacity:0.3 !important;" />
          <v-spacer />
            <v-icon :disabled="!web" :style="{ color: `${ColorSecundary} !important`, opacity: !web ? '0.2 !important' : null }" @click="handleClickWeb" >mdi-web</v-icon>
          <v-spacer />
              <v-divider vertical :style="{ 'border-color': `${ColorSecundary} !important` }" style="opacity:0.3 !important;" />
          <v-spacer />
            <v-icon :disabled="!address" :style="{ color: `${ColorSecundary} !important`, opacity: !address ? '0.2 !important' : null }" @click="handleClickMap" >mdi-map-marker</v-icon>
        </v-toolbar>
          <!--v-flex wrap>
            <v-flex xs
          </v-flex-->
          <div v-if="phone && expandContact" v-html="phone" class="brochure-contact-item-first" :style="{ 'font-size': `${FontSizeContent-5}px !important` }"/>
            <div v-if="email && expandContact" v-html="email" class="brochure-contact-item" :style="{ 'font-size': `${FontSizeContent-5}px !important`, 'border-top': `1px solid ${ColorSecundary}80 !important` }"/>
          <div v-if="web && expandContact" v-html="web" class="brochure-contact-item" :style="{ 'font-size': `${FontSizeContent-5}px !important`, 'border-top': `1px solid ${ColorSecundary}80 !important` }" />
          <div v-if="address && expandContact" v-html="address" class="brochure-contact-item" :style="{ 'font-size': `${FontSizeContent-5}px !important`, 'border-top': `1px solid ${ColorSecundary}80 !important` }"/>
        </v-card>
      </v-layout>
      <v-layout wrap 
        v-if="content.BrochureWithDescription"
        class="brochure-description"
        v-show="mainExpand"
        style="margin-top: 8px;"
      >
      <div
class="result-html"
        >
        <v-flex xs12
          class="ql-editor ql-blank"
          :style="{ 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent-4}px !important` }"
          v-html="description"
        />
      </div>
        <v-flex xs12>
        <span
          :style="{ 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent-5}px !important` }"
          style="opacity: 0.5;"
        >
          {{ note }}
        </span>
        </v-flex>
      </v-layout>
      <v-layout wrap 
        v-if="content.BrochureWithFacts && (hours || price)"
        class="brochure-facts"
        v-show="mainExpand"
      >
        <v-flex xs12>
        <v-divider v-if="hours" :style="{ 'border-color': `${ColorContent} !important` }" style="margin-bottom: 10px;opacity:0.3!important" />
        <p class="title"
          v-if="hours"
          :style="{ 'font-family': `${FontContent} !important`, 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent}px !important` }"
          v-html="$t('dtouch.brochure.hours', locale)"
        />
        <p class="subtitle"
          v-if="hours"
          :style="{ 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent-5}px !important` }"
          v-html="hours"
        />
        <v-divider v-if="hours" :style="{ 'border-color': `${ColorContent} !important` }" style="margin-top:20px;margin-bottom: 10px;opacity:0.3!important"/>
        <p class="title"
          v-if="price"
          :style="{ 'font-family': `${FontContent} !important`, 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent}px !important` }"
          v-html="$t('dtouch.brochure.price', locale)"
        />
        <p class="subtitle"
          v-if="price"
          :style="{ 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent-5}px !important` }"
          v-html="price"
        />
        </v-flex>
      </v-layout>
      <v-layout wrap 
        v-if="content.BrochureWithFacts && services"
        style="padding: 0px;"
        v-show="mainExpand"
      >
        <v-card
          class="brochure-services"
          :elevation="0"
        >
        <p class="title"
          :style="{ 'font-family': `${FontContent} !important`, 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent}px !important` }"
          v-html="$t('dtouch.brochure.services', locale)"
        />
          <div v-if="services" v-html="services" 
            :style="{ 'color': `${ColorContent} !important`, 'font-size': `${FontSizeContent-4}px !important` }"
          />
        </v-card>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import utils from '@/services/utils'
//import { VueEditor } from "vue2-editor"
export default {
  /*components: {
    VueEditor
  },*/
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    show: false,
    aux: [[],[],[],[],[],[],[],[],[],[],[],[],[{  }],[]],
    expandContact: false,
    mainExpand: false,
    hidden: false,
  }),
  computed: {
      FontContent () {
        return this.config.FontContent
      },
      FontSizeContent () {
        return this.config.FontSizeContent
      },
      BGColorContent () {
        return this.config.BGColorContent
      },
      ColorContent () {
        return this.config.ColorContent
      },
      BGColorSecundary () {
        return this.config.BGColorSecundary
      },
      ColorSecundary () {
        return this.config.ColorSecundary
      },
    title () {
      return this.content.BrochureTitle ? utils.getTranslation (this.content.BrochureTitle, this.locale) : ''
    },
    subtitle () {
      return this.content.BrochureSubtitle ? utils.getTranslation (this.content.BrochureSubtitle, this.locale) : ''
    },
    description () {
      let aux = utils.getTranslation (this.content.BrochureDescription, this.locale)
      if (aux) aux = aux.replace(/\n/g, '<br />')
      return this.content.BrochureWithDescription && aux && this.content.BrochureDescription ? aux : null
    },
    note () {
      return this.content.BrochureWithDescription && this.content.BrochureNote ? utils.getTranslation (this.content.BrochureNote, this.locale) : null
    },
    hours () {
      let aux = this.content.BrochureWithFacts && this.content.BrochureHours ? utils.getTranslation (this.content.BrochureHours, this.locale) : null
      if (aux) aux = aux.replace(/\n/g, '<br />')
      return aux
    },
    price () {
      let aux = this.content.BrochureWithFacts && this.content.BrochurePrice ? utils.getTranslation (this.content.BrochurePrice, this.locale) : null
      if (aux) aux = aux.replace(/\n/g, '<br />')
      return aux
    },
    services () {
      let aux = this.content.BrochureWithFacts && this.content.BrochureServices ? utils.getTranslation (this.content.BrochureServices, this.locale) : null
      if (aux) aux = aux.replace(/\n/g, '<br />')
      return aux
    },
    phone () {
      return this.content.BrochureWithContact && this.content.BrochurePhone ? this.$t('dtouchbrochure.phone', this.locale).substring(0, 3) + '.: ' + this.content.BrochurePhone : null
    },
    email () {
      return this.content.BrochureWithContact && this.content.BrochureEmail ? this.$t('dtouch.brochure.email', this.locale) + ': ' + this.content.BrochureEmail : null
    },
    web () {
      return this.content.BrochureWithContact && this.content.BrochureWeb ? this.$t('dtouch.brochure.web', this.locale) + ': ' + this.content.BrochureWeb : null
    },
    address () {
      let aux = this.content.BrochureAddress ? this.content.BrochureAddress + ' ' : ''
      aux = this.content.BrochurePostalCode ? aux + this.content.BrochurePostalCode + ' ' : ''
      aux = this.content.BrochureMunicipaly ? aux + this.content.BrochureMunicipaly + ' ' : ''
      return this.content.BrochureWithLocation && aux.length > 0 ? this.$t('dtouch.brochure.address', this.locale) + ': ' + aux : null
    },

    isSimulation () {
      return true
    },
    sizeW () {
      return window.innerWidth - (!utils.isMobile() ? 360 : 0)
    },
  },
  methods: {
    handleMainExpand () {
      this.mainExpand = !this.mainExpand
    },
    prepareTitle (v) {
      const result = `<p class="title" style="font-size:${this.FontSizeContent}px !important;">${v}</p>`
      return result
    },
    handleClickPhone () {
      const url = `tel:${this.content.BrochurePhone}`
      window.location.href = url
    },
    handleClickMap () {
      const coord = `${this.content.BrochureLat}%2C${this.content.BrochureLon}`
      const url = `https://maps.google.com/maps?q=${coord}&t=&z=13&ie=UTF8&iwloc=&output=embed`
      window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    handleClickEmail () {
      const url = `mailto:${this.content.BrochureEmail}`
      window.location.href = url
    },
    handleClickWeb () {
      let url = this.content.BrochureWeb
      if(url && url.toUpperCase().indexOf('HTTP') === -1) url = 'http://'.concat(url)
      window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
  }
}
</script>
<style scope>
.brochure .v-toolbar__content {
  height: 40px!important;
}
.brochure p {
  line-height: 1.2;
  margin: 0px !important;
  width: 100%;
}
.brochure-description {
  padding: 10px 20px;
}

.brochure-facts {
  padding: 10px 20px;
}

.brochure-contact {
  width: 100%;
  margin: 5px 10px !important;
  border-radius: 8px !important;
  padding-bottom: 10px;
}

.brochure-contact-item {
  margin: 0px 20px;
  padding: 8px 0px;
}

.brochure-contact-item-first {
  margin: 0px 20px;
  padding: 8px 0 8px 0;
}

.brochure-services {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.07) !important;
  margin: 15px !important;
  padding: 5px 10px 10px 10px;
  border-radius: 8px !important;
}
/*.brochure-services .title {
  line-height: 1;
  margin: 0px;
}*/
.ql-editor p {
  font-size: var(--FontSizeContent);
}
.result-html .ql-toolbar.ql-snow {
  height: 0px !important;
  padding: 0px !important;
  border: 0px !important;
}
.result-html .ql-container.ql-snow {
  border: 0px !important;
}
.result-html .ql-editor {
  min-height: 0px !important;
}   
</style>


