<template>
   <v-layout wrap
      justify-center
   >
      <v-flex xs12
         v-for="item of activities"
         :key="item.ID"
         style="padding:10px 20px;"
      >
         <activity
            :activity="item"
            :content="content"
            :locale="locale"
            :config="config"
            :autoExpand="activities && activities.length === 1"
         />
      </v-flex>
   </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import utils from '@/services/utils'
import Activity from './Activity'
export default {
   components: {
      Activity,
   },
   props: {
      locale: {
         type: String,
         required: true,
      },
      content: {
         type: Object,
         required: true,
      },
      config: {
         type: Object,
         required: true,
      },
   },
   data: () => ({
      loading: false,
      activities: null,
   }),
   computed: {
      ...mapState('app', ['dtouchWorkspace']),
   },
   mounted () {
      this.getActivities()
   },
   methods: {
      parseJSON (v) {
         return typeof v === 'string' ? JSON.parse(v) : v
      },
      getTranslation (v, locale) {
         return utils.getTranslation(v, locale)
      },
      getActivities () {
         // get all activities
         const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/cm/${this.dtouchWorkspace}/activities${this.content.BookingActWorkspaceID ? '/'.concat(this.content.BookingActWorkspaceID) : ''}`
         axios.get(url)
            .then((response) => {
               if (this.content.BookingActActivityID) {
                  // filter by activityID
                  response.data = response.data.filter(x => x.ID === this.content.BookingActActivityID)
               }
              /*if (this.content.BookingActCategoryID) {
                response.data = response.data.filter(x => x.CategoryID === this.content.BookingActCategoryID)
              }*/
               this.activities = response.data.map(item => {
                  return {
                     ID: item.ID,
                     Name: this.parseJSON(item.Name),
                     Description: this.parseJSON(item.Description),
                     Subtitle: this.parseJSON(item.Subtitle),
                     Image: item.Image,
                     PlaceID: item.PlaceID,
                     MeetingPointID: item.MeetingPointID,
                     Source: item.Source,
                     TimeLimit: item.TimeLimit,
                     CategoryID: item.CategoryID
                  }
               })
            })
            .catch((error) => {
               console.log(error)
            })
            .then(() => {
               this.loading = false
            })
      },
   },
}
</script>

