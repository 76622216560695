<template>
  <div v-if="authData && reservations && reservations.length > 0" style="width: 100%">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge right color="#fe6b2f">
          <template v-slot:badge>
            <span>{{reservations.filter(x=>x.Status!=='CANCEL').length}}</span>
          </template>      
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            fab
            :color="bgColor"
          >
          <v-icon color="white">mdi-check</v-icon>
            <!-- {{$t('reservationList.myReservations', locale)}} -->
          </v-btn>
        </v-badge>
      </template>
      <v-card>
        <v-toolbar
          dark
          :color="bgColor"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon :style="{ 'color': color}">mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title :style="{ 'color': color}">{{$t('reservationList.myReservations', locale)}}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-card
                style="margin-bottom: 10px;background-color: #CCCCCC80;"
                v-for="item of reservations"
                :key="item.location"
              >
                <v-card-title class="headline" v-if="item.ReservationData.activity">
                  <b>{{getName(item.ReservationData.activity.Name)}}</b>
                </v-card-title>

                <div style="padding: 0 10px;">
                  <b>{{$t('reservationList.participant', locale)}}: </b>{{item.ReservationData.asistentes.length}}
                  <br>
                  <div v-if="item.ReservationData.activity">
                    <b>{{$t('reservationList.date', locale)}}: </b>{{new Date(item.ReservationData.activity.Start).toLocaleDateString(locale, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}}   
                    <br>
                    <b>{{$t('reservationList.status', locale)}}: </b><span :style="{ color: item.Status !== 'OK' ? 'red' : 'black'}">{{$t(`reservationList.${item.Status}`, locale)}}</span>
                  </div>                   
                </div>

                <v-card-actions>
                  <v-spacer />
                  <v-btn text
                    v-if="item.Status === 'OK'"
                    @click="handleCancelReservation(item.Locator)"          
                  >
                    {{$t('acttiv.form.cancelReservation', locale)}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mapState
  } from 'vuex'
  import tinycolor from 'tinycolor2'
  import api from '@/services/api'
  import utils from '@/services/utils'
  export default {
    props: {
      updateKey: {
        type: String,
        required: false
      }
    },
    data() {
      return {
        reservations: null,
        dialog: false,
      }
    },
    computed: {
      ...mapState('app',['locale', 'authData']),      
      bgColor () {
        return this.$store.state.app.BGColor
      },
      color () {
        return this.$store.state.app.Color
      },
    },
    watch: {
      'authData.ID' () {
        this.getReservationList()
      },
      dialog () {
        this.getReservationList()
      },
      updateKey () {
        this.getReservationList()
      }
    },
    mounted() {
      this.getReservationList()
    },
    methods: {
      getName(name) {
        return utils.getTranslation(name, this.locale)
      },
      getReservationList () {
        if (this.authData && this.authData.ID) {
          api.getAll(`acttiv/reservations/list/${this.authData.ID}`, false, 1, 1000, '').then(response => {
            if (response.count > 0) this.reservations = response.data.map(item => {
              item.ReservationData = JSON.parse(item.ReservationData)
              return item
            })
            else this.reservations = null
          })
        }        
      },
      handleCancelReservation (locator) {
        api.getItem('acttiv/reservations/cancel', false, `${locator}`).then(() => {
          this.getReservationList()
        })
      },
      isLight (value) {
        return tinycolor(value).isLight()
      },
    }
  }
</script>
