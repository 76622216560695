<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on }">
        <v-btn class="mx-2" fab dark x-small v-on="on"
          :style="{ 'background-color': btnBackgroundColor}"
        >
          <v-icon dark :style="{ 'color': btnTextColor}">mdi-camera</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text style="padding: 10px 0px 0px 0px;">
          <div style="width:100%;text-align: right;padding: 0 40px">
            <button 
            style="margin-right:-30px;margin-top:-10px;"
              @click="dialog = false"
            >
              <v-icon
                style="margin-left: 0px; color: white;font-size:30px;"
                :style="{ 'color': btnBackgroundColor }"
                class="activity-button"
              >
                mdi-close
              </v-icon>
            </button>
          </div>
          <center style="margin-bottom: 20px;">
            <button 
            disabled="true"
            style="border-radius:50%;height: 55px;width:55px;text-align:center;padding:5px 5px 2px 5px;opacity:0.7;"
            :style="{ 'background-color': btnBackgroundColor}"
          >
            <v-icon
              style="margin-left: 0px; color: white;font-size:35px;"
              dark
              class="activity-button"
              :style="{ 'color': btnTextColor}"
            >
              mdi-camera
            </v-icon>
          </button>
          </center>
          <center
            :style="{ 'color': ColorContent,
              'font-size': `${FontSizeContent}px`,
              'font-family': FontContent, 
            }"
            style="margin: 0 10px"
          >
            {{name}}
          </center>
          <center
            :style="{ 'color': ColorContent,
              'font-size': `${FontSizeContent - 5}px`,
              'font-family': FontContent, 
            }"
            style="margin: 0 10px"
          >
            {{description}}
          </center>
          <center 
            style="margin-top:5px;"
            :style="{ 'color': ColorContent,
              'font-size': `${FontSizeContent}px`,
              'font-family': FontContent, 
            }"
          >
            {{data.ProductPrice}}
          </center>
          <center style="margin-top:20px;">
            <img
              :src="data.ProductImage"
              width="100%"
              heigth="auto"
              alt="item.Name"
              style="margin-bottom: -6px;"
            > 
          </center>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import utils from '@/services/utils'
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    config: {
      type: Object,
      required: true
    },

    locale: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      name: '',
      description: ''
    }
  },
  computed: {
    BGColor () {
      return this.config.BGColor
    },
    FontContent () {
      return this.config.FontContent
    },
    FontSizeContent () {
      return this.config.FontSizeContent
    },
    ColorContent () {
      return this.config.ColorContent
    },
    btnBackgroundColor () {        
      return this.config.BtnBackgroundColor && this.config.BtnBackgroundColor !== '' ? this.config.BtnBackgroundColor : this.BGColor
    },
    btnTextColor () {
      return this.config.BtnTextColor && this.config.BtnTextColor !== '' ? this.config.BtnTextColor : 'white'
    },
    // name () {
    //   return this.data.ProductName ? utils.getTranslation (this.data.ProductName, this.locale) : ''
    // },
    // ingredients () {
    //   return this.data.ProductDescription ? utils.getTranslation (this.data.ProductDescription, this.locale) : ''
    // },
  },
  watch: {
    locale (newValue) {
      this.name = this.data.ProductName ? utils.getTranslation (this.data.ProductName, newValue) : ''
      this.ingredients = this.data.ProductDescription ? utils.getTranslation (this.data.ProductDescription, newValue) : ''
    }
  },
  mounted () {
    this.name = this.data.ProductName ? utils.getTranslation (this.data.ProductName, this.locale) : ''
    this.description = this.data.ProductDescription ? utils.getTranslation (this.data.ProductDescription, this.locale) : ''
  },
}
</script>

<style scoped>
.modal-allergens {
  background-color: rgba(241, 241, 241);
}
</style>
