<template>
  <v-layout wrap v-if="config">
    <v-flex 
      v-for="(item, index) of messages"
      :key="index"
      xs12
    >
      <v-card
        elevation="0"
        :style="{ fontFamily: config.FontContent }"
      >
        <v-card-title
          :style="{ color: content.DviewcontentMessagesTitleColor}"
        >
          {{item.title}}
        </v-card-title>

        <v-card-title
          style="font-size: 1rem; padding: 0 16px 16px 16px;line-height: 1.375rem;letter-spacing: 0.0071428571em;font-weight: 400;"
          :style="{ color: content.DviewcontentMessagesDescriptionColor}"
        >
          {{item.description}}
        </v-card-title>
      </v-card>
      <v-divider v-if="index+1 !== messages.length" />
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import { parseString } from 'xml2js'
import axios from 'axios'
export default {
  name:'Item55DviewcontentMessages',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    config: null,
    messages: []
  }),
  computed: {
    ...mapState('app', ['dtouchWorkspace']),
  },
  watch: {
    dtouchWorkspace() {
      this.handleGetMessages()
    },
  },
  mounted () {
    this.handleGetMessages()
  },
  methods: {
    async handleGetMessages () {
      const messages = []
      try {
        const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/workspaces/${this.dtouchWorkspace}/dviewcontent-messages`
        this.config = await new Promise((resolve, reject) => axios.get(url)
          .then(response => {
            resolve(response && response.data && response.data.config ? response.data.config : null)
          })
          .catch(error => {
            reject(error)
          }))
        if (!this.config || !this.config.DviewcontentMessagesID) return
        const xml = `https://www.dviewcontent.com/message/result/_${this.config.DviewcontentMessagesID}.xml`
        const data = await new Promise((resolve) => fetch(xml)
          .then(response => response.text())
          .then(data => resolve(data))
        )
        const results = await new Promise((resolve, reject) => parseString(data, (err, results) => {
          if (err) reject(err)
          else resolve(results)
        }))

        if (!results || !results.rss || !results.rss.channel) return
        const channel = results.rss.channel.shift()
        if (!channel || !channel.item) return
        messages.push(...channel.item.map(item => {
          return { title: item.title.shift(), description: item.description.shift() }
        }))
        this.messages = messages
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

