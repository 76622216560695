<template>
  <v-expansion-panels
    v-model="panel"
    accordion
    active-class="menu-main"
    :style="cssProps"
  >
    <v-expansion-panel
      v-for="(item) in menus"
      :key="item.id"
      active-class="menu-panel"
    >
      <v-expansion-panel-header
        hide-actions
        :class="item.isPrimary ? 'menu' : 'menu-secondary'"
        v-bind:id="item.id"
        style="border-radius: 0"
        :style="{
          'background-color': item.bgColor ? item.bgColor : item.isPrimary ? config.BGColor : config.BGColorSecondary,
          'color': item.textColor ? item.textColor : item.isPrimary ? config.Color : config.ColorSecondary
        }"
      >
      <!--center>{{ getTranslation(item.name, locale) }} {{item.BGColor}}</center-->
      <div class="menu-container">
        <div class="menu-simulated">
          <center>{{ getTranslation(item.name, locale) }} {{item.BGColor}}</center>
        </div>
        <div v-if="dtouchSimulationShowFastEdit" class="menu-action">
          <v-layout wrap>
            <v-flex xs12 style="text-align:right;">
        <v-btn fab x-small elevation="0"
                v-if="item.ID !== 'new'"
                style="margin-right:5px;"
                @click.stop.prevent="onEditItem(false, 'new', item.id)"
        >
              <v-icon
                small
                color="primary"
                block
              >
                mdi-plus-thick
              </v-icon>
        </v-btn>
        <v-btn fab x-small elevation="0"
                style="margin-right:5px;"
                v-if="item.ID !== 'new'"
                @click.stop.prevent="onEditItem(true, item.id, item.parentID)"
              >
              <v-icon
                small
                color="primary"
                block
              >
                mdi-pencil
              </v-icon>
        </v-btn>
          <v-btn fab x-small elevation="0"
            v-if="item.id !== 'new'"
          >
              <delete-item
                v-if="item.id !== 'new'"
                :id="item.id"
                :onDeleteItem="handleDeleteMenu"
                icon="mdi-delete"
                :locale="locale"
              />
            </v-btn>
            </v-flex>
          </v-layout>
        </div>
      </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="menu-content"
        v-bind:id="item.id.concat('content')"
        :style="{
          'background-color': item.bgColorContent ? item.bgColorContent : config.BGColorContent,
          'color': item.textColorContent ? item.textColorContent : config.ColorContent
        }"
      >
        <edited-menu
          v-if="item.isMenuWithSubmenus"
          :id="item.id"
          :isMenuWithSubmenus="item.isMenuWithSubmenus"
          :config="config"
          :level="level+1"
          :locale="locale"
          :onEditItem="onEditItem"
        />
        <edited-content
          v-else
          :id="item.id"
          :config="config"
          :locale="locale"
          :onEditItem="onEditItem"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState } from 'vuex'
import { defaultMenu } from '@/constants/dtouch'
import api from '@/services/api'
import utils from '@/services/utils'
import DeleteItem from '@/components/DeleteItem'
import EditedMenu from './Index'
import EditedContent from './editedContent/Index'
export default {
  name: 'EditedMenu',
  components: {
    EditedMenu,
    EditedContent,
    DeleteItem,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    isMenuWithSubmenus: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: null,
    },
    level: {
      type: Number,
      default: 0,
    },
    locale: {
      type: String,
      default: null,
    },
    refreshSortContent: {
      type: Object,
      default: null,
    },
    autoExpand: {
      type: Boolean,
      default: true,
    },
    onEditItem: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    panel: null,
    menus: [],
  }),
  computed: {
    ...mapState('app',['dtouchMenuEdit', 'dtouchSimulationShowFastEdit']),
    isNew () {
      return this.id === 'new'
    },
    cssProps () {
      return {
        '--line': this.config.LineMenu === 1 ? '0.5px solid rgba(255, 255, 255, 0.3)' : null,
        // menu
        //'--bg-color': this.config.BGColor,
        //'--color': this.config.Color,
        '--font': this.config.Font,
        '--font-size': this.config.FontSize + 'px',
        '--text-transform': this.config.TextFormat === 1 ? 'uppercase' : 'initial',
        '--gradient': this.config.GradientMenu === 1 ? 'linear-gradient(to top, rgba(128, 128, 128, 0), rgba(0, 0, 0, 0.2))' : 'none',
        // secondary
        //'--bg-color-secondary': this.config.BGColorSecundary,
        //'--color-secondary': this.config.ColorSecundary,
        '--font-secondary': this.config.FontSecundary,
        '--font-size-secondary': this.config.FontSizeSecundary + 'px',
        '--text-transform-secondary': this.config.TextFormatSecondary === 1 ? 'uppercase' : 'initial',
        '--gradient-secondary': this.config.GradientSubmenu === 1 ? 'linear-gradient(to top, rgba(128, 128, 128, 0), rgba(0, 0, 0, 0.2))' : 'none',
        // content
        //'--bg-color-content': this.config.BGColorContent,
        //'--color-content': this.config.ColorContent,
        '--font-content': this.config.FontContent,
        '--font-size-content': this.config.FontSizeContent + 'px',
      }
    },
  },
  watch: {
    id (v) {
      if (v) this.getData()
    },
    'dtouchMenuEdit'(v) {
      if (v) {
        this.menus.filter(x => x.id === v.ID).map(item => {
          item.name = v.Name
          // colors
          item.bgColor = v.BGColor
          item.textColor = v.TextColor
          item.bgColorContent = v.BGColorContent
          item.textColorContent = v.TextColorContent
          this.prepareCssStyle(item)
          // colors
          return item
        })
      }
    },
    refreshSortContent (v) {
      // when sort is update refresh preview
      if (v.id === this.id && this.id) this.getData()
    },
  },
  mounted () {
    if (this.id) this.getData()
  },
  methods: {
    getColor(type, color) {
      return color ? color : this.config[type]
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareCssStyle (v) {
      if (!v.ID) return
      if (document.getElementById(v.ID) !== null) {
        document.getElementById(v.ID).style.backgroundColor = v.bgColor ? v.bgColor : this.level === 0 ? this.config.BGColor : this.config.BGColorSecondary
        document.getElementById(v.ID).style.color = v.textColor ? v.textColor : this.level === 0 ? this.config.Color : this.config.ColorSecondary
      }
      if (document.getElementById(v.ID.concat('content')) !== null) {
        document.getElementById(v.ID.concat('content')).style.backgroundColor = v.bgColorContent ? v.bgColorContent : this.config.BGColorContent
        document.getElementById(v.ID.concat('content')).style.color = v.textColorContent ? v.textColorContent : this.config.ColorContent
      }
    },
    prepareMenu (v) {
      return {
            id: v.ID,
            //name: utils.getTranslation(JSON.parse(v.Name), this.locale),
            name: v.Name,
            isMenuWithSubmenus: v.Type === 1,
            parentID: v.ParentID,
            isPrimary: v.ParentID === null,
            bgColor: v.BGColor,
            textColor: v.TextColor,
            bgColorContent: v.BGColorContent,
            textColorContent: v.TextColorContent,
          }
    },
    getData () {
      this.menus = []
      if (this.isMenuWithSubmenus) this.getSubmenus()
      else this.getSingleMenu()
    },
    getSingleMenu () {
      if (this.isNew) this.menus.push(this.prepareMenu(JSON.parse(JSON.stringify(defaultMenu)))) // add defaultMenu to simulation when is new
      else
        api.getItem ('dtouch', `v1/public/menus/`, this.id)
          .then((response) => {
            this.menus.push(this.prepareMenu(response))
            if (this.autoExpand) this.panel = 0 // TODO: eliminar si no se tiene que expandir
          })
    },
    getSubmenus () {
      api.getAllWithoutLimit ('dtouch', `v1/public/menus/${this.id}/submenus/`)
        .then((response) => {
          if(this.isNew) response.push(JSON.parse(JSON.stringify(defaultMenu))) // add defaultMenu to simulation when is new
          this.menus = response.map(item => this.prepareMenu(item))
        })
    },
    handleDeleteMenu (v) {
      api.deleteItem('dtouch', `v1/private/menus/`, v)
        .then(response => {
          if(response && response.data) {
            // update all content draggable menu
              this.$store.state.app.dtouchDraggableMenuUpdated = {
                MenuID: v,
                LMD: new Date(),
              }
            // get new content situation
            this.getData()
          }
        })
    },
  }
}
</script>
<style>
.simulation .menu {
  /*background-color: var(--bg-color);*/
  background-image: var(--gradient);
  border-top: var(--line);
  border-bottom: var(--line);
}
.simulation .menu center {
  font-size: var(--font-size);
  /*color: var(--color);*/
  font-family: var(--font);
  text-transform: var(--text-transform);
}
.simulation .menu-secondary {
  /*background-color: var(--bg-color-secondary);*/
  background-image: var(--gradient-secondary);
  border-top: var(--line);
  border-bottom: var(--line);
}
.simulation .menu-secondary center {
  /*color: var(--color-secondary);*/
  font-size: var(--font-size-secondary);
  font-family: var(--font-secondary);
  text-transform: var(--text-transform-secondary);
}
.menu-panel .v-expansion-panels {
  border-radius: 0 !important;
}
.menu-content {
  /*background-color: var(--bg-color-content);
  color: var(--color-content);*/
  font-size: var(--font-size-content);
  font-family: var(--font-content);
}
.menu-content .v-expansion-panel-content__wrap {
  padding: 0;
}
.menu-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.menu-simulated {
  width: 100%;
  position: absolute;  
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.menu-action {
  width: 100%;
  z-index: 10;
  text-align: right;
}
</style>

