<template>
  <div style="display: inline-block;">
    <v-btn
      v-if="event.Availability === 0"
      style="margin: 5px"
      color="red"
      :disabled="event.Availability === 0 || limited"
      small
    />
    <v-btn
      v-else
      style="margin: 5px"
      :color="color"
      :disabled="!event.AllowReservations || event.Availability === 0 || limited"
      @click="handleCheckAvailability"
      :dark="event.AllowReservations && event.Availability !== 0 && !limited ? true : false"
      small
     >
      {{event.sTime}} {{ limited ? '*' : '' }}
     </v-btn>
    <!--v-btn
      v-if="event.Availability === 0"
      x-small
      text
      style="margin: 10px 0;"
      disabled
    >
      {{ $t('booking.complete', locale).toUpperCase() }}
    </v-btn>
    <v-btn
      v-else
      x-small
      dark
      :color="color"
      style="margin: 10px 0;"
      :style="{ float: right ? null : 'left' }"
      :disabled="event.Availability === 0"
      @click="handleCheckAvailability"
    >
    {{ $t('booking.reservation', locale).toUpperCase() }}
    </v-btn-->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card style="padding: 20px">
        <v-layout wrap v-if="bookingTicketData">
          <v-flex xs12>
            <ticket :booking="bookingTicketData" :locale="locale" />
          </v-flex>
          <v-flex xs12 style="text-align: right;padding-top: 20px">
            <v-btn @click="handleClose" :color="color" dark>
              {{ $t('common.close', locale) }}
            </v-btn>
          </v-flex>
        </v-layout>
        <booking-reservation-form
          v-else
          :editedID="editedID"
          :event="event"
          :onClose="handleClose"
          :onAdd="handleAdd"
          :color="color"
        />
      </v-card>
    </v-dialog>
    <!--v-btn
      x-small
      dark
      :color="color"
      style="margin: 10px 0"
      :disabled="event.availability === 0"
      @click="handleCheckAvailability"
    >
      {{ $t('booking.reservation', locale).toUpperCase() }}
    </v-btn-->
    <!--v-chip
      class="ma-2"
      :color="color"
      small
      dark
      style="margin-left: 0px !important;"
      :disabled="event.availability === 0"
      @click="handleCheckAvailability"
    >
      {{ $t('booking.reservation', locale).toUpperCase() }}
    </v-chip-->
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{ $t('common.messages.pleaseStandBy', locale) }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import utils from '@/services/utils'
import Ticket from '@/components/common-components/reservation/Ticket'
import BookingReservationForm from '@/components/common-components/bookingReservationForm/Index'
export default {
  name: 'ReservationForm',
  components: {
    Ticket,
    BookingReservationForm,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    right: {
      type: Boolean,
      default: false,
    },
    timeLimit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    dialog: false,
    editedID: null,

    bookingTicketData: null,
    limited: false
  }),
  watch: {
    'event.ID' () {
      if(this.timeLimit) this.checkLimitTime()
    },
  },
  mounted () {
    if(this.timeLimit) this.checkLimitTime()
  },
  methods: {
    handleCheckAvailability () {
      this.loading = true
      const urlBase = process.env.VUE_APP_API_URL_DTOUCH
      const url = `${window.location.protocol}//${urlBase}/v1/public/events/${this.event.ID}/availability?type=${this.event.type}`
      axios.get(url)
        .then((response) => {
          const availability = response.data ? response.data.availability : null
          if (availability > 0) {
            this.editedID = 'new'
            this.dialog = true
          } else this.event.Availability = availability
        })
        .catch((error) => {
           console.log(error)
        })
        .then(() => {
          this.loading = false
        })
    },
    checkLimitTime () {
      axios.get(`${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/events/${this.event.ID}/check-limit-time`)
        .then (response => {
          this.limited = !response.data ? true : false
        })
    },
    async handleAdd (body) {
      this.loading = true
      if (this.event.Source === 'contentmanager') {
        const isValidLimitTime = await new Promise ((resolve) => axios.get(`${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/events/${this.event.ID}/check-limit-time`)
          .then (response => {
            resolve(response.data ? true : false)
          }))
        if (!isValidLimitTime) {
          alert('no disponible')
          this.loading = false
          this.handleClose()
          return
        }
      }

      const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/reservations`
      axios.post(url, body)
        .then((response) => {
          console.log('jhm-response', response)
          if (response.data && response.data.status === 'OK') {
            this.bookingTicketData = response.data.reservation
          } else {
            this.$store.state.app.bookingUpdateAvailability_resource = utils.getNewID()
            alert(this.$t(`booking.reservations.${response.data.error}`, this.locale))
          }

        })
        .catch((error) => { console.log('1', error) })
        .then(() => { this.loading = false })
      //this.handleClose()
    },
    handleClose () {
      this.bookingTicketData = null
      this.dialog = false
    },
  },
}
</script>

