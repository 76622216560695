var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"activity",style:({ 
     'background-color' : _vm.config.BGColorContent
   }),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticStyle:{"padding":"0 20px"},attrs:{"xs12":""}},[_c('week-menu',{attrs:{"disabled":_vm.loading,"day":_vm.daysToAdd,"primaryColor":_vm.weekPrimaryColor,"secondaryColor":_vm.weekSecondaryColor,"locale":_vm.locale,"onChange":_vm.handleChangeDay}}),_c('v-container',{staticStyle:{"padding":"5px"},attrs:{"fluid":"","grid-list-md":""}},[_c('v-layout',{staticStyle:{"margin-bottom":"20px"},attrs:{"row":"","wrap":""}},[(_vm.loading)?_c('v-flex',[_c('center',[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":_vm.contentColor,"indeterminate":""}})],1)],1):_vm._e(),(!_vm.loading && _vm.events.length === 0)?_c('v-flex',{attrs:{"xs12":""}},[_c('center',{style:({ 'color': _vm.contentColor })},[_vm._v(_vm._s(_vm.$t('dtouch.activity.messages.noActivities', _vm.locale).toUpperCase()))])],1):_vm._e(),(!_vm.loading && !_vm.content.BookingActCategoryID && _vm.categories && _vm.categories.length > 0 && !_vm.filterType)?_c('v-flex',{staticStyle:{"padding-bottom":"25px"},attrs:{"xs12":""}},[_c('v-select',{attrs:{"items":_vm.categories,"label":_vm.$t('booking.filter', _vm.locale),"item-text":"Name","item-value":"ID","outlined":"","color":_vm.titleColor,"hide-details":""},model:{value:(_vm.selectedCategoryID),callback:function ($$v) {_vm.selectedCategoryID=$$v},expression:"selectedCategoryID"}})],1):_vm._e(),_vm._l((_vm.eventsFiltered),function(event,index){
   var _obj;
return _c('v-flex',_vm._b({key:event.ID,style:({ 
                 paddingLeft: !_vm.isTotem || index % 2 === 0 ? 0 : '5px',
                 paddingRight: !_vm.isTotem || index % 2 !== 0 ? 0 : '5px',
              })},'v-flex',( _obj = {}, _obj[("xs" + (_vm.isTotem ? 6 : 12))] = true, _obj ),false),[_c('activity-item',{attrs:{"event":event,"locale":_vm.locale,"contentColor":_vm.contentColor,"primaryColor":_vm.weekPrimaryColor,"secondaryColor":_vm.weekSecondaryColor,"titleColor":_vm.titleColor,"timeColor":_vm.timeColor}})],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }