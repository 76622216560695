<template>
  <div v-if="meals && rows">
    <v-card
      v-for="meal of meals"
      :key="meal.id"
      style="margin-bottom: 20px;padding:0 10px;"
      elevation="1"
      :style="{ color: `${color1} !important` }"
    >
      <table
        class="main-table"
      >
        <tr
        >
          <td
            class="meal"
            style="font-weight: 900 !important;"
          >
          {{meal.name}} <v-icon v-if="meal.icon" style="font-size: 1.7rem;" :style="{ color: color2 }">{{meal.icon}}</v-icon>
          </td>
        </tr>
        <tr
        >
          <td
          >
            <meal-row
              :mealID="meal.id"
              :rows="rows"
              :values="values"
              :color2="color2"
            />
          </td>
        </tr>
      </table>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import MealRow from './MealRow'
export default {
  components: {
    MealRow,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    daysToAdd: {
      type: Number,
      required: true,
    },
    color1: {
      type: String,
      default: null,
    },
    color2: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    values: null,
    meals: null,
    rows: null,
  }),
  watch: {
    id () {
      this.handleGetData()
    },
    daysToAdd () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData() {
      this.loading = true
      //const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_CMDVIEWCONTENT}/v1/public/weekly-menu/meals/${this.id}/${this.daysToAdd}`
      const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_CMDVIEWCONTENT}/v1/public/weekly-menu/meals/${this.id}/${this.daysToAdd}`
      axios.get(url)
        .then(response => {
          this.values = response.data.data
          this.meals = response.data.config.meal
          this.rows = response.data.config.row
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>
<style>
.main-table {
  width: 100%;
}
.main-table .meal {
  font-size: 1.5rem;
  height: 70px;
  vertical-align: bottom;
  padding-left: 10px;;
}


.meal-name {
  padding-top: 10px !important;
  font-size: 2rem;
}
.meal-name-item {
  padding-left: 161px;
}
</style>


