<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="390"
    >
      <template v-slot:activator="{  }">
        <v-btn
          block
          :color="config.BGColor"
          :disabled="loading"
          :style="{ color: config.Color }"
          @click="onShowForm"
        >
          {{$t('dtouch.dishOrders.confirmOrder', locale)}}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5" style="padding-bottom: 20px">
          {{$t('dtouch.dishOrders.formConfirm.title', locale)}}
        </v-card-title>
        <v-card-text
          v-for="(item, index) of formConfirm"
          :key="index"
        >
          <v-text-field
            v-if="item.type === 'text'"
            :label="$t(`dtouch.dishOrders.formConfirm.${item.name}`, locale).concat(' (*)')"
            outlined
            v-model="item.value"
            hide-details
            style="width: 100%"
          />
          <small 
            v-if="showRequired && (!item.value || item.value.length ===0)"
            style="color: red;float: right;"
          >
            * {{$t('dtouch.dishOrders.formConfirm.required', locale).toLowerCase()}}
          </small>
        </v-card-text>
        <v-card-actions style="width:100%; padding-bottom: 10px;">
          <v-layout
            wrap
            style="width: 100%;margin-top: -20px;"
          >
          <v-flex
            xs1
            v-if="sTerms ? true : false"
            style="padding-left:10px;"
          >
            <v-checkbox
              v-model="isAcceptTerms"
            />
          </v-flex>
          <v-flex
            xs11
            v-if="sTerms ? true : false"
            style="padding-top: 20px;padding-left: 10px;"
          >
            <accept-terms
              :sTerms="sTerms"
              :locale="locale"
              :onChange="handleChangeAcceptTerms"
            />
          </v-flex>
          <v-flex
            xs6
            style="padding: 0 10px 10px 10px;"
          >
            <v-btn
              @click="onCancel"
              block
            >
              {{ $t('common.cancel', locale) }}
            </v-btn>
          </v-flex>
          <v-flex
            xs6
            style="padding: 0 10px 10px 10px;"
          >
            <v-btn
              block
              :color="config.BGColor"
              :style="{ color: config.Color }"
              @click="handleConfirm"
              :disabled="!isAcceptTerms && sTerms"
            >
              {{$t('dtouch.dishOrders.confirmOrder', locale)}}
            </v-btn>
          </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  import utils from '@/services/utils'
  import AcceptTerms from './AcceptTerms'
  import apiDishOrders from '@/services/apiDishOrders'
  export default {
    components: {
      AcceptTerms,
    },
    props: {
      config: {
        type: Object,
        default: null,
      },
      formConfirm: {
        type: Array,
        required: true,
      },
      locale: {
        type: String,
        required: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      onShowForm: {
        type: Function,
        required: true,
      },
      onCancel: {
        type: Function,
        required: true,
      },
      onConfirm: {
        type: Function,
        required: true,
      },
      showForm: {
        type: Boolean,
        default: false,
      },
      terms: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      dialog: false,
      formItems: null,
      showRequired: false,
      sTerms: null,
      isAcceptTerms: false,
    }),
    watch: {
      showForm () {
        this.dialog = this.showForm
      },
      terms () {
        this.prepareTerms()
      },
      locale () {
        this.prepareTerms()
      },
    },
    mounted () {
      this.prepareForm ()
      this.dialog = this.showForm
      this.prepareTerms()
    },
    methods: {
      setStorage (v) {
        if (!v) return
        for (const item of v.filter(x => x.value)) {
          const name = `f_${item.name === 'fullName' ? 'name' : item.name}`
          localStorage.setItem(name, item.value)
        }
      },
      getStorage (v) {
        const aux = localStorage.getItem(v)
        return aux && aux.length > 0 ? aux : null
      },
      prepareForm () {
        this.formConfirm = this.formConfirm.map(item => {
          const name = `f_${item.name === 'fullName' ? 'name' : item.name}`
          item.value = this.getStorage(name)
          return item
        })
      },
      prepareTerms () {
        if(!this.terms) this.sTerms = null
        const aux = utils.getTranslation(this.terms, this.locale)
        this.sTerms = aux.length > 0 ? aux : null
      },
      async handleConfirm () {
        // TODO: JHM
        if (this.$route.query.point && this.workspaceID) await apiDishOrders.preparePoint()

        this.showRequired = false
        this.showRequired = this.formConfirm.filter(x => !x.value || x.value.length === 0).length > 0
        if (this.showRequired) return

        const body = {
          formConfirm: this.formConfirm,
          isAcceptTerms: this.isAcceptTerms
        }
        this.setStorage(this.formConfirm)
        this.onConfirm(body)
      },
      handleChangeAcceptTerms () {
        this.isAcceptTerms = this.isAcceptTerms ? false : true
      },
    },
  }
</script>

