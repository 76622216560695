<template>
  <v-container>
    <div
      v-for="(item, i) in data"
      :key="i"
      class="parrilla"
    >
      <video
        :id="'video'+i"
        v-if="item.Type === 'video'"
        v-show="active === i"
        :muted="isSimulation"
        preload="true"
        style="width: 100%; height:100%;"
      >
        <source :src="item.Media" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <v-img
        v-else
        v-show="active === i"
        :src="item.Media"
      />
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'Parrilla',
  props: {
    data: {
      type: Array,
      required: true
    },
    loop: {
      type: Boolean,
      required: false,
      default: false
    },
    onFinished: {
      type: Function,
      required: true
    },
    isSimulation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    numItem: 0,
    active: 0 
  }),
  mounted () {
    this.numItem = this.data.length
    const aux = this.initRep()
    if (!aux) this.prepareItem()
  },
  methods: {
    initRep () {
      if (this.data[this.active].Type !== 'video' && this.data[this.active].Time) {
        setTimeout(() => this.prepareItem(), this.data[this.active].Time * 1000)
        return true
      }
      return false
    },
    prepareItem () {
      const newActive = this.active +1
      if (newActive >= this.data.length) {
        // fin de la reproducci�n de la parrilla
        if (this.loop) {  // si es tipo loop se reinicia la reproduccion
          this.active = 0
          this.initRep()
        }
        this.onFinished()
      } else {
        this.active = newActive
        if (!this.initRep()) {  // si no es initRep significa que no es un item con tiempo por lo que tiene que ser un v�deo
          const video = document.getElementById('video'+this.active)
          if (video.readyState !== 4) {
              video.load()
          }
          video.play()
          const that = this
          // video.addEventListener("ended", this.prepareItem());
          video.onended = () => {
            that.prepareItem()
          }
        }
      }
    }
  }
}
</script>
