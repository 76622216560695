<template>
  <v-card
    :elevation="0"
    style="background: transparent !important;"
    :style="{ 'width': isSimulation ? '375px' : `${sizeW}px !important`}"
  >
    <v-carousel 
      v-if="content.GallerySlider" 
      hide-delimiters
      :height="carouselHeight"
    >
      <v-carousel-item
        v-for="(item,i) in images"
        :key="i"
        :src="item"
      />
    </v-carousel>
    <v-container v-else grid-list-sm fluid>
      <v-layout
        wrap
        :style="{
          'margin': !isSimulation && isDesktop ? '10px 20px 10px 20px' : '5px' 
        }"
      >
        <v-flex
          v-for="image in images"
          :key="image"
          v-bind="{ [`xs${!isSimulation && isDesktop && images.length >= 4 ? 3 : 4}`]: true }"
          d-flex
          child-flex
        >
          <v-card flat tile class="d-flex" style="background: transparent !important;">
            <v-img
              :src="image"
              :lazy-src="image"
              aspect-ratio="1"
              class="grey lighten-2"
              @click="handleShowImage(image)"
            >
              <template v-slot:placeholder>
                <v-layout
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <v-progress-circular indeterminate color="grey lighten-5" />
                </v-layout>
              </template>
            </v-img>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-overlay :value="showImage">
      <v-btn
        icon
        :z-index="6"
        @click="overlay = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-img
        :z-index="5"
        :src="selectedImage"
        :lazy-src="selectedImage"
        aspect-ratio="1.7"
        class="grey lighten-2"
        min-width="calc(80vw)"
        max-width="calc(100vw)"
        @click="handleShowImage(null)"
      />
    </v-overlay>
  </v-card>
</template>
<script>
export default {
  name:'Item7Gallery',
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    isSimulation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    images: null,
    showImage: false,
    selectedImage: null,
  }),
  computed: {
    sizeW () {
      return window.innerWidth - (this.isDesktop ? 360 : 0)
    },
    carouselHeight () {
      return this.content.GalleryMobileHeight && this.content.GalleryMobileHeight.length > 0 ? this.content.GalleryMobileHeight : 500
    },
  },
  watch: {
    'content.Images' () {
      this.prepareData()
    }
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.images = typeof this.content.Images === 'string' ? this.content.Images.split(',') : this.content.Images
    },
    handleShowImage (v) {
      this.showImage = v ? true : false
      this.selectedImage = v
    },
  },
}
</script>

