<template>
  <center>
    <div
      style="padding: 0 20px 0 20px;"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
      >
        <item
          :content="item"
          :config="config"
        />
        <v-divider v-if="!content.HideDivider && index < content.ContactItems.length -1" style="margin: 10px 50px 0 50px !important;" />
      </div>
    </div>
  </center>
</template>
<script>
import Item from './Item'
export default {
  name:'Item17Contact',
  components: {
    Item,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  computed: {
    items () {
      const aux = this.content.ContactItems
      return typeof aux === 'string' ? JSON.parse(aux) : aux
    }
  },
}
</script>

