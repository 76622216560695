<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <template v-slot:activator="{ on }">
        <v-btn class="mx-2" fab dark x-small v-on="on"
          :style="{ 'background-color': btnBackgroundColor}"          
        >
          <v-icon dark :style="{ 'color': btnTextColor}">mdi-comment-alert</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text style="padding: 10px 0px 0px 0px;">
          <div style="width:100%;text-align: right;padding: 0 40px">
            <button 
            style="margin-right:-30px;margin-top:-10px;"
              @click="dialog = false"
            >
              <v-icon
                style="margin-left: 0px; color: white;font-size:30px;"
                :style="{ 'color': btnBackgroundColor }"
                class="activity-button"
              >
                mdi-close
              </v-icon>
            </button>
          </div>
          <center style="margin-bottom: 20px;">
            <button 
            disabled="true"
            style="border-radius:50%;height: 55px;width:55px;text-align:center;padding:5px 5px 2px 5px;opacity:0.7;"
            :style="{ 'background-color': btnBackgroundColor}"
          >
            <v-icon
              style="margin-left: 0px; color: white;font-size:35px;"
              dark
              class="activity-button"
              :style="{ 'color': btnTextColor}"
            >
              mdi-comment-alert
            </v-icon>
          </button>
          </center>
          <center
            :style="{ 'color': ColorContent,
              'font-size': `${FontSizeContent}px`,
              'font-family': FontContent, 
            }"
            style="margin: 0 10px"
          >
            {{name}}
          </center>
          <center
            :style="{ 'color': ColorContent,
              'font-size': `${FontSizeContent - 5}px`,
              'font-family': FontContent, 
            }"
            style="margin: 0 10px;"
          >
            {{ingredients}}
          </center>
          <center
            style="margin-top:5px;"
            :style="{ 'color': ColorContent,
              'font-size': `${FontSizeContent}px`,
              'font-family': FontContent, 
            }"
          >
            {{data.Price}}
          </center>
          <center style="margin-top:20px;">
            <div class="modal-allergens">
              <v-layout wrap style="padding: 15px">
                <v-flex xs12 >
                  <center>{{ allergensText }}</center>
                </v-flex>
                <v-flex
                  xs4
                  v-for="(allergen) in selectedAllergens"
                  :key="allergen.ID" 
                >
                  <table width="100%">
                    <tr>
                      <td style="padding: 0px;text-align:center;">
                        <img
                          :src="allergen.Image"
                          width="60%"
                          heigth="auto"
                          alt="item.Name"
                          style="margin: 5px 5px 0 5px;"
                        > 
                      </td>
                    </tr>    
                    <tr>
                      <td>
                        <center
                          :style="{ 'color': ColorContent,
                            'font-size': `${FontSizeContent - 7}px`,
                            'font-family': FontContent, 
                          }"
                        >
                          {{ allergen.Name.toUpperCase()}}
                        </center>
                      </td>
                    </tr>
                  </table>           
                </v-flex>
              </v-layout>
            </div>
          </center>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import utils from '@/services/utils'
  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
      locale: {
        type: String,
        required: true
      },
      dtouchWorkspace: {
        type: String,
        required: true,
      },
      config: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        dialog: false,
        allergens: [],
        selectedAllergens: [],
        allergensText: ''
      }
    },
    computed: {
      BGColor () {
        return this.config.BGColor
      },
      FontContent () {
        return this.config.FontContent
      },
      FontSizeContent () {
        return this.config.FontSizeContent
      },
      ColorContent () {
        return this.config.ColorContent
      },
      btnBackgroundColor () {        
        return this.config.BtnBackgroundColor && this.config.BtnBackgroundColor !== '' ? this.config.BtnBackgroundColor : this.BGColor
      },
      btnTextColor () {
        return this.config.BtnTextColor && this.config.BtnTextColor !== '' ? this.config.BtnTextColor : 'white'
      },
      name () {
        return this.data.Name ? utils.getTranslation (this.data.Name, this.locale) : ''
      },
      ingredients () {
        return this.data.Ingredients ? utils.getTranslation (this.data.Ingredients, this.locale) : ''
      },
    },
    watch: {
      'data.FoodAllergens' (v) {
        this.selectedAllergens = this.allergens.filter(x => v.indexOf(x.ID)>=0)
      },
      locale (v) {
        this.allergensText = this.$t('dtouch.food.containsAllergens', v).toUpperCase()

        this.allergens = this.allergens.map(item => {
          item.Name = utils.getTranslation (item.FullName, v)
          return item
        })
      }
    },
    mounted () {
      this.allergensText = this.$t('dtouch.food.containsAllergens', this.locale).toUpperCase()
      const urlBase = process.env.VUE_APP_API_URL_DTOUCH
      const url = `${window.location.protocol}//${urlBase}/v1/public/workspaces/${this.dtouchWorkspace}/allergens`
      fetch(url, { 
        method: 'get', 
        headers: new Headers({
          'Content-Type': 'application/json'
        }), 
      })
      .then((res) => {
        return res.json()
      })
      .then((data) => {
        const response = data.shift().data
        this.allergens = response.map(item => {
          item.FullName = item.Name,
          item.Name = utils.getTranslation (item.Name, this.locale)
          item.Image = `https://www.dtouch.es/assets/allergens/${item.Image}.png`
          return item
        })
        
        this.selectedAllergens = this.allergens.filter(x => this.data.Allergens.indexOf(x.ID)>=0)
      })
    },
  }
</script>

<style scoped>
  .modal-allergens {
    margin-top: 10px;
    background-color: rgba(241, 241, 241);
  }
</style>
