<template>
  <v-layout wrap v-if="reservations" 
    :style="{ 'background-color': content.BookingMyBookingsBGColor }"
  >
    <v-flex xs12>
      <v-toolbar
        elevation="0"
        :color="content.BookingMyBookingsExpandColor"
      >
      <v-toolbar-title :style="{ color: getOppositeColor(content.BookingMyBookingsExpandColor) }">{{ $t('dtouch.reservations', locale) }}</v-toolbar-title>

        <v-spacer/>

        <v-btn
          icon
          @click="handleLogout"
        >
          <v-icon :color="getOppositeColor(content.BookingMyBookingsExpandColor)">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-flex>
    <!--
    <v-flex xs12>
      <book-reservation-form
        v-if="event && editedBooking"
        :editedID="editedBooking.ID"
        :event="event"
        :token="token"
        :onClose="handleCloseReservationForm"
        :onUpdate="handleUpdate"
      />
    </v-flex>
    -->
    <booking-reservation-form
      v-if="1==2"
                  :editedID="editedBooking.ID"
                  :event="event"
                  :onAdd="handleAdd"
                  :onUpdate="handleUpdate"
                  :onDelete="handleCancelReservation"
                  :onClose="handleCloseReservationForm"
                />
    <reservation-form
      v-if="event && editedBooking && editedBooking.ID"
      :editedBooking="editedBooking"
      :onClose="handleCloseReservationForm"
      :event="event"
      :token="token"
      :onUpdate="handleUpdate"
      :onDelete="handleCancelReservation"
    />
    <v-flex xs12
      v-for="(booking, index) of reservations"
      :key="index"
      style="padding: 10px"
    >
      <reservation-ticket
        v-show="false"
      />
        <ticket :booking="booking" :locale="locale" 
          :externalBGColor="content.BookingMyBookingsBGColor" 
          :BGColor="content.BookingMyBookingsTicketBGColor" 
          :textColor="content.BookingMyBookingsTicketTextColor" 
        />
        <!--p>
          {{getTranslation(booking.ActivityName, locale)}}
        </p>
        <p>
          <v-icon>mdi-calendar</v-icon> {{booking.Date.substring(0, 10)}} <v-icon>mdi-clock</v-icon> {{ convertTime(booking.Time) }}
        </p>
        <p>
          <v-icon>mdi-account</v-icon> {{booking.OwnerName}} {{booking.OwnerLastName}}
        </p>
        <p>
          {{booking.NumPax}} {{$t('dtouch.pax', locale).toLowerCase()}}
        </p>
        <p style="color: red">
          {{booking.Status}}
        </p>
        <v-alert
          v-if="booking.Status !== 'CANCELED' && booking.outdated"
          text
          dense
          outlined
          color="deep-gray"
          icon="mdi-information-variant"
        >
          {{ $t('dtouch.outdatedBooking', locale) }}
        </v-alert-->
        <v-btn 
          v-if="booking.allowCancel && !booking.allowEdit"
          @click="handleCancelReservation(booking.ID)"
          color="red"
          dark
          block
        >
          {{ $t('dtouch.cancelBooking', locale) }}
        </v-btn>
        <v-btn 
          v-else-if="booking.allowEdit"
          @click="handleEditReservation(booking.ID)"
          :color="content.BookingMyBookingsBtnColor"
          style="margin-top: 10px"
          dark
          block
        >
          <span :style="{ color: getOppositeColor(content.BookingMyBookingsBtnColor) }">
            {{ $t('dtouch.editBooking', locale) }}
          </span>
        </v-btn>
    </v-flex>
  </v-layout>
  <v-layout wrap v-else
    :style="{ 'background-color': content.BookingMyBookingsBGColor }"
  >
    <v-flex xs12>
      <v-btn
        block
        large
        @click="showBookings = !showBookings"
        style="border-radius: 0px;"
        elevation="0"
        :color="content.BookingMyBookingsExpandColor"
      >
        <span :style="{ color: getOppositeColor(content.BookingMyBookingsExpandColor) }">
          {{ $t('dtouch.showBookings', locale) }}
        </span>
      </v-btn>
    </v-flex>
    <v-flex xs12 v-if="showBookings" style="margin: 20px 0; padding: 0 10px;">
      <v-layout wrap>
        <v-flex xs12>
          <v-text-field
            v-model="email"
            :label="$t('dtouch.email', locale)"
            outlined
            clearable
            :dark="!isLight(content.BookingMyBookingsBGColor)"
            hide-details
          />
        </v-flex>
        <v-flex xs12 style="margin: 10px 0">
          <v-text-field
            v-model="room"
            :label="$t('dtouch.room', locale)"
            outlined
            clearable
            :dark="!isLight(content.BookingMyBookingsBGColor)"
            hide-details
          />
        </v-flex>
        <v-flex xs12>
          <v-btn
            block
            :color="content.BookingMyBookingsBtnColor"
            :loading="loading"
            @click="handleAuthentication"
            dark
          >
            <span :style="{ color: getOppositeColor(content.BookingMyBookingsBtnColor) }">
              {{ $t('dtouch.access', locale) }}
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios'
import tinycolor from 'tinycolor2'
import utils from '@/services/utils'
import ReservationTicket from '@/components/common-components/ReservationTicket'
import Ticket from '@/components/common-components/reservation/Ticket'
import BookingReservationForm from '@/components/common-components/bookingReservationForm/Index'
import ReservationForm from './ReservationForm'
export default {
  name:'Item47Book',
  components: {
    BookingReservationForm,
    ReservationForm,
    Ticket,
    ReservationTicket,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    token: null,
    loading: false,
    editedBooking: null,
    event: null,
    showBookings: false,
    email: process.env.VUE_APP_ENV === 'development' ? 'test@descubregroup.com' : null,
    room: process.env.VUE_APP_ENV === 'development' ? '1408' : null,
    reservations: null,
  }),
  computed: {
    workspaceIdentification () {
      return utils.getWorkspaceIdentification ()
    },
  },
  mounted () {
    this.token = utils.getCustomToken('47MyBooking')
    if (this.token) {
      this.showBookings = true
      this.handleGetReservations()
    }
  },
  methods: {
    isLight (v) {
      return tinycolor(v).isLight()
    },
    getOppositeColor(v) {
      return utils.getOppositeColor(v)
    },
    convertTime (v) {
      return utils.convertTime(v)
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleAuthentication () {
      this.loading = true
      if (!this.email || !this.room || this.email.length === 0 || this.room.length === 0) {
        alert ('TODO')
        return
      }
      const body = {
        domain: this.workspaceIdentification,
        email: this.email,
        room: this.room,
        color: this.content.BookingMyBookingsTicketBGColor,
        textColor: this.content.BookingMyBookingsTicketTextColor,
      }

      const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/reservations/content-manager/authentications`
      axios.post(url, body)
        .then(response => {
          this.token = response.data.token
          utils.setCustomToken('47MyBooking', this.token)
          this.handleGetReservations()
        })
        .catch((error) => {
          console.log('error', error)
        })
        .finally(() => { this.loading = false })
    },
    handleGetReservations () {
      if (this.token) {
        const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/external/reservations/content-manager?token=${this.token}`
        axios.get(url)
          .then(response => {
            if (response.data.token) {
              this.token = response.data.token
              utils.setCustomToken('47MyBooking', this.token)
            }
            this.reservations = response.data && response.data.reservations ? response.data.reservations.map(item => {
              //TODO
              //item.outdated = new Date(item.DateTime) < new Date()
              //item.allowCancel = item.Status !== 'CANCELED' && !item.outdated
              //item.allowEdit = item.Status !== 'CANCELED' && !item.outdated
              item.outdated = process.env.VUE_APP_ENV === 'development' ? false : new Date(item.DateTime) < new Date()
              item.allowCancel = process.env.VUE_APP_ENV === 'development' ? true : item.Status !== 'CANCELED' && !item.outdated
              item.allowEdit = process.env.VUE_APP_ENV === 'development' ? true : item.Status !== 'CANCELED' && !item.outdated
              item.ActivityName = JSON.parse(item.ActivityName)
              return item
            }) : null
          })
          .catch((error) => {
            console.log('error', error)
          })
          .finally(() => { this.loading = false })

      }
    },
    handleEditReservation (reservationID) {
      if (!reservationID) return
      this.editedBooking = this.reservations.filter(x => x.ID === reservationID).shift()
      this.event = {
        ID: this.editedBooking.EventID
      }
    },
    handleUpdate (id, body) {
      const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/external/reservations/content-manager/${id}?token=${this.token}`
      axios.put(url, body)
        .then(response => {
          if (response.data.status === 'OK') {
            this.handleGetReservations()
            this.handleCloseReservationForm()
          }
        })
    },
    handleLogout () {
      this.reservations = null
      utils.setCustomToken('47MyBooking', null)
    },
    handleAdd () {
      alert('aaa')
    },
    handleCloseReservationForm () {
      this.handleGetReservations()
      this.editedBooking = null
      this.event = null
    },
    handleCancelReservation (id) {
      const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/external/reservations/content-manager/${id}?token=${this.token}`
      axios.delete(url)
        .then(response => {
          if (response.data.status === 'OK') {
            this.handleGetReservations()
            this.handleCloseReservationForm()
          }
        })
    },
  },
}
</script>

